import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  AngularFirestoreCollection,
  AngularFirestore,
} from "@angular/fire/firestore";
import { UserService } from "./user.service";
import { Recipe } from "../../../../models/recipe";
import { DeviceService } from "./device.service";
import { CropService } from "./crop.service";

@Injectable({
  providedIn: "root",
})
export class RecipeService {
  public collection: AngularFirestoreCollection<Recipe>;

  constructor(
    private db: AngularFirestore,
    private userService: UserService,
    private deviceService: DeviceService,
    private cropService: CropService
  ) {
    this.collection = this.db.collection<Recipe>("recipes", (ref) => {
      const c1 = ref.where(
        "Company.Id",
        "==",
        this.userService.self.Company.Id
      );
      return c1.orderBy("DeviceZone");
    });
  }

  getList(): Observable<Array<Recipe>> {
    const list$ = this.db
      .collection<Recipe>("recipes", (ref) => {
        const c1 = ref.where(
          "Company.Id",
          "==",
          this.userService.self.Company.Id
        );
        return c1;
      })
      .valueChanges({ idField: "Id" });
    return list$;
  }

  async add(data: Recipe): Promise<void> {
    data.Company = this.userService.self.Company;
    // FIXME(simon): Why does a recipe need a RegistryId?
    data.RegistryId = "plantos_indoor_autodose";
    const docRef = await this.collection.add(data);
    // FIXME(simon): Why does recipe need its own ID here?
    data.Id = docRef.id;
    this.edit(data);
    console.log(`created recipe: ${docRef.path}`);
  }

  async edit(recipe: Recipe): Promise<void> {
    recipe.Company = this.userService.self.Company;
    recipe.RegistryId = "plantos_indoor_autodose";
    this.collection.doc(recipe.Id).update(recipe);

    // Update any devices that depend on this recipe. First we list all of
    // the devices this user has control over.
    const devices = await this.deviceService.list(
      this.userService.self.Company.Id
    );

    // Find all the Crops that use this recipe.
    const crops = await this.cropService.list(this.userService.self.Company.Id);

    console.log(
      `got crops: ${JSON.stringify(crops.docs.map((x) => x.data()))}`
    );

    const recipeCrops = crops.docs.filter((crop) => {
      console.log(
        `checking recipe for crop ${crop.id}: ${JSON.stringify(crop.data())}`
      );

      for (const r of crop.data().Recipes || []) {
        if (r.Id == recipe.Id) {
          console.log(`recipe ${r.Id} applies`);
          return true;
        }
      }
      return false;
    });

    const recipeCropIds = new Set();
    for (const c of recipeCrops) {
      recipeCropIds.add(c.id);
    }

    // For each device, we check if it has been assigned this recipe - if so
    // we'll send a command to the device to re-configure the dosing.
    for (const doc of devices.docs) {
      const device = doc.data();
      console.log(`${doc.id} => ${JSON.stringify(device)}`);
      if (recipeCropIds.has(device.CropId)) {
        console.log(
          `device ${doc.id} ${device.DeviceId} is using crop ${device.CropId} which uses recipe ${recipe.Id}. updating it's config.`
        );
        // TODO(simon): Propagate errors correctly here.
        const success = await this.deviceService.updateDeviceConfig(
          device.RegistryId,
          device.DeviceId,
          device.SumpTankCapacityLitres,
          recipe.EC.Min,
          recipe.EC.Optimal,
          recipe.pH.Max,
          device.MaxFrequency
        );

        if (!success) {
          console.error(`failed to update device ${doc.id}`);
        }
      }
    }
  }

  delete(id: string): void {
    this.collection.doc(id).delete();
  }
}
