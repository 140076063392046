<div fxLayout="row wrap" fxLayoutAlign="end stretch">
  <button
    nz-button
    *ngIf="self.Role.Admin || self.Role.Owner"
    nzType="primary"
    (click)="editDialog(tplTitle, tplContent, null, false)"
  >
    <i nz-icon nzType="plus" nzTheme="outline"></i>
    {{ "deviceSideMenu" | translate }}
  </button>
</div>
<br />

<nz-table nzSize="middle" [nzData]="device$ | async">
  <thead>
    <tr>
      <th>{{ "deviceZone" | translate }}</th>
      <th>{{ "deviceId" | translate }}</th>
      <th>{{ "deviceType" | translate }}</th>
      <th>{{ "location" | translate }}</th>
      <th>{{ "environment" | translate }}</th>
      <th *ngIf="self.Role.Worker"></th>
      <th *ngIf="self.Role.Admin || self.Role.Owner">
        {{ "action" | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of device$ | async">
      <td>{{ item.DeviceZone }}</td>
      <td>{{ item.DeviceId }}</td>
      <td>{{ item.DeviceType.Name }}</td>
      <td>{{ item.Location }}</td>
      <td>
        <span *ngIf="item.Indoor">{{ "indoor" | translate }}</span
        ><span *ngIf="!item.Indoor">{{ "outdoor" | translate }}</span>
      </td>
      <td style="width: 90px">
        <button
          *ngIf="self.Role.Admin || self.Role.Owner"
          nz-button
          nzType="default"
          nzShape="circle"
          (click)="editDialog(tplTitle, tplContent, item, true)"
        >
          <i nz-icon nzType="edit"></i></button
        >&nbsp;
        <button
          *ngIf="self.Role.Admin || self.Role.Owner"
          nz-button
          nzType="danger"
          nzShape="circle"
          (click)="deleteDialog(item)"
        >
          <i nz-icon nzType="delete"></i>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>

<ng-template #tplTitle>
  <span>{{ "manageDevices" | translate }}</span>
</ng-template>
<ng-template #tplContent>
  <form nz-form [formGroup]="dialogFormGroup" class="dialog-form">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "zoneName" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="Device zone is required"
      >
        <input
          type="text"
          nz-input
          formControlName="zoneCtrl"
          placeholder="{{ 'zoneName' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "deviceId" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="Device ID is required"
      >
        <input
          type="text"
          nz-input
          formControlName="deviceIdCtrl"
          placeholder="{{ 'deviceId' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24">{{
        "deviceDescription" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="Description is required"
      >
        <textarea
          nz-input
          formControlName="descriptionCtrl"
          placeholder="{{ 'deviceDescription' | translate }}"
          [nzAutosize]="{ minRows: 4 }"
        ></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "location" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="Location is required"
      >
        <input
          type="text"
          nz-input
          formControlName="locationCtrl"
          placeholder="{{ 'location' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "environment" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <nz-switch
          formControlName="environmentCtrl"
          nzCheckedChildren="{{ 'indoor' | translate }}"
          nzUnCheckedChildren="{{ 'outdoor' | translate }}"
        >
        </nz-switch>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "stage" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <nz-switch
          formControlName="stageCtrl"
          nzCheckedChildren="{{ 'production' | translate }}"
          nzUnCheckedChildren="{{ 'demonstration' | translate }}"
        >
        </nz-switch>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "active" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <nz-switch
          formControlName="activeCtrl"
          nzCheckedChildren="{{ 'active' | translate }}"
          nzUnCheckedChildren="{{ 'inactive' | translate }}"
        >
        </nz-switch>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "deviceType" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="Device type is required"
      >
        <nz-select
          nzPlaceHolder="{{ 'deviceType' | translate }}"
          formControlName="deviceTypeCtrl"
        >
          <nz-option nzLabel="None"></nz-option>
          <nz-option
            *ngFor="let device of deviceType$ | async"
            [nzValue]="device.Id"
            [nzLabel]="device.Name"
          >
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf="dialogFormGroup.get('activeCtrl').value === true">
      <nz-form-label [nzSm]="6" [nzXs]="24">{{
        "crop" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <nz-select
          nzPlaceHolder="{{ 'crop' | translate }}"
          formControlName="cropCtrl"
          (ngModelChange)="cropChange($event)"
        >
          <nz-option
            *ngFor="let crop of crops$ | async"
            [nzValue]="crop.Id"
            [nzLabel]="crop.Name"
          >
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24">{{
        "Daily Dosing Limit (ml): "
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <input
          type="number"
          nz-input
          formControlName="dosingLimitCtrl"
          placeholder="{{ 'Dosing Limit millilitres' }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
    <div *ngIf="isEditing">
      <nz-divider nzText="{{ 'calibration' | translate }}"></nz-divider>
      <div *ngIf="isCalibrationInProgress">
        <div fxLayout="row wrap" fxLayoutAlign="space-between">
          <div>
            Follow the instructions on the device. To end the process click
            "Finish".
          </div>
          <nz-form-item>
            <button
              nz-button
              nzType="primary"
              (click)="onClickContinueCalibration(activeDevice)"
            >
              Continue
            </button>
          </nz-form-item>
          <nz-form-item>
            <button
              nz-button
              nzType="secondary"
              (click)="onClickDoneCalibration()"
            >
              Finish
            </button>
          </nz-form-item>
        </div>
      </div>
      <div *ngIf="!isCalibrationInProgress">
        <div fxLayout="row wrap" fxLayoutAlign="space-between">
          <nz-form-item>
            <button
              nz-button
              nzType="primary"
              (click)="onClickStartPhCalibration(activeDevice)"
            >
              Start pH sensor calibration
            </button>
          </nz-form-item>
          <nz-form-item>
            <button
              nz-button
              nzType="primary"
              (click)="onClickStartEcCalibration(activeDevice)"
            >
              Start EC sensor calibration
            </button>
          </nz-form-item>
        </div>
      </div>
    </div>
  </form>
</ng-template>
