import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DeviceType } from "../../../../models/device-type";
import {
  AngularFirestoreCollection,
  AngularFirestore,
} from "@angular/fire/firestore";

@Injectable({
  providedIn: "root",
})
export class DeviceTypeService {
  public collection: AngularFirestoreCollection<DeviceType>;

  constructor(private db: AngularFirestore) {
    this.collection = this.db.collection<DeviceType>("devicetypes");
  }

  getList(): Observable<Array<DeviceType>> {
    return this.collection.valueChanges({ idField: "Id" });
  }

  async add(data: DeviceType): Promise<void> {
    const docRef = await this.collection.add(data);
    data.Id = docRef.id;
    this.edit(data);
  }

  edit(data: DeviceType): void {
    this.collection.doc(data.Id).update(data);
  }

  delete(id: string): void {
    this.collection.doc(id).delete();
  }
}
