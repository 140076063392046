export class Attachment {
  Id: number;
  Filename: string;
  Bucket: string;
  Path: string;
  ContentType: string;
  ContentLength: number;
  Link: string;
  Progress: number;
  TempFileName: string;

  constructor() {
    this.Id = 0;
  }
}
