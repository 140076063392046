import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Company } from "../../../../models/company";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  public collection: AngularFirestoreCollection<Company>;

  constructor(private db: AngularFirestore) {
    this.collection = this.db.collection<Company>("companies", (ref) =>
      ref.orderBy("Name")
    );
  }

  getList(): Observable<Array<Company>> {
    return this.collection.valueChanges({ idField: "Id" });
  }

  async add(data: Company): Promise<void> {
    const docRef = await this.collection.add(data);
    data.Id = docRef.id;
    this.edit(data);
  }

  edit(data: Company): void {
    this.collection.doc(data.Id).update(data);
  }

  delete(id: string): void {
    this.collection.doc(id).delete();
  }
}
