import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Device } from "../../../../models/device";
import {
  AngularFirestoreCollection,
  AngularFirestore,
} from "@angular/fire/firestore";
import { UserService } from "./user.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";

import firebase from "firebase/app";
import { Crop } from "../../../../models/crop";

@Injectable({
  providedIn: "root",
})
export class DeviceService {
  public collection: AngularFirestoreCollection<Device>;
  constructor(
    private db: AngularFirestore,
    private userService: UserService,
    private http: HttpClient
  ) {
    this.collection = this.db.collection<Device>("devices", (ref) => {
      const c1 = ref.where(
        "Company.Id",
        "==",
        this.userService.self.Company.Id
      );
      const c2 = c1.orderBy("DeviceZone");
      return c2;
    });
  }

  async list(
    company: string
  ): Promise<firebase.firestore.QuerySnapshot<Device>> {
    return (
      (await firebase
        .firestore()
        .collection("devices")
        .where("Company.Id", "==", company)
        // Only show hydroponics devices in the dashboard.
        .where("RegistryId", "==", "plantos_indoor_autodose")
        .get()) as firebase.firestore.QuerySnapshot<Device>
    );
  }

  getList(): Observable<Array<Device>> {
    return this.db
      .collection<Device>("devices", (ref) => {
        return (
          ref
            .where("Company.Id", "==", this.userService.self.Company.Id)
            // Only show hydroponics devices in the dashboard.
            .where("RegistryId", "==", "plantos_indoor_autodose")
        );
      })
      .valueChanges({ idField: "Id" });
  }

  getActiveDevices(
    cropId: string
  ): Observable<
    (Device & {
      Id: string;
    })[]
  > {
    const list$ = this.db
      .collection<Device>("devices", (ref) => {
        const c1 = ref.where(
          "Company.Id",
          "==",
          this.userService.self.Company.Id
        );
        const c2 = c1.where("CropId", "==", cropId);
        const c3 = c2.orderBy("DeviceZone");
        return c3;
      })
      .valueChanges({ idField: "Id" });
    return list$;
  }

  getdeviceByCropId(
    cropId: string
  ): Observable<
    (Device & {
      Id: string;
    })[]
  > {
    const list$ = this.db
      .collection<Device>("devices", (ref) => {
        const c1 = ref.where("CropId", "==", cropId);
        return c1;
      })
      .valueChanges({ idField: "Id" });
    return list$;
  }

  get(Id: string): Observable<firebase.firestore.DocumentSnapshot<Device>> {
    return this.db.collection<Device>("devices").doc<Device>(Id).get();
  }

  getRecipeDevices(): Observable<Array<Device>> {
    const list$ = this.collection.valueChanges({ idField: "Id" });
    return list$ as Observable<Array<Device>>;
  }

  async add(data: Device): Promise<void> {
    data.RegistryId = "plantos_indoor_autodose"; // this.userService.self.Company.RegistryId;
    data.Company = this.userService.self.Company;
    const docRef = await this.collection.add(data);
    data.Id = docRef.id;
    this.edit(docRef.id, data);
  }

  async edit(id: string, data: Device): Promise<void> {
    // data.RegistryId = this.userService.self.Company.RegistryId;
    data.Company = this.userService.self.Company;

    console.log(`updating device ${id} => ${JSON.stringify(data)}`);
    await this.collection.doc(id).update(data);

    // Look up the assigned crop. Then look up the recipe for that
    // crop.
    if (data.CropId != null) {
      const crop = await this.get2(data.CropId);

      const recipes = crop.data().Recipes;
      if (recipes.length > 1) {
        console.error(`expected at most one recipe: got ${recipes.length}`);
      }

      if (recipes.length == 0) {
        return;
      }

      const recipe = recipes[0];

      console.log(
        `device ${id} is assigned crop ${crop.id} which has recipe ${recipe.Id}. updating device config.`
      );

      this.updateDeviceConfig(
        data.RegistryId,
        data.DeviceId,
        data.SumpTankCapacityLitres,
        recipe.EC.Min,
        recipe.EC.Optimal,
        recipe.pH.Max,
        data.MaxFrequency
      );
    }
  }

  async get2(
    cropId: string
  ): Promise<firebase.firestore.QueryDocumentSnapshot<Crop>> {
    return (await firebase
      .firestore()
      .collection("crops")
      .doc(cropId)
      .get()) as firebase.firestore.QueryDocumentSnapshot<Crop>;
  }

  delete(id: string): Promise<void> {
    return this.collection.doc(id).delete();
  }

  getCurrentUserToken(): Promise<string> {
    return firebase.auth().currentUser.getIdToken(true);
  }

  /**
   *
   * @param deviceCode The physical device identifier - i.e. esp32_abcdef
   */
  async updateDeviceConfig(
    registryId: string,
    deviceCode: string,
    sumpTankCapacityLitres: number,
    minEc: number,
    optEC: number,
    maxPh: number,
    MaxFrequency: number
  ): Promise<boolean> {
    const data = {
      RegistryId: registryId,
      DeviceId: deviceCode,
      uid: firebase.auth().currentUser.uid,
      sumpTankCapacityLitres: sumpTankCapacityLitres,
      minEC: minEc,
      optEC: optEC,
      maxPH: maxPh,
      MaxFrequency: MaxFrequency,
    };
    const authToken = await this.getCurrentUserToken();
    const headers = new HttpHeaders({ Authorization: "Bearer " + authToken });
    return this.http
      .post(
        "https://us-central1-plantos-development-225209.cloudfunctions.net/updateDeviceConfig",
        { data: data },
        { headers: headers, observe: "response" }
      )
      .pipe(
        map((response) => {
          return response.status === 200;
        })
      )
      .toPromise();
  }

  async calibrate(
    calibrationCommand: string,
    registryId: string,
    deviceId: string
  ): Promise<boolean> {
    const data = {
      CalibrationCommand: calibrationCommand,
      RegistryId: registryId,
      DeviceId: deviceId,
      uid: firebase.auth().currentUser.uid,
    };
    console.log(data);
    console.log(data.CalibrationCommand);
    const authToken = await this.getCurrentUserToken();
    const headers = new HttpHeaders({ Authorization: "Bearer " + authToken });
    return this.http
      .post(
        "https://us-central1-plantos-development-225209.cloudfunctions.net/calibrate",
        { data: data },
        { headers: headers, observe: "response" }
      )
      .pipe(
        map((response) => {
          return response.status === 200;
        })
      )
      .toPromise();
  }
}
