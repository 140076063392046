import { Component, OnInit } from "@angular/core";
import { ToolbarService } from "src/app/services/toolbar.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-outdoor-dashboard",
  templateUrl: "./outdoor-dashboard.component.html",
  styleUrls: ["./outdoor-dashboard.component.scss"],
})
export class OutdoorDashboardComponent implements OnInit {
  constructor(
    private toolbar: ToolbarService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate
      .stream("outdoorDashboardSideMenu")
      .subscribe((translation) => {
        this.toolbar.changeHeader(translation);
      });
  }
}
