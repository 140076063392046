import { AbstractControl } from "@angular/forms";
import { ONCE } from "../services/global-constant";

export const recurrenceTaskValidator = (
  control: AbstractControl
): { [key: string]: boolean } => {
  const recurrence = control.get("recurrenceCtrl").value;
  const taskDate = control.get("taskDateCtrl").value;
  const startDate = control.get("startDateCtrl").value;
  const endDate = control.get("endDateCtrl").value;

  if (recurrence === ONCE && !taskDate) {
    return null;
  } else if (recurrence !== ONCE && !startDate && !endDate) {
    return null;
  }
};

export const recurrenceExpenseValidator = (
  control: AbstractControl
): { [key: string]: boolean } => {
  const recurrence = control.get("recurrenceCtrl").value;
  const taskDate = control.get("dateCtrl").value;
  const startDate = control.get("startDateCtrl").value;
  const endDate = control.get("endDateCtrl").value;

  if (recurrence === ONCE && !taskDate) {
    return null;
  } else if (recurrence !== ONCE && !startDate && !endDate) {
    return null;
  }
};
