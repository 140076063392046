import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";

import firebase from "firebase/app";
import { errorHandler } from "../global-error-handler";

@Injectable({
  providedIn: "root",
})
export class AuthenticateService {
  public tokenExpiry: number;
  public token: string;
  public refreshToken: string;

  constructor(private afAuth: AngularFireAuth) {}

  async reset(email: string): Promise<void> {
    console.log("sending password reset to " + email);
    await this.afAuth
      .sendPasswordResetEmail(email)
      .catch((err) => console.error(err));
  }

  async verifyPasswordResetCode(actionCode: string): Promise<string> {
    return await this.afAuth.verifyPasswordResetCode(actionCode);
  }

  async confirmPasswordReset(
    actionCode: string,
    password: string
  ): Promise<void> {
    return await this.afAuth.confirmPasswordReset(actionCode, password);
  }

  login(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return new Promise<firebase.auth.UserCredential>((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(email, password).then(
        async (res) => {
          // Add the user to crash reports.
          errorHandler.setUser(email);
          resolve(res);
        },
        (err) => {
          errorHandler.setUser(undefined);
          reject(err);
        }
      );
    });
  }

  logout(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      errorHandler.setUser(undefined);

      if (firebase.auth().currentUser) {
        this.afAuth.signOut().then(() => resolve(true));
      } else {
        reject();
      }
    });
  }
}
