<nz-layout class="app-layout" *ngIf="self">
  <nz-sider
    class="menu-sidebar"
    nzCollapsible
    nzWidth="256px"
    nzBreakpoint="lg"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
  >
    <app-side-menu [isCollapsed]="isCollapsed"></app-side-menu>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
          <i
            class="trigger"
            nz-icon
            [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
          ></i>
        </span>
        <span id="header-text" style="font-size: 1.5em; font-weight: bold">{{
          headerText
        }}</span>
        <nz-avatar
          class="avatar"
          nzText="{{ self.Name[0].toUpperCase() }}"
          nz-popover
          nzPlacement="bottomRight"
          [nzContent]="contentTemplate"
          nzTrigger="click"
        ></nz-avatar>
      </div>
      <ng-template #contentTemplate>
        <div class="popoverAvatar">
          <div nz-row>
            <div nz-col [nzSpan]="8">
              <nz-avatar
                class="popup-avatar"
                [nzSize]="64"
                nzText="{{ self.Name[0].toUpperCase() }}"
              >
              </nz-avatar>
            </div>
            <div nz-col [nzSpan]="16">
              <h3 class="companyTitle">{{ self.Company.Name }}</h3>
              <div>{{ self.Email }}</div>
              <div>{{ self.Name }}</div>
            </div>
          </div>
          <br />
          <div nz-row>
            <div nz-col>
              <div nz-row>
                Language: <app-select-language></app-select-language>
              </div>
              <br />
              <div nz-row>
                <button nz-button nzType="primary" (click)="signOut()">
                  Sign Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
    <nz-footer>
      Copyright &copy; Integrasi Hijauan Sdn Bhd / plantOS
    </nz-footer>
  </nz-layout>
</nz-layout>
