import { Component, OnInit, TemplateRef } from "@angular/core";
import { DeviceService } from "src/app/services/device.service";
import { DeviceTypeService } from "src/app/services/devicetype.service";
import { Device } from "../../../../../models/device";
import { DeviceType } from "../../../../../models/device-type";
import { Company } from "../../../../../models/company";
import { NzModalRef, NzModalService, NzMessageService } from "ng-zorro-antd";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToolbarService } from "src/app/services/toolbar.service";
import { Observable } from "rxjs";
import { UserService } from "src/app/services/user.service";
import { User } from "../../../../../models/user";
import { Crop } from "../../../../../models/crop";
import { CropService } from "src/app/services/crop.service";
import { Recipe } from "../../../../../models/recipe";
import { RecipeService } from "src/app/services/recipe.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-device",
  templateUrl: "./device.component.html",
  styleUrls: ["./device.component.scss"],
})
export class DeviceComponent implements OnInit {
  public tplModal: NzModalRef;
  public dialogFormGroup: FormGroup;
  public tplModalButtonLoading = false;
  public device$: Observable<Device[]>;
  public deviceType$: Observable<DeviceType[]>;
  public selectedCrop: Crop = {} as Crop;
  public selectedRecipe: Recipe = {} as Recipe;
  public recipes: Recipe[] = [{} as Recipe];
  public self: User;
  public crops$: Observable<Crop[]>;
  public activeDevice: Device;
  public isEditing = false;
  public cancelTranslate: string;
  public saveTranslate: string;
  public yesTranslate: string;
  public noTranslate: string;
  public areYouSureDeletionTranslate: string;

  private isCalibrationInProgress = false;

  constructor(
    private toolbar: ToolbarService,
    private deviceService: DeviceService,
    private deviceTypeService: DeviceTypeService,
    private modalService: NzModalService,
    private userService: UserService,
    private message: NzMessageService,
    private formBuilder: FormBuilder,
    public cropService: CropService,
    private recipeService: RecipeService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.stream("deviceManagement").subscribe((translation) => {
      this.toolbar.changeHeader(translation);
    });
    this.translate.stream("cancel").subscribe((translation) => {
      this.cancelTranslate = translation;
    });
    this.translate.stream("save").subscribe((translation) => {
      this.saveTranslate = translation;
    });
    this.translate.stream("yes").subscribe((translation) => {
      this.yesTranslate = translation;
    });
    this.translate.stream("no").subscribe((translation) => {
      this.noTranslate = translation;
    });
    this.translate.stream("areYouSureForDelete").subscribe((translation) => {
      this.areYouSureDeletionTranslate = translation;
    });
    this.device$ = this.deviceService.getList();
    this.recipeService
      .getList()
      .subscribe((list) => (this.recipes = [...list]));
    this.deviceType$ = this.deviceTypeService.getList();
    this.crops$ = this.cropService.getList();
    this.self = this.userService.self;
  }

  editDialog(
    tplTitle: TemplateRef<never>,
    tplContent: TemplateRef<never>,
    data: Device,
    isEditing: boolean
  ): void {
    if (!data) {
      data = {} as Device;
      data.Company = {} as Company;
      data.DeviceType = {} as DeviceType;
    }
    const item = { ...data };
    this.activeDevice = item;
    this.dialogFormGroup = this.formBuilder.group({
      zoneCtrl: [item.DeviceZone, Validators.required],
      deviceIdCtrl: [item.DeviceId, Validators.required],
      activeCtrl: [item.Active],
      descriptionCtrl: [item.Description],
      environmentCtrl: [item.Indoor],
      stageCtrl: [!item.Demo],
      locationCtrl: [item.Location, Validators.required],
      deviceTypeCtrl: [item.DeviceType.Id, Validators.required],
      dosingLimitCtrl: [item.NutrientDailyDosingLimitMl],
      cropCtrl: [item.CropId],
    });
    this.isEditing = isEditing;
    this.tplModal = this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: [
        {
          label: this.cancelTranslate,
          type: "danger",
          onClick: () => {
            this.tplModal.destroy();
            this.isEditing = false;
          },
        },
        {
          label: this.saveTranslate,
          type: "primary",
          disabled: () => this.dialogFormGroup.invalid,
          onClick: async () => {
            const form = this.dialogFormGroup.value;
            item.DeviceZone = form.zoneCtrl;
            item.DeviceId = form.deviceIdCtrl;
            item.Active = form.activeCtrl;
            item.Description = form.descriptionCtrl;
            item.Indoor = form.environmentCtrl;
            item.Outdoor = !form.environmentCtrl;
            item.Demo = !form.stageCtrl;
            item.Location = form.locationCtrl;
            item.NutrientDailyDosingLimitMl = form.dosingLimitCtrl;
            item.DeviceType.Id = form.deviceTypeCtrl;
            item.CropId = form.cropCtrl;
            if (!item.Id) {
              this.deviceService.add(item);
            } else {
              this.deviceService.edit(item.Id, item);
            }
            this.displayResult(true, true);
            this.tplModal.destroy();
            this.isEditing = false;
          },
        },
      ],
      nzMaskClosable: false,
      nzClosable: true,
    });
  }

  deleteDialog(item: Device): void {
    if (!item) {
      this.message.create("success", "Error delete item");
    } else {
      this.tplModal = this.modalService.create({
        nzTitle: this.areYouSureDeletionTranslate,
        nzContent: `${item.DeviceZone} (${item.DeviceId})`,
        nzOkText: this.yesTranslate,
        nzOkType: "danger",
        nzOnOk: () => {
          this.deviceService.delete(item.Id);
          this.displayResult(true, false);
        },
        nzCancelText: this.noTranslate,
      });
    }
  }

  displayResult(response: boolean, isSave: boolean): void {
    if (response) {
      const msg = isSave
        ? "Item successfully saved"
        : "Item successfully deleted";
      this.message.create("success", msg);
    } else {
      const msg = isSave ? "Error saving item" : "Error deleting item";
      this.message.create("warning", msg);
    }
  }

  cropChange(value: Crop): void {
    console.log(`would change crop to ${JSON.stringify(value)}`);
  }

  // onClickStartPhCalibration handles click events from the "ph calibration"
  // button. This sends a message to the device to begin the ph calibration
  // process - the user will have to follow the on-screen instructions on the
  // device in order to continue.
  async onClickStartPhCalibration(device: Device): Promise<boolean> {
    this.isCalibrationInProgress = true;
    return await this.deviceService.calibrate(
      "ph_auto",
      device.RegistryId,
      device.DeviceId
    );
  }

  // onClickStartEcCalibration handles click events from the "EC calibration"
  // button. Similarly to `onClickStartPhCalibration` it starts the calibration
  // process on the device.
  async onClickStartEcCalibration(device: Device): Promise<boolean> {
    this.isCalibrationInProgress = true;
    return await this.deviceService.calibrate(
      "ec_auto",
      device.RegistryId,
      device.DeviceId
    );
  }

  // onClickContinueCalibration handles click events from the "continue"
  // button. This notifies the device to proceed to the next stage of the
  // calibration process.
  async onClickContinueCalibration(device: Device): Promise<boolean> {
    this.isCalibrationInProgress = true;
    return await this.deviceService.calibrate(
      "continue",
      device.RegistryId,
      device.DeviceId
    );
  }

  // onClickDoneCalibration handles click events from the "finish"
  // button. This ends the calibration process.
  onClickDoneCalibration(): void {
    this.isCalibrationInProgress = false;
  }
}
