<div fxLayout="row wrap" fxLayoutAlign="end stretch">
  <nz-button-group>
    <button
      nz-button
      nzType="primary"
      (click)="
        editCropRevenueDialog(tplCropRevenueTitle, tplCropRevenueContent, null)
      "
    >
      <i nz-icon nzType="plus" nzTheme="outline"></i>
      <span>{{ "cropRevenue" | translate }}</span>
    </button>
    <button
      nz-button
      nzType="primary"
      (click)="editRevenueDialog(tplRevenueTitle, tplRevenueContent, null)"
    >
      <i nz-icon nzType="plus" nzTheme="outline"></i>
      <span>{{ "revenue" | translate }}</span>
    </button>
    <button
      nz-button
      nzType="primary"
      (click)="
        editExpenseDialog(tplExpenseTitle, tplExpenseContent, null, false)
      "
    >
      <i nz-icon nzType="plus" nzTheme="outline"></i>
      <span>{{ "expenses" | translate }}</span>
    </button>
  </nz-button-group>
</div>
<br />
<form nz-form [formGroup]="formGroup" nzLayout="inline">
  <nz-form-item>
    <nz-form-label>{{ "period" | translate }}</nz-form-label>
    <nz-form-control>
      <nz-input-group nzCompact>
        <nz-select
          formControlName="monthCtrl"
          nzPlaceHolder="Month"
          nzAllowClear
        >
          <nz-option nzLabel="Jan" nzValue="1"></nz-option>
          <nz-option nzLabel="Feb" nzValue="2"></nz-option>
          <nz-option nzLabel="Mar" nzValue="3"></nz-option>
          <nz-option nzLabel="Apr" nzValue="4"></nz-option>
          <nz-option nzLabel="May" nzValue="5"></nz-option>
          <nz-option nzLabel="Jun" nzValue="6"></nz-option>
          <nz-option nzLabel="Jul" nzValue="7"></nz-option>
          <nz-option nzLabel="Aug" nzValue="8"></nz-option>
          <nz-option nzLabel="Sep" nzValue="9"></nz-option>
          <nz-option nzLabel="Oct" nzValue="10"></nz-option>
          <nz-option nzLabel="Nov" nzValue="11"></nz-option>
          <nz-option nzLabel="Dec" nzValue="12"></nz-option>
        </nz-select>
        <nz-select
          formControlName="yearCtrl"
          nzPlaceHolder="{{ 'year' | translate }}"
          nzAllowClear
        >
          <nz-option
            *ngFor="let i of years"
            [nzLabel]="i"
            [nzValue]="i"
          ></nz-option>
        </nz-select>
        <button nz-button nzType="primary" nzSearch (click)="search()">
          <i nz-icon nzType="search"></i>
        </button>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
</form>
<br />
<nz-table
  nzTitle="{{ 'cropBreakdown' | translate }}"
  nzBordered
  nzSize="middle"
  #cropRevenues
  [nzData]="cropRevenues$ | async"
  [nzLoading]="!(cropRevenues$ | async)"
>
  <thead>
    <tr>
      <th>{{ "crop" | translate }}</th>
      <th width="100">{{ "yield" | translate }}</th>
      <th width="100">{{ "priceKg" | translate }}</th>
      <th width="100">{{ "cycleWeek" | translate }}</th>
      <th width="100">{{ "outputKgWeek" | translate }}</th>
      <th width="100">{{ "period" | translate }}</th>
      <th width="100">{{ "revenue" | translate }}</th>
      <th>{{ "action" | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of cropRevenues.data">
      <td>{{ item.CropName }}</td>
      <td>{{ item.Yield }}</td>
      <td>{{ item.Price }}</td>
      <td>{{ item.CropCycle }}</td>
      <td>{{ item.Output }}</td>
      <td>{{ item.MonthName }} {{ item.Year }}</td>
      <td class="amount">{{ item.CropRevenue | number: "1.2-2" }}</td>
      <td style="width: 90px">
        <button
          nz-button
          nzType="default"
          nzShape="circle"
          (click)="
            editCropRevenueDialog(
              tplCropRevenueTitle,
              tplCropRevenueContent,
              item
            )
          "
        >
          <i nz-icon nzType="edit"></i></button
        >&nbsp;
        <button
          nz-button
          nzType="danger"
          nzShape="circle"
          (click)="deleteCropRevenueDialog(item)"
        >
          <i nz-icon nzType="delete"></i>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>
&nbsp;
<nz-table
  nzTitle="{{ 'otherRevenue' | translate }}"
  nzBordered
  nzSize="middle"
  #otherRevenues
  [nzData]="revenues$ | async"
  [nzLoading]="!(revenues$ | async)"
>
  <thead>
    <tr>
      <th>{{ "name" | translate }}</th>
      <th width="100">{{ "date" | translate }}</th>
      <th width="100">{{ "amount" | translate }}</th>
      <th>{{ "action" | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of otherRevenues.data">
      <td>{{ item.Name }}</td>
      <td>{{ item.Date.toDate() | date: "mediumDate" }}</td>
      <td class="amount">{{ item.Amount | number: "1.2-2" }}</td>
      <td style="width: 90px">
        <button
          nz-button
          nzType="default"
          nzShape="circle"
          (click)="editRevenueDialog(tplRevenueTitle, tplRevenueContent, item)"
        >
          <i nz-icon nzType="edit"></i></button
        >&nbsp;
        <button
          nz-button
          nzType="danger"
          nzShape="circle"
          (click)="deleteRevenueDialog(item)"
        >
          <i nz-icon nzType="delete"></i>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>
&nbsp;
<nz-table
  nzTitle="Expenses"
  nzBordered
  nzSize="middle"
  #expenses
  [nzData]="expenses$ | async"
  [nzLoading]="!(expenses$ | async)"
>
  <thead>
    <tr>
      <th>{{ "name" | translate }}</th>
      <th width="100">{{ "date" | translate }}</th>
      <th width="100">{{ "amount" | translate }}</th>
      <th>{{ "action" | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of expenses.data">
      <td>{{ item.Name }}</td>
      <td>{{ item.Date.toDate() | date: "mediumDate" }}</td>
      <td class="amount">{{ item.Amount | number: "1.2-2" }}</td>
      <td style="width: 90px">
        <button
          nz-button
          nzType="default"
          nzShape="circle"
          (click)="editExpenseDialog(tplExpenseTitle, tplExpenseContent, item)"
        >
          <i nz-icon nzType="edit"></i></button
        >&nbsp;
        <button
          nz-button
          nzType="danger"
          nzShape="circle"
          (click)="deleteExpenseDialog(item)"
        >
          <i nz-icon nzType="delete"></i>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>

<ng-template #tplCropRevenueTitle>
  <span>{{ "manageCropRevenue" | translate }}</span>
</ng-template>
<ng-template #tplCropRevenueContent>
  <form nz-form [formGroup]="cropRevenueFormGroup" class="dialog-form">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "name" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="Crop name is required"
      >
        <input
          type="text"
          nz-input
          formControlName="cropNameCtrl"
          placeholder="{{ 'name' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "yield" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Yield is required">
        <nz-input-group nzAddOnAfter="{{ 'perKg' | translate }}">
          <input
            type="text"
            nz-input
            formControlName="yieldCtrl"
            type="number"
            placeholder="{{ 'yield' | translate }}"
            autocomplete="off"
            maxlength="255"
            required
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "price" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Price is required">
        <nz-input-group
          nzAddOnBefore="$"
          nzAddOnAfter="{{ 'perKg' | translate }}"
        >
          <input
            type="text"
            nz-input
            formControlName="priceCtrl"
            type="number"
            placeholder="{{ 'priceKg' | translate }}"
            autocomplete="off"
            maxlength="255"
            required
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "cropCycle" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Cycle is required">
        <nz-input-group nzAddOnAfter="{{ 'weeks' | translate }}">
          <input
            type="text"
            nz-input
            formControlName="cycleCtrl"
            type="number"
            placeholder="{{ 'cropCycleInWeek' | translate }}"
            autocomplete="off"
            maxlength="255"
            required
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "output" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Output is required">
        <nz-input-group nzAddOnAfter="{{ 'kgWeek' | translate }}">
          <input
            type="text"
            nz-input
            formControlName="outputCtrl"
            type="number"
            placeholder="{{ 'outputKgWeek' | translate }}"
            autocomplete="off"
            maxlength="255"
            required
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "period" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Period is required">
        <nz-input-group>
          <nz-select
            formControlName="monthCtrl"
            nzPlaceHolder="{{ 'period' | translate }}"
            nzAllowClear
          >
            <nz-option nzLabel="Jan" nzValue="1"></nz-option>
            <nz-option nzLabel="Feb" nzValue="2"></nz-option>
            <nz-option nzLabel="Mar" nzValue="3"></nz-option>
            <nz-option nzLabel="Apr" nzValue="4"></nz-option>
            <nz-option nzLabel="May" nzValue="5"></nz-option>
            <nz-option nzLabel="Jun" nzValue="6"></nz-option>
            <nz-option nzLabel="Jul" nzValue="7"></nz-option>
            <nz-option nzLabel="Aug" nzValue="8"></nz-option>
            <nz-option nzLabel="Sep" nzValue="9"></nz-option>
            <nz-option nzLabel="Oct" nzValue="10"></nz-option>
            <nz-option nzLabel="Nov" nzValue="11"></nz-option>
            <nz-option nzLabel="Dec" nzValue="12"></nz-option>
          </nz-select>
          <nz-select
            formControlName="yearCtrl"
            nzPlaceHolder="{{ 'year' | translate }}"
            nzAllowClear
          >
            <nz-option
              *ngFor="let i of years"
              [nzLabel]="i"
              [nzValue]="i"
            ></nz-option>
          </nz-select>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "cropRevenue" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Revenue is required">
        <nz-input-group nzAddOnBefore="$">
          <input
            type="text"
            nz-input
            formControlName="revenueCtrl"
            type="number"
            placeholder="{{ 'cropRevenue' | translate }}"
            autocomplete="off"
            maxlength="255"
            required
            readonly
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>

<ng-template #tplRevenueTitle>
  <span>{{ "manageRevenue" | translate }}</span>
</ng-template>
<ng-template #tplRevenueContent>
  <form nz-form [formGroup]="revenueFormGroup" class="dialog-form">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "revenue" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Revenue is required">
        <input
          type="text"
          nz-input
          formControlName="nameCtrl"
          placeholder="{{ 'revenue' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "amount" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Amount is required">
        <input
          type="text"
          nz-input
          formControlName="amountCtrl"
          type="number"
          placeholder="{{ 'amount' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "revenueDate" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="Date is required">
        <nz-date-picker formControlName="dateCtrl" required></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>

<ng-template #tplExpenseTitle>
  <span>{{ "manageExpenses" | translate }}</span>
</ng-template>
<ng-template #tplExpenseContent>
  <form nz-form [formGroup]="expenseFormGroup" class="dialog-form">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "expenses" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Expense is required">
        <input
          type="text"
          nz-input
          formControlName="nameCtrl"
          placeholder="{{ 'expenses' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "amount" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Amount is required">
        <input
          type="text"
          nz-input
          formControlName="amountCtrl"
          type="number"
          placeholder="{{ 'amount' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "recurrence" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="18"
        [nzXs]="24"
        nzErrorTip="Recurrence is required"
      >
        <nz-input-group nzCompact>
          <nz-select
            formControlName="recurrenceCtrl"
            nzAllowClear
            nzPlaceHolder="{{ 'recurrence' | translate }}"
            required
            style="width: 200px"
            #recurrence
          >
            <nz-option
              nzValue="once"
              nzLabel="{{ 'once' | translate }}"
            ></nz-option>
            <nz-option
              nzValue="daily"
              nzLabel="{{ 'daily' | translate }}"
            ></nz-option>
            <nz-option
              nzValue="weekly"
              nzLabel="{{ 'weekly' | translate }}"
            ></nz-option>
            <nz-option
              nzValue="bi-weekly"
              nzLabel="{{ 'biWeekly' | translate }}"
            ></nz-option>
            <nz-option
              nzValue="monthly"
              nzLabel="{{ 'monthly' | translate }}"
            ></nz-option>
            <nz-option
              nzValue="bi-monthly"
              nzLabel="{{ 'biMonthly' | translate }}"
            ></nz-option>
          </nz-select>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item
      *ngIf="expenseFormGroup.get('recurrenceCtrl').value !== 'once'"
    >
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "startDate" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="Date is required">
        <nz-date-picker
          formControlName="startDateCtrl"
          required
        ></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item
      *ngIf="expenseFormGroup.get('recurrenceCtrl').value !== 'once'"
    >
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "endDate" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="Date is required">
        <nz-date-picker formControlName="endDateCtrl" required></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item
      *ngIf="expenseFormGroup.get('recurrenceCtrl').value === 'once'"
    >
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "expenseDate" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="Date is required">
        <nz-date-picker formControlName="dateCtrl" required></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>
