import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  AngularFirestoreCollection,
  AngularFirestore,
} from "@angular/fire/firestore";
import { leftJoin } from "../models/docJoin";
import { UserService } from "./user.service";
import { CropRevenue, Revenue, Expense } from "../../../../models/profit-loss";
import moment from "moment";

@Injectable({
  providedIn: "root",
})
export class ProfitLossService {
  public cropRevenues: AngularFirestoreCollection<CropRevenue>;
  public revenues: AngularFirestoreCollection<Revenue>;
  public expenses: AngularFirestoreCollection<Expense>;

  constructor(private db: AngularFirestore, private userService: UserService) {
    this.cropRevenues = this.db.collection<CropRevenue>(
      "cropRevenues",
      (ref) => {
        const c1 = ref.where(
          "Company.Id",
          "==",
          this.userService.self.Company.Id
        );
        const c2 = c1.orderBy("Year");
        const c3 = c2.orderBy("Month");
        const c4 = c3.orderBy("CropName");
        return c4;
      }
    );
    this.revenues = this.db.collection<Revenue>("revenues", (ref) => {
      const c1 = ref.where(
        "Company.Id",
        "==",
        this.userService.self.Company.Id
      );
      const c2 = c1.orderBy("Date");
      const c3 = c2.orderBy("Name");
      return c3;
    });
    this.expenses = this.db.collection<Expense>("expenses", (ref) => {
      const c1 = ref.where(
        "Company.Id",
        "==",
        this.userService.self.Company.Id
      );
      const c2 = c1.orderBy("Date");
      const c3 = c2.orderBy("Name");
      return c3;
    });
  }

  getCropRevenueList(): Observable<Array<CropRevenue>> {
    const list$ = this.cropRevenues.valueChanges({ idField: "Id" });
    const j1$ = list$.pipe(leftJoin(this.db, "Company", "Id", "companies"));
    return j1$ as Observable<Array<CropRevenue>>;
  }

  getRevenueList(): Observable<Array<Revenue>> {
    const list$ = this.revenues.valueChanges({ idField: "Id" });
    const j1$ = list$.pipe(leftJoin(this.db, "Company", "Id", "companies"));
    return j1$ as Observable<Array<Revenue>>;
  }

  getExpenseList(): Observable<Array<Expense>> {
    const list$ = this.expenses.valueChanges({ idField: "Id" });
    const j1$ = list$.pipe(leftJoin(this.db, "Company", "Id", "companies"));
    return j1$ as Observable<Array<Expense>>;
  }

  getCropRevenueListByMonthYear(
    month: number,
    year: number
  ): Observable<Array<CropRevenue>> {
    const list$ = this.db
      .collection<CropRevenue>("cropRevenues", (ref) => {
        const c1 = ref.where(
          "Company.Id",
          "==",
          this.userService.self.Company.Id
        );
        const c2 = c1.where("Month", "==", Number(month));
        return c2.where("Year", "==", Number(year));
      })
      .valueChanges({ idField: "Id" });
    return list$ as Observable<Array<CropRevenue>>;
  }

  getRevenueListByMonthYear(
    month: number,
    year: number
  ): Observable<Array<Revenue>> {
    const d1 = moment().set({
      year: year,
      month: month - 1,
      date: 1,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const d2 = moment().set({
      year: year,
      month: month,
      date: 1,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    const list$ = this.db
      .collection<Revenue>("revenues", (ref) => {
        const c1 = ref.where(
          "Company.Id",
          "==",
          this.userService.self.Company.Id
        );
        const c2 = c1.where("Date", ">=", d1.toDate());
        const c3 = c2.where("Date", "<", d2.toDate());
        return c3;
      })
      .valueChanges({ idField: "Id" });
    return list$ as Observable<Array<Revenue>>;
  }

  getExpenseListByMonthYear(
    month: number,
    year: number
  ): Observable<Array<Expense>> {
    const d1 = moment().set({
      year: year,
      month: month - 1,
      date: 1,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const d2 = moment().set({
      year: year,
      month: month,
      date: 1,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    const list$ = this.db
      .collection<Expense>("expenses", (ref) => {
        const c1 = ref.where(
          "Company.Id",
          "==",
          this.userService.self.Company.Id
        );
        const c2 = c1.where("Date", ">=", d1.toDate());
        const c3 = c2.where("Date", "<", d2.toDate());
        return c3;
      })
      .valueChanges({ idField: "Id" });
    return list$ as Observable<Array<Expense>>;
  }

  async addCropRevenue(data: CropRevenue): Promise<void> {
    data.Company = this.userService.self.Company;
    const docRef = await this.cropRevenues.add(data);
    data.Id = docRef.id;
    this.editCropRevenue(data);
  }

  editCropRevenue(data: CropRevenue): void {
    data.Company = this.userService.self.Company;
    this.cropRevenues.doc(data.Id).update(data);
  }

  deleteCropRevenue(id: string): void {
    this.cropRevenues.doc(id).delete();
  }

  async addRevenue(data: Revenue): Promise<void> {
    data.Company = this.userService.self.Company;
    const docRef = await this.revenues.add(data);
    data.Id = docRef.id;
    this.editRevenue(data);
  }

  editRevenue(data: Revenue): void {
    data.Company = this.userService.self.Company;
    this.revenues.doc(data.Id).update(data);
  }

  deleteRevenue(id: string): void {
    this.revenues.doc(id).delete();
  }

  async addExpense(data: Expense): Promise<void> {
    data.Company = this.userService.self.Company;
    const docRef = await this.expenses.add(data);
    data.Id = docRef.id;
    this.editExpense(data);
  }

  addExpenseList(data: Array<Expense>): void {
    data.forEach((itm) => {
      itm.Company = this.userService.self.Company;
    });

    const parent = data[0];

    data.shift();
    const child = [...data];

    this.expenses.add(parent).then((x) => {
      parent.Id = x.id;
      this.editExpense(parent);

      child.forEach((element) => {
        element.ParentId = x.id;
        this.expenses.add(element).then((docRef) => {
          element.Id = docRef.id;
          this.editExpense(element);
        });
      });
    });
  }

  editExpense(data: Expense): void {
    data.Company = this.userService.self.Company;
    this.expenses.doc(data.Id).update(data);
  }

  deleteExpense(id: string): void {
    this.expenses.doc(id).delete();
  }
}
