<nz-divider nzText="{{ 'coreInformation' | translate }}"></nz-divider>
<nz-skeleton [nzActive]="true" [nzLoading]="loadCard">
  <div
    *ngIf="deviceList.length == 0"
    [ngStyle]="{ display: 'flex', 'justify-content': 'center' }"
  >
    No devices found. Contact support to add your device.
  </div>
  <div *ngFor="let sensor of deviceList">
    <div *ngIf="sensor.Company.Id === self.Company.Id || self.Role.Admin">
      <h2>
        {{ sensor.DeviceZone }}
        <span *ngIf="!isDeviceDead(sensor)" class="subheader"
          ><span *ngIf="sensor.LatestUpdateTime"
            >{{ "asAt" | translate }}
            {{ sensor.LatestUpdateTime.toDate() | date: "medium" }}</span
          >
          <mat-icon
            [ngStyle]="{ color: '#17b484', margin: '3px', 'font-size': '17px' }"
            >stop_circle</mat-icon
          ></span
        >
        <span *ngIf="isDeviceDead(sensor)" class="subheader"
          ><span *ngIf="sensor.LatestUpdateTime"
            >{{ "asAt" | translate }}
            {{ sensor.LatestUpdateTime.toDate() | date: "medium" }}</span
          >
          <mat-icon
            inline="true"
            [ngStyle]="{ color: '#f7d981', margin: '3px', 'font-size': '17px' }"
            >stop_circle</mat-icon
          ></span
        >
        <div fxLayout="row wrap" fxLayoutAlign="end stretch">
          <button
            nz-button
            nzType="primary"
            [routerLink]="['../details', sensor.Id]"
          >
            {{ "overview" | translate }}
          </button>
        </div>
      </h2>
      <div
        fxLayout="row wrap"
        fxLayoutAlign="start stretch"
        fxLayoutGap="10px grid"
      >
        <div fxFlex="25" fxFlex.lt-lg="25" fxFlex.lt-md="50" fxFlex.lt-sm="100">
          <nz-card>
            <nz-statistic
              [nzValue]="sensor.LatestEC | number"
              nzTitle="Electric Conductivity"
              [nzPrefix]="ecIcon"
              nzSuffix="mS/cm"
              [nzValueStyle]="{ color: '#000' }"
            >
            </nz-statistic>
            <ng-template #ecIcon><i class="fas fa-bolt"></i></ng-template>
          </nz-card>
        </div>
        <div fxFlex="25" fxFlex.lt-lg="25" fxFlex.lt-md="50" fxFlex.lt-sm="100">
          <nz-card>
            <nz-statistic
              [nzValue]="sensor.LatestPH | number"
              nzTitle="pH Level"
              [nzPrefix]="pHIcon"
              nzSuffix=""
              [nzValueStyle]="{ color: '#000' }"
            >
            </nz-statistic>
            <ng-template #pHIcon
              ><i class="fas fa-balance-scale"></i
            ></ng-template>
          </nz-card>
        </div>
        <div fxFlex="25" fxFlex.lt-lg="25" fxFlex.lt-md="50" fxFlex.lt-sm="100">
          <nz-card>
            <nz-statistic
              [nzValue]="sensor.LatestAirTemperature | number"
              nzTitle="Air Temperature"
              [nzPrefix]="airTempIcon"
              nzSuffix="°C"
              [nzValueStyle]="{ color: '#000' }"
            >
            </nz-statistic>
            <ng-template #airTempIcon
              ><i class="fas fa-binoculars"></i
            ></ng-template>
          </nz-card>
        </div>
        <div fxFlex="25" fxFlex.lt-lg="25" fxFlex.lt-md="50" fxFlex.lt-sm="100">
          <nz-card>
            <nz-statistic
              [nzValue]="sensor.LatestHumidity | number"
              nzTitle="Humidity"
              [nzPrefix]="humidIcon"
              nzSuffix="%"
              [nzValueStyle]="{ color: '#000' }"
            >
            </nz-statistic>
            <ng-template #humidIcon><i class="fas fa-tint"></i></ng-template>
          </nz-card>
        </div>
        <br />
      </div>
    </div>
  </div>
</nz-skeleton>
<br />
<nz-divider nzText="{{ 'details' | translate }}"></nz-divider>
<app-crop-details></app-crop-details>
