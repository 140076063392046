<div fxLayout="row wrap" fxLayoutAlign="end stretch">
  <button
    *ngIf="self.Role.Admin || self.Role.Owner"
    nz-button
    nzType="primary"
    (click)="editDialog(tplTitle, tplContent, null, false)"
  >
    <i nz-icon nzType="plus" nzTheme="outline"></i>
    {{ "taskSideMenu" | translate }}
  </button>
</div>

<nz-tabset nzSize="small">
  <nz-tab nzTitle="{{ 'today' | translate }}">
    <nz-table
      nzSize="middle"
      #todayTasks
      [nzData]="today$ | async"
      [nzLoading]="!(today$ | async)"
    >
      <thead>
        <tr>
          <th width="40"></th>
          <th>{{ "taskSideMenu" | translate }}</th>
          <th width="150">{{ "taskDate" | translate }}</th>
          <th>{{ "assigned" | translate }}</th>
          <th width="200">{{ "recurrence" | translate }}</th>
          <th *ngIf="self.Role.Worker"></th>
          <th *ngIf="self.Role.Owner || self.Role.Admin">
            {{ "action" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of todayTasks.data"
          [style.color]="
            checkDateAll(item.TaskDate.toDate(), item) ? 'red' : ''
          "
        >
          <ng-container
            *ngIf="
              doesItContain(item.Users, this.self);
              then currentUserTaskToday;
              else otherUsersTaskToday
            "
          >
          </ng-container>
          <ng-template #currentUserTaskToday>
            <td
              nzShowCheckbox="true"
              [(nzChecked)]="item.isCompleted"
              (nzCheckedChange)="refreshStatus(item)"
            ></td>
          </ng-template>
          <ng-template #otherUsersTaskToday>
            <td>
              <span *ngIf="item.isCompleted && self.Role.Worker">
                <mat-icon [ngStyle]="{ color: '#17b484' }"
                  >check_circle</mat-icon
                >
              </span>
              <span *ngIf="item.isCompleted && !self.Role.Worker">
                <td
                  nzShowCheckbox="true"
                  [(nzChecked)]="item.isCompleted"
                  (nzCheckedChange)="refreshStatus(item)"
                ></td>
              </span>
              <span *ngIf="!item.isCompleted && self.Role.Worker">
                <mat-icon [ngStyle]="{ color: 'red' }">cancel</mat-icon>
              </span>
              <span *ngIf="!item.isCompleted && !self.Role.Worker">
                <td
                  nzShowCheckbox="true"
                  [(nzChecked)]="item.isCompleted"
                  (nzCheckedChange)="refreshStatus(item)"
                ></td>
              </span>
            </td>
          </ng-template>
          <td>
            <span>{{ item.Name }}</span>
          </td>
          <td>
            <span>{{ item.TaskDate.toDate() | date: "mediumDate" }}</span>
          </td>
          <td>
            <nz-tag *ngFor="let user of item.Users">{{ user.Name }}</nz-tag>
          </td>
          <td>
            <span *ngIf="item.Recurrence === 'once'">{{
              "once" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'daily'">{{
              "daily" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'weekly'">{{
              "weekly" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'monthly'">{{
              "monthly" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'bi-weekly'">{{
              "biWeekly" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'bi-monthly'">{{
              "biMonthly" | translate
            }}</span>
          </td>
          <td style="width: 90px">
            <button
              *ngIf="self.Role.Owner || self.Role.Admin"
              nz-button
              nzType="default"
              nzShape="circle"
              (click)="editDialog(tplTitle, tplContent, item)"
            >
              <i nz-icon nzType="edit"></i></button
            >&nbsp;
            <button
              *ngIf="self.Role.Owner || self.Role.Admin"
              nz-button
              nzType="danger"
              nzShape="circle"
              (click)="deleteDialog(item)"
            >
              <i nz-icon nzType="delete"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>

  <nz-tab nzTitle="{{ 'all' | translate }}">
    <nz-table
      nzSize="middle"
      #allTask
      [nzData]="task$ | async"
      [nzLoading]="!(task$ | async)"
    >
      <thead>
        <tr>
          <th width="40"></th>
          <th>{{ "taskSideMenu" | translate }}</th>
          <th width="200">{{ "taskDate" | translate }}</th>
          <th>{{ "assigned" | translate }}</th>
          <th width="200">{{ "recurrence" | translate }}</th>
          <th *ngIf="self.Role.Owner || self.Role.Admin">
            {{ "action" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of allTask.data"
          [style.color]="
            checkDateAll(item.TaskDate.toDate(), item) ? 'red' : ''
          "
        >
          <ng-container
            *ngIf="
              doesItContain(item.Users, this.self);
              then currentUserTaskAll;
              else otherUsersTaskAll
            "
          >
          </ng-container>
          <ng-template #currentUserTaskAll>
            <td
              nzShowCheckbox="true"
              [(nzChecked)]="item.isCompleted"
              (nzCheckedChange)="refreshStatus(item)"
            ></td>
          </ng-template>
          <ng-template #otherUsersTaskAll>
            <td>
              <span *ngIf="item.isCompleted && self.Role.Worker">
                <mat-icon [ngStyle]="{ color: '#17b484' }"
                  >check_circle</mat-icon
                >
              </span>
              <span *ngIf="item.isCompleted && !self.Role.Worker">
                <td
                  nzShowCheckbox="true"
                  [(nzChecked)]="item.isCompleted"
                  (nzCheckedChange)="refreshStatus(item)"
                ></td>
              </span>
              <span *ngIf="!item.isCompleted && self.Role.Worker">
                <mat-icon [ngStyle]="{ color: 'red' }">cancel</mat-icon>
              </span>
              <span *ngIf="!item.isCompleted && !self.Role.Worker">
                <td
                  nzShowCheckbox="true"
                  [(nzChecked)]="item.isCompleted"
                  (nzCheckedChange)="refreshStatus(item)"
                ></td>
              </span>
            </td>
          </ng-template>
          <td>
            <span>{{ item.Name }}</span>
          </td>
          <td>
            <span>{{ item.TaskDate.toDate() | date: "mediumDate" }}</span>
          </td>
          <td>
            <nz-tag *ngFor="let user of item.Users">{{ user.Name }}</nz-tag>
          </td>
          <td>
            <span *ngIf="item.Recurrence === 'once'">{{
              "once" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'daily'">{{
              "daily" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'weekly'">{{
              "weekly" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'monthly'">{{
              "monthly" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'bi-weekly'">{{
              "biWeekly" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'bi-monthly'">{{
              "biMonthly" | translate
            }}</span>
          </td>
          <td style="width: 90px">
            <button
              *ngIf="self.Role.Owner || self.Role.Admin"
              nz-button
              nzType="default"
              nzShape="circle"
              (click)="editDialog(tplTitle, tplContent, item)"
            >
              <i nz-icon nzType="edit"></i></button
            >&nbsp;
            <button
              *ngIf="self.Role.Owner || self.Role.Admin"
              nz-button
              nzType="danger"
              nzShape="circle"
              (click)="deleteDialog(item)"
            >
              <i nz-icon nzType="delete"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>

  <nz-tab nzTitle="{{ 'completed' | translate }}">
    <nz-table
      nzSize="middle"
      #completedTasks
      [nzData]="taskCompleted$ | async"
      [nzLoading]="!(taskCompleted$ | async)"
    >
      <thead>
        <tr>
          <th width="40"></th>
          <th>{{ "taskSideMenu" | translate }}</th>
          <th width="200">{{ "taskDate" | translate }}</th>
          <th>{{ "assigned" | translate }}</th>
          <th width="200">{{ "recurrence" | translate }}</th>
          <th *ngIf="self.Role.Owner || self.Role.Admin">
            {{ "action" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of completedTasks.data">
          <ng-container
            *ngIf="
              doesItContain(item.Users, this.self);
              then currentUserTaskCompleted;
              else otherUsersTaskCompleted
            "
          >
          </ng-container>
          <ng-template #currentUserTaskCompleted>
            <td
              nzShowCheckbox="true"
              [(nzChecked)]="item.isCompleted"
              (nzCheckedChange)="refreshStatus(item)"
            ></td>
          </ng-template>
          <ng-template #otherUsersTaskCompleted>
            <td>
              <span *ngIf="item.isCompleted && self.Role.Worker">
                <mat-icon [ngStyle]="{ color: '#17b484' }"
                  >check_circle</mat-icon
                >
              </span>
              <span *ngIf="item.isCompleted && !self.Role.Worker">
                <td
                  nzShowCheckbox="true"
                  [(nzChecked)]="item.isCompleted"
                  (nzCheckedChange)="refreshStatus(item)"
                ></td>
              </span>
              <span *ngIf="!item.isCompleted && self.Role.Worker">
                <mat-icon [ngStyle]="{ color: 'red' }">cancel</mat-icon>
              </span>
              <span *ngIf="!item.isCompleted && !self.Role.Worker">
                <td
                  nzShowCheckbox="true"
                  [(nzChecked)]="item.isCompleted"
                  (nzCheckedChange)="refreshStatus(item)"
                ></td>
              </span>
            </td>
          </ng-template>
          <td>
            <span>{{ item.Name }}</span>
          </td>
          <td>
            <span>{{ item.TaskDate.toDate() | date: "mediumDate" }}</span>
          </td>
          <td>
            <nz-tag *ngFor="let user of item.Users">{{ user.Name }}</nz-tag>
          </td>
          <td>
            <span *ngIf="item.Recurrence === 'once'">{{
              "once" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'daily'">{{
              "daily" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'weekly'">{{
              "weekly" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'monthly'">{{
              "monthly" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'bi-weekly'">{{
              "biWeekly" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'bi-monthly'">{{
              "biMonthly" | translate
            }}</span>
          </td>
          <td style="width: 90px">
            <button
              *ngIf="self.Role.Owner || self.Role.Admin"
              nz-button
              nzType="default"
              nzShape="circle"
              (click)="editDialog(tplTitle, tplContent, item)"
            >
              <i nz-icon nzType="edit"></i></button
            >&nbsp;
            <button
              *ngIf="self.Role.Owner || self.Role.Admin"
              nz-button
              nzType="danger"
              nzShape="circle"
              (click)="deleteDialog(item)"
            >
              <i nz-icon nzType="delete"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>

  <nz-tab nzTitle="{{ 'ongoing' | translate }}">
    <nz-table
      nzSize="middle"
      #ongoingTasks
      [nzData]="taskOngoing$ | async"
      [nzLoading]="!(taskOngoing$ | async)"
    >
      <thead>
        <tr>
          <th width="40"></th>
          <th>{{ "taskSideMenu" | translate }}</th>
          <th width="200">{{ "taskDate" | translate }}</th>
          <th>{{ "assigned" | translate }}</th>
          <th width="200">{{ "recurrence" | translate }}</th>
          <th *ngIf="self.Role.Owner || self.Role.Admin">
            {{ "action" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of ongoingTasks.data"
          [style.color]="
            checkDateAll(item.TaskDate.toDate(), item) ? 'red' : ''
          "
        >
          <ng-container
            *ngIf="
              doesItContain(item.Users, this.self);
              then currentUserTaskOngoing;
              else otherUsersTaskOngoing
            "
          >
          </ng-container>
          <ng-template #currentUserTaskOngoing>
            <td
              nzShowCheckbox="true"
              [(nzChecked)]="item.isCompleted"
              (nzCheckedChange)="refreshStatus(item)"
            ></td>
          </ng-template>
          <ng-template #otherUsersTaskOngoing>
            <td>
              <span *ngIf="item.isCompleted && self.Role.Worker">
                <mat-icon [ngStyle]="{ color: '#17b484' }"
                  >check_circle</mat-icon
                >
              </span>
              <span *ngIf="item.isCompleted && !self.Role.Worker">
                <td
                  nzShowCheckbox="true"
                  [(nzChecked)]="item.isCompleted"
                  (nzCheckedChange)="refreshStatus(item)"
                ></td>
              </span>
              <span *ngIf="!item.isCompleted && self.Role.Worker">
                <mat-icon [ngStyle]="{ color: 'red' }">cancel</mat-icon>
              </span>
              <span *ngIf="!item.isCompleted && !self.Role.Worker">
                <td
                  nzShowCheckbox="true"
                  [(nzChecked)]="item.isCompleted"
                  (nzCheckedChange)="refreshStatus(item)"
                ></td>
              </span>
            </td>
          </ng-template>
          <td>
            <span>{{ item.Name }}</span>
          </td>
          <td>
            <span>{{ item.TaskDate.toDate() | date: "mediumDate" }}</span>
          </td>
          <td>
            <nz-tag *ngFor="let user of item.Users">{{ user.Name }}</nz-tag>
          </td>
          <td>
            <span *ngIf="item.Recurrence === 'once'">{{
              "once" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'daily'">{{
              "daily" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'weekly'">{{
              "weekly" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'monthly'">{{
              "monthly" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'bi-weekly'">{{
              "biWeekly" | translate
            }}</span>
            <span *ngIf="item.Recurrence === 'bi-monthly'">{{
              "biMonthly" | translate
            }}</span>
          </td>
          <td style="width: 90px">
            <button
              *ngIf="self.Role.Owner || self.Role.Admin"
              nz-button
              nzType="default"
              nzShape="circle"
              (click)="editDialog(tplTitle, tplContent, item)"
            >
              <i nz-icon nzType="edit"></i></button
            >&nbsp;
            <button
              *ngIf="self.Role.Owner || self.Role.Admin"
              nz-button
              nzType="danger"
              nzShape="circle"
              (click)="deleteDialog(item)"
            >
              <i nz-icon nzType="delete"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
</nz-tabset>

<ng-template #tplCompleteTitle>
  <span>Complete Task</span>
</ng-template>
<ng-template #tplCompleteContent>
  <p>Do you want to complete the checked task(s)?</p>
</ng-template>

<ng-template #tplTitle>
  <span>{{ "manageTask" | translate }}</span>
</ng-template>
<ng-template #tplContent>
  <form nz-form [formGroup]="dialogFormGroup" class="dialog-form">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "name" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="18"
        [nzXs]="24"
        nzErrorTip="Task name is required"
      >
        <input
          type="text"
          nz-input
          formControlName="nameCtrl"
          placeholder="{{ 'name' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24">{{
        "zoneName" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24">
        <input
          type="text"
          nz-input
          formControlName="zoneCtrl"
          placeholder="{{ 'zoneName' | translate }}"
          autocomplete="off"
          maxlength="255"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "assignedTo" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="User is required">
        <nz-select
          nzPlaceHolder="{{ 'assignedTo' | translate }}"
          formControlName="usersCtrl"
          nzMode="multiple"
          [nzMaxTagPlaceholder]="tagPlaceHolder"
        >
          <nz-option
            *ngFor="let user of users"
            [nzValue]="user.Id"
            [nzLabel]="user.Name"
          ></nz-option>
        </nz-select>
        <ng-template #tagPlaceHolder let-selectedList>
          and {{ selectedList.length }} more selected
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "recurrence" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="18"
        [nzXs]="24"
        nzErrorTip="Recurrence is required"
      >
        <nz-input-group nzCompact>
          <nz-select
            formControlName="recurrenceCtrl"
            nzAllowClear
            nzPlaceHolder="{{ 'recurrence' | translate }}"
            required
            style="width: 200px"
            #recurrence
          >
            <nz-option
              nzValue="once"
              nzLabel="{{ 'once' | translate }}"
            ></nz-option>
            <nz-option
              nzValue="daily"
              nzLabel="{{ 'daily' | translate }}"
            ></nz-option>
            <nz-option
              nzValue="weekly"
              nzLabel="{{ 'weekly' | translate }}"
            ></nz-option>
            <nz-option
              nzValue="bi-weekly"
              nzLabel="{{ 'biWeekly' | translate }}"
            ></nz-option>
            <nz-option
              nzValue="monthly"
              nzLabel="{{ 'monthly' | translate }}"
            ></nz-option>
            <nz-option
              nzValue="bi-monthly"
              nzLabel="{{ 'biMonthly' | translate }}"
            ></nz-option>
          </nz-select>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item
      *ngIf="dialogFormGroup.get('recurrenceCtrl').value !== 'once'"
    >
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "startDate" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="18"
        [nzXs]="24"
        nzErrorTip="Start date is required"
      >
        <nz-date-picker
          formControlName="startDateCtrl"
          required
        ></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item
      *ngIf="dialogFormGroup.get('recurrenceCtrl').value !== 'once'"
    >
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "endDate" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="18"
        [nzXs]="24"
        nzErrorTip="End date is required"
      >
        <nz-date-picker formControlName="endDateCtrl" required></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item
      *ngIf="dialogFormGroup.get('recurrenceCtrl').value === 'once'"
    >
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "taskDate" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="18"
        [nzXs]="24"
        nzErrorTip="Task date is required"
      >
        <nz-date-picker
          formControlName="taskDateCtrl"
          required
        ></nz-date-picker>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24">{{
        "notes" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24">
        <textarea
          nz-input
          formControlName="notesCtrl"
          placeholder="{{ 'notes' | translate }}"
          rows="10"
        ></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24"
        >{{ "basedOnCrop" | translate }}
      </nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Answer is required">
        <nz-radio-group
          formControlName="basedOnCropCtrl"
          [nzButtonStyle]="'solid'"
        >
          <label nz-radio-button nzValue="yes">{{ "yes" | translate }}</label>
          <label nz-radio-button nzValue="no">{{ "no" | translate }}</label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item
      *ngIf="dialogFormGroup.get('basedOnCropCtrl').value === 'yes'"
    >
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "crop" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Crop is required">
        <nz-select
          nzPlaceHolder="{{ 'crop' | translate }}"
          formControlName="cropCtrl"
        >
          <nz-option nzLabel="None"></nz-option>
          <nz-option
            *ngFor="let crop of crops$ | async"
            [nzValue]="crop.Id"
            [nzLabel]="crop.Name"
          >
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>
