<div fxLayout="row wrap" fxLayoutAlign="end stretch">
  <button
    nz-button
    *ngIf="userService.self.Role.Admin || userService.self.Role.Owner"
    nzType="primary"
    (click)="editDialog(tplTitle, tplContent, null)"
  >
    <i nz-icon nzType="plus" nzTheme="outline"></i> {{ "crop" | translate }}
  </button>
</div>

<br />

<nz-table
  nzSize="middle"
  [nzData]="cropList | async"
  [nzLoading]="!(cropList | async)"
>
  <thead>
    <tr>
      <th>{{ "name" | translate }}</th>
      <th>{{ "recipe" | translate }}</th>
      <th>{{ "cameras" | translate }}</th>
      <th *ngIf="userService.self.Role.Worker"></th>
      <th *ngIf="userService.self.Role.Admin || userService.self.Role.Owner">
        {{ "action" | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of cropList | async">
      <td>{{ item.Name }}</td>
      <td>
        <span *ngFor="let recipe of item.Recipes">{{ recipe.Name }}</span>
      </td>
      <td>
        <nz-tag *ngFor="let camera of item.Cameras">{{
          camera.Description
        }}</nz-tag>
      </td>
      <td style="width: 135px">
        <button
          *ngIf="userService.self.Role.Admin || userService.self.Role.Owner"
          nz-button
          nzType="default"
          nzShape="circle"
          (click)="editDialog(tplTitle, tplContent, item)"
        >
          <i nz-icon nzType="edit"></i></button
        >&nbsp;
        <button
          *ngIf="userService.self.Role.Admin || userService.self.Role.Owner"
          nz-button
          nzType="danger"
          nzShape="circle"
          (click)="deleteDialog(item)"
        >
          <i nz-icon nzType="delete"></i></button
        >&nbsp;
        <span *ngIf="userService.self.Dashboard.Indoor">
          <button
            nz-button
            nzType="default"
            nzShape="circle"
            [routerLink]="['../dashboard', item.Id]"
          >
            <i nz-icon nzType="right" nzTheme="outline"></i>
          </button>
        </span>
      </td>
    </tr>
  </tbody>
</nz-table>

<ng-template #tplTitle>
  <span>{{ "manageCrop" | translate }}</span>
</ng-template>

<ng-template #tplContent>
  <form nz-form [formGroup]="dialogFormGroup" class="dialog-form">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "name" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="Crop name is required"
      >
        <input
          type="text"
          nz-input
          formControlName="nameCtrl"
          placeholder="{{ 'name' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24">{{
        "recipe" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24">
        <nz-select
          nzPlaceHolder="{{ 'recipe' | translate }}"
          formControlName="recipesCtrl"
        >
          <nz-option
            *ngFor="let recipe of recipesList"
            [nzValue]="recipe.Id"
            [nzLabel]="recipe.Name"
          ></nz-option>
        </nz-select>
        <!-- <ng-template #tagPlaceHolder let-selectedList>
          and {{ selectedList.length }} more selected
        </ng-template> -->
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24">{{
        "cameras" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="18" [nzXs]="24">
        <nz-select
          nzPlaceHolder="{{ 'cameras' | translate }}"
          formControlName="camerasCtrl"
          nzMode="multiple"
          [nzMaxTagPlaceholder]="tagPlaceHolder"
        >
          <nz-option
            *ngFor="let camera of cameras"
            [nzValue]="camera.Id"
            [nzLabel]="camera.Description"
          ></nz-option>
        </nz-select>
        <ng-template #tagPlaceHolder let-selectedList>
          and {{ selectedList.length }} more selected
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <div formArrayName="cropStagesFormArray">
      <nz-form-item
        *ngFor="
          let item of dialogFormGroup.get('cropStagesFormArray')['controls'];
          let i = index
        "
        formGroupName="{{ i }}"
      >
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
          item.controls["stageCtrl"].value
        }}</nz-form-label>
        <nz-form-control
          [nzSm]="14"
          [nzXs]="24"
          nzErrorTip="{{ item.controls['stageCtrl'].value }} Days is required"
        >
          <input
            type="number"
            nz-input
            placeholder="Crop stage"
            name="days{{ i }}"
            formControlName="daysCtrl"
            autocomplete="off"
            maxlength="255"
            required
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "startDate" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="18"
        [nzXs]="24"
        nzErrorTip="Start date is required"
      >
        <nz-date-picker
          formControlName="startDateCtrl"
          required
        ></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>
