import { Component, OnInit, Input } from "@angular/core";
import { UserService } from "../services/user.service";
import { User } from "../../../../models/user";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent implements OnInit {
  public self: User;
  public companyUser: User;
  public admin = false;
  public worker = false;
  public owner = false;
  @Input() isCollapsed = false;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.self = this.userService.self;
    this.admin = this.userService.self.Role.Admin;
    this.worker = this.userService.self.Role.Worker;
    this.owner = this.userService.self.Role.Owner;
  }
}
