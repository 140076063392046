<div class="logo-container"><img src="./assets/banner.svg" class="logo" /></div>
<ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
  <li nz-menu-item>
    <a [routerLink]="['./crop']"
      ><i nz-icon nzTheme="fill"
        ><i class="material-icons" style="width: 14px; height: 14px">eco</i></i
      ><span class="nav-text">{{ "crop" | translate }}</span></a
    >
  </li>
  <li nz-menu-item *ngIf="self.Dashboard.Outdoor">
    <a [routerLink]="['./outdoor-dashboard']"
      ><i nz-icon nzTheme="fill" nzType="dashboard"></i
      ><span class="nav-text">{{
        "outdoorDashboardSideMenu" | translate
      }}</span></a
    >
  </li>
  <li nz-menu-item>
    <a [routerLink]="['./device']"
      ><i nz-icon nzTheme="fill" nzType="control"></i
      ><span class="nav-text">{{ "deviceSideMenu" | translate }}</span></a
    >
  </li>
  <li nz-menu-item>
    <a [routerLink]="['./camera']"
      ><i nz-icon nzType="camera" nzTheme="outline"></i
      ><span class="nav-text">{{ "cameraSideMenu" | translate }}</span></a
    >
  </li>
  <li nz-menu-item>
    <a [routerLink]="['./task']"
      ><i nz-icon nzTheme="fill" nzType="profile"></i
      ><span class="nav-text">{{ "taskSideMenu" | translate }}</span></a
    >
  </li>
  <li nz-menu-item>
    <a [routerLink]="['./recipe']"
      ><i nz-icon nzTheme="outline" nzType="file-sync"></i
      ><span class="nav-text">{{ "recipeSideMenu" | translate }}</span></a
    >
  </li>
  <li nz-submenu>
    <span title
      ><i nz-icon nzType="bar-chart"></i
      ><span class="nav-text">{{
        "profit/lossSideMenu" | translate
      }}</span></span
    >
    <ul>
      <li nz-menu-item [routerLink]="['./profit-loss']">
        {{ "breakdownSideMenu" | translate }}
      </li>
      <li nz-menu-item [routerLink]="['./profit-loss-summary']">
        {{ "summarySideMenu" | translate }}
      </li>
    </ul>
  </li>
  <li nz-menu-item *ngIf="admin || owner">
    <a [routerLink]="['./user-company']">
      <span title
        ><i nz-icon nzType="user"></i
        ><span class="nav-text">{{ "usersSideMenu" | translate }}</span></span
      >
    </a>
  </li>
  <li nz-submenu *ngIf="admin">
    <span title
      ><i nz-icon nzType="setting"></i
      ><span class="nav-text">{{
        "adminstratorSideMenu" | translate
      }}</span></span
    >
    <ul>
      <li nz-menu-item [routerLink]="['./company']">
        {{ "companySideMenu" | translate }}
      </li>
      <li nz-menu-item [routerLink]="['./user']">
        {{ "usersSideMenu" | translate }}
      </li>
      <li nz-menu-item [routerLink]="['./devicetype']">
        {{ "devicetypeSideMenu" | translate }}
      </li>
    </ul>
  </li>
</ul>
