import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class NotificationService {
  private notification = new BehaviorSubject<string>(null);
  readonly notification$ = this.notification.asObservable();

  notify(message: string): void {
    this.notification.next(message);
  }
}
