import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Sensor } from "../../../../models/sensor";
import { Observable } from "rxjs";
import { AutoDosingHistory } from "../../../../models/auto-dosing-history";
import { Photo } from "../../../../models/photo";
import { AngularFirestore } from "@angular/fire/firestore";
import { Camera } from "../../../../models/camera";

import firebase from "firebase/app";

const baseUrl =
  "https://us-central1-plantos-development-225209.cloudfunctions.net";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private http: HttpClient, private db: AngularFirestore) {}

  getCurrentUserToken(): Promise<string> {
    return firebase.auth().currentUser.getIdToken(true);
  }

  getImages(cameraId: string): Observable<Photo[]> {
    const list$ = this.db
      .collection<Camera>("cameras")
      .doc(cameraId)
      .collection<Photo>("photos", (ref) => {
        const c1 = ref;
        const c2 = c1.orderBy("UnixSeconds", "desc");
        return c2;
      })
      .valueChanges({ idField: "Id" });
    return list$;
  }

  async getTelemetry(
    deviceId: string,
    startTimeStamp: string,
    endTimeStamp: string
  ): Promise<Sensor[]> {
    const data = {
      DeviceId: deviceId,
      Start: startTimeStamp,
      End: endTimeStamp,
      uid: firebase.auth().currentUser.uid,
    };
    const authToken = await this.getCurrentUserToken();
    const headers = new HttpHeaders({ Authorization: "Bearer " + authToken });

    return this.http
      .post<Sensor[]>(
        `${baseUrl}/getTelemetryRangeV2`,
        { data: data },
        { headers: headers }
      )
      .toPromise();
  }

  async getAutoDosingHistoryByRange(
    deviceId: string,
    start: string,
    end: string
  ): Promise<AutoDosingHistory[]> {
    const data = {
      DeviceId: deviceId,
      uid: firebase.auth().currentUser.uid,
      Start: start,
      End: end,
    };
    const authToken = await this.getCurrentUserToken();
    const headers = new HttpHeaders({ Authorization: "Bearer " + authToken });
    return this.http
      .post<AutoDosingHistory[]>(
        `${baseUrl}/getAutodosingHistoryByRange`,
        { data: data },
        { headers: headers }
      )
      .toPromise();
  }
}
