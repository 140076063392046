<div fxLayout="column" fxLayoutAlign="center center" class="container">
  <nz-card class="login-card">
    <div>
      <div fxLayout="column" fxLayoutAlign="center center">
        <div id="login-div">
          <img
            class="login-icon bottom"
            [class.transparent]="enter"
            src="./assets/secondary-logo.svg"
          />
          <img
            class="login-icon top"
            [class.transparent]="!enter"
            [src]="favicon"
          />
        </div>
        <br />
        <div>
          <form nz-form [formGroup]="loginFormGroup" class="login-form">
            <nz-form-item>
              <nz-form-control nzErrorTip="Email is required">
                <nz-input-group [nzPrefix]="prefixUser">
                  <input
                    id="login-email"
                    type="text"
                    nz-input
                    formControlName="emailCtrl"
                    placeholder="Email"
                    autocomplete="off"
                    maxlength="255"
                    #email
                    required
                    email
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Password is required">
                <nz-input-group [nzPrefix]="prefixLock">
                  <input
                    id="login-password"
                    type="password"
                    nz-input
                    formControlName="passwordCtrl"
                    placeholder="Password"
                    autocomplete="off"
                    maxlength="100"
                    #password
                    required
                    (keyup.enter)="login(email.value, password.value)"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </form>
          <ng-template #prefixUser><i nz-icon nzType="user"></i></ng-template>
          <ng-template #prefixLock><i nz-icon nzType="lock"></i></ng-template>
          <div>{{ notificationText }}</div>
          <br />
          <div class="progress" align="right">
            <button
              nz-button
              id="login-btn"
              nzType="primary"
              [disabled]="loginFormGroup.invalid"
              (click)="login(email.value, password.value)"
            >
              Login
            </button>
          </div>
          <div align="right">
            <a [routerLink]="['../reset-password']">Forgot password?</a>
          </div>
        </div>
      </div>
    </div>
  </nz-card>
</div>
