import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-select-language",
  template: `
    <nz-radio-group
      [nzButtonStyle]="'solid'"
      [(ngModel)]="langValue"
      #langSelect
      (ngModelChange)="onChangeLang(langSelect.value)"
    >
      <label nz-radio-button nzValue="en">English</label>
      <label nz-radio-button nzValue="ar">عربی</label>
    </nz-radio-group>
  `,
})
export class SelectLanguageComponent {
  public langValue: string;

  constructor(public translate: TranslateService) {
    this.langValue = translate.currentLang;
  }

  onChangeLang(language: string): void {
    this.translate.use(language);
  }
}
