import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgZorroAntdModule, NZ_I18N, en_US } from "ng-zorro-antd";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatIconModule } from "@angular/material/icon";
import { NgxGalleryModule } from "@kolkov/ngx-gallery";
import { NgxGoogleAnalyticsModule } from "ngx-google-analytics";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { AngularFireStorageModule } from "@angular/fire/storage";
import "hammerjs";
import "mousetrap";

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireFunctionsModule } from "@angular/fire/functions";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFirePerformanceModule } from "@angular/fire/performance";

import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { AuthenticateGuardService } from "./services/authenticate-guard.service";
import { AuthenticateService } from "./services/authenticate.service";
import { NotificationService } from "./services/notification.service";
import { ToolbarService } from "./services/toolbar.service";
import { LayoutComponent } from "./layout/layout.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { FirstTimeLoginComponent } from "./first-time-login/first-time-login.component";
import { LoginComponent } from "./login/login.component";
import { CompanyComponent } from "./pages/company/company.component";
import { CropComponent } from "./pages/crop/crop.component";
import { RecipeComponent } from "./pages/recipe/recipe.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { TaskComponent } from "./pages/task/task.component";
import { UserComponent } from "./pages/user/user.component";
import { DeviceComponent } from "./pages/device/device.component";
import { DeviceTypeComponent } from "./pages/device-type/device-type.component";
import { ProfitLossComponent } from "./pages/profit-loss/profit-loss.component";
import { ProfitLossSummaryComponent } from "./pages/profit-loss-summary/profit-loss-summary.component";
import { VerificationComponent } from "./pages/verification/verification.component";
import { SideMenuComponent } from "./side-menu/side-menu.component";
import { NgxPaginationModule } from "ngx-pagination";

import { PlotlyViaCDNModule } from "angular-plotly.js";
import { DetailsComponent } from "./pages/details/details.component";
import { UserCompanyComponent } from "./pages/user-company/user-company.component";
import { CropDetailsComponent } from "./pages/crop-details/crop-details.component";
import { OutdoorDashboardComponent } from "./pages/outdoor-dashboard/outdoor-dashboard.component";
import { GalleryModule } from "@ks89/angular-modal-gallery";
import { CameraComponent } from "./pages/camera/camera.component";
import { I18nModule } from "./i18n/i18n.module";
import { SelectLanguageComponent } from "./pages/select-language/select-language.component";
import { GlobalErrorHandler } from "./global-error-handler";

// TODO(simon): Remove this and verify if we use it anywhere.
PlotlyViaCDNModule.plotlyVersion = "1.51.2";

const routes: Routes = [
  {
    path: "p",
    component: LayoutComponent,
    canActivate: [AuthenticateGuardService],
    runGuardsAndResolvers: "always",
    data: { breadcrumb: "Dashboard" },
    children: [
      {
        path: "dashboard/:cropId",
        component: DashboardComponent,
        canActivate: [AuthenticateGuardService],
        data: { breadcrumb: "IoT Monitor" },
      },
      {
        path: "camera",
        component: CameraComponent,
        canActivate: [AuthenticateGuardService],
        data: { breadcrumb: "Manage Cameras" },
      },
      {
        path: "outdoor-dashboard",
        component: OutdoorDashboardComponent,
        canActivate: [AuthenticateGuardService],
        data: { breadcrumb: "Outdoor Dashboard" },
      },
      {
        path: "details/:deviceId/:type",
        component: DetailsComponent,
        canActivate: [AuthenticateGuardService],
        data: { breadcrumb: "Telemetry Details" },
      },
      {
        path: "dashboard/details/:deviceId",
        component: DetailsComponent,
        canActivate: [AuthenticateGuardService],
        data: { breadcrumb: "Telemetry Details" },
      },
      {
        path: "fertigation-dashboard/details/:deviceId",
        component: DetailsComponent,
        canActivate: [AuthenticateGuardService],
        data: { breadcrumb: "Telemetry Details" },
      },
      {
        path: "crop",
        component: CropComponent,
        canActivate: [AuthenticateGuardService],
        data: { breadcrumb: "Crops" },
      },
      {
        path: "crop-details/:cropId",
        component: CropDetailsComponent,
        canActivate: [AuthenticateGuardService],
        data: { breadcrumb: "Crop Details" },
      },
      {
        path: "user",
        component: UserComponent,
        canActivate: [AuthenticateGuardService],
        data: { breadcrumb: "User" },
      },
      {
        path: "company",
        component: CompanyComponent,
        canActivate: [AuthenticateGuardService],
        data: { breadcrumb: "Company" },
      },
      {
        path: "device",
        component: DeviceComponent,
        canActivate: [AuthenticateGuardService],
        data: { breadcrumb: "Device" },
      },
      {
        path: "devicetype",
        component: DeviceTypeComponent,
        canActivate: [AuthenticateGuardService],
        data: { breadcrumb: "Device Type" },
      },
      {
        path: "task",
        component: TaskComponent,
        canActivate: [AuthenticateGuardService],
        data: { breadcrumb: "Task" },
      },
      {
        path: "recipe",
        component: RecipeComponent,
        canActivate: [AuthenticateGuardService],
        data: { breadcrumb: "Recipe" },
      },
      {
        path: "profit-loss",
        component: ProfitLossComponent,
        canActivate: [AuthenticateGuardService],
        data: { breadcrumb: "Profit Loss" },
      },
      {
        path: "profit-loss-summary",
        component: ProfitLossSummaryComponent,
        canActivate: [AuthenticateGuardService],
        data: { breadcrumb: "Profit Loss Summary" },
      },
      {
        path: "user-company",
        component: UserCompanyComponent,
        canActivate: [AuthenticateGuardService],
        data: { breadcrumb: "Company User" },
      },
      { path: "**", redirectTo: "task" },
    ],
  },
  { path: "login", component: LoginComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "verification", component: VerificationComponent },
  { path: "", redirectTo: "p", pathMatch: "full" },
];

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    DashboardComponent,
    FirstTimeLoginComponent,
    LoginComponent,
    CompanyComponent,
    CropComponent,
    RecipeComponent,
    ResetPasswordComponent,
    TaskComponent,
    UserComponent,
    DeviceComponent,
    DeviceTypeComponent,
    ProfitLossComponent,
    ProfitLossSummaryComponent,
    VerificationComponent,
    SideMenuComponent,
    DetailsComponent,
    UserCompanyComponent,
    CropDetailsComponent,
    OutdoorDashboardComponent,
    CameraComponent,
    SelectLanguageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    AngularFirePerformanceModule,
    FlexLayoutModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    MatIconModule,
    NgxGalleryModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    NgxPaginationModule,
    GalleryModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot("UA-173965998-2"),
    RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" }),
    FormsModule,
    PlotlyViaCDNModule,
    AngularFireStorageModule,
    I18nModule,
  ],
  exports: [RouterModule],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: NZ_I18N, useValue: en_US },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthenticateGuardService,
    AuthenticateService,
    NotificationService,
    ToolbarService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
