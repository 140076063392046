import { Component, OnInit, TemplateRef } from "@angular/core";
import { ToolbarService } from "src/app/services/toolbar.service";
import { Crop } from "../../../../../models/crop";
import { CropService } from "src/app/services/crop.service";
import { ActivatedRoute } from "@angular/router";
import { Ticket, Action } from "../../../../../models/ticket";
import { Observable } from "rxjs";
import { NgxGalleryOptions, NgxGalleryImage } from "@kolkov/ngx-gallery";
import { NzModalRef, NzModalService, NzMessageService } from "ng-zorro-antd";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { User } from "../../../../../models/user";
import { UserService } from "src/app/services/user.service";
import { Task } from "../../../../../models/task";
import { FruitDetection } from "../../../../../models/fruit_detection";
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from "@angular/fire/storage";
import { Device } from "../../../../../models/device";
import { DeviceService } from "src/app/services/device.service";
import { Photo } from "../../../../../models/photo";
import { AngularFirestoreCollection } from "@angular/fire/firestore";
import { TranslateService } from "@ngx-translate/core";
import { Camera } from "../../../../../models/camera";
import { Timestamp } from "../../../../../models/timestamp";

import moment from "moment";
import firebase from "firebase/app";
import { GrowthPrediction } from "../../../../../models/growth_prediction";

interface ChartReading {
  name: Date;
  value: number;
}

interface ChartSeries {
  name: string;
  series: ChartReading[];
}

@Component({
  selector: "app-crop-details",
  templateUrl: "./crop-details.component.html",
  styleUrls: ["./crop-details.component.scss"],
})
export class CropDetailsComponent implements OnInit {
  constructor(
    private toolbar: ToolbarService,
    private cropService: CropService,
    private activatedRoute: ActivatedRoute,
    private modalService: NzModalService,
    private message: NzMessageService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private deviceService: DeviceService,
    private fireStorage: AngularFireStorage,
    private translate: TranslateService
  ) {}

  public growthChart: Array<ChartSeries>;

  public cropList: Crop[] = [];

  // FIXME
  public currentCrop: Crop = {
    Id: null,
    Name: "",
    Cameras: [],
    Recipes: [],
    Company: null,
    Selected: null,
    Schedules: [],
  } as Crop;
  public fruitDetectionResults: FruitDetection[];
  public deviceIdSelected: string;
  public paramCropId: string;
  public ticketsList: Observable<Ticket[]>;
  public tplModal: NzModalRef;
  public cropDevice: Device;
  public dialogFormGroup: FormGroup;
  public tplModalButtonLoading = false;
  public self: User;
  public task: AngularFireUploadTask;
  public galleryOptions: NgxGalleryOptions[];
  public galleryImages: Array<{
    id: string;
    images: NgxGalleryImage[];
    title: string;
    latestTimestamp: string;
  }> = [];
  public photos: Observable<Photo[]>;
  public collection: AngularFirestoreCollection<Camera>;
  view: number[] = [550, 400];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showLegendLabel = false;
  showXAxisLabel = true;
  history = "";
  predictedTemperature = " ";
  actualRateOfGrowth = "";
  predictedRateOfGrowth = " ";
  growthXAxisLabel = "Date";
  growthYAxisLabel = "Growth (KG)";
  tempXAxisLabel = "Time (Hour)";
  tempYAxisLabel = "Temp (Centigrade)";
  showYAxisLabel = true;
  timeline = true;

  growthColorScheme = {
    domain: ["#5AA454", "#A10A28"],
  };
  tempColorScheme = {
    domain: ["#5AA454", "#2ca8c7"],
  };
  public tempMulti = [];
  public downloadableURL: Observable<string>;
  selectedFile: File = null;
  fb: string;

  ngOnInit(): void {
    this.translate.stream("cropDetails").subscribe((translation) => {
      this.toolbar.changeHeader(translation);
    });
    this.translate.stream("date").subscribe((translation) => {
      this.growthXAxisLabel = translation;
    });
    this.translate.stream("growthKg").subscribe((translation) => {
      this.growthYAxisLabel = translation;
    });
    this.translate.stream("timeHour").subscribe((translation) => {
      this.tempXAxisLabel = translation;
    });
    this.translate.stream("temperatureCentigrade").subscribe((translation) => {
      this.tempYAxisLabel = translation;
    });
    this.translate.stream("legend").subscribe((translation) => {
      this.tempYAxisLabel = translation;
    });
    this.translate.stream("history").subscribe((translation) => {
      const history = {
        name: translation,
        series: [
          {
            name: "11",
            value: 3,
          },
          {
            name: "12",
            value: 4.5,
          },
          {
            name: "13",
            value: 7,
          },
        ],
      };
      this.tempMulti[0] = history;
      this.tempYAxisLabel = translation;
    });

    this.translate.stream("predictedTemperature").subscribe((translation) => {
      const predictedTemp = {
        name: translation,
        series: [
          {
            name: "13",
            value: 7,
          },
          {
            name: "14",
            value: 8.2,
          },
          {
            name: "15",
            value: 9,
          },
          {
            name: "16",
            value: 8.5,
          },
          {
            name: "17",
            value: 7.9,
          },
        ],
      };
      this.tempMulti[1] = predictedTemp;
    });

    this.activatedRoute.params.subscribe((params) => {
      this.paramCropId = params.cropId;

      console.log(`crop ${params.cropId}`);

      this.cropService
        .getFruitDetectionResults(params.cropId)
        .then((data) => {
          this.fruitDetectionResults = data.map((fd) => {
            const boxes = fd.boxes;

            // turn the box points into actual boxes:
            const actualBoxes: {
              top: number;
              left: number;
              right: number;
              bottom: number;
              score: number;
            }[] = [];

            // Firestore doesn't support 2d arrays, so we store the flattened
            // array in firestore and un-flatten it here into a n*4 2d array.
            for (let i = 0; i < boxes.length / 4; i++) {
              actualBoxes.push({
                top: boxes[4 * i + 0],
                left: boxes[4 * i + 1],
                bottom: boxes[4 * i + 2],
                right: boxes[4 * i + 3],
                score: fd.scores[i],
              });
            }

            return {
              ...fd,
              actualBoxes: actualBoxes,
            };
          });
        })
        .catch((err) => console.warn(err));

      this.cropService.get(params.cropId).subscribe((data) => {
        this.currentCrop = data.data() as Crop;
        console.log(`currentCrop is ${JSON.stringify(this.currentCrop)}`);

        const growthChart: Array<ChartSeries> = [];

        this.cropService
          .listCameras(params.cropId)
          .subscribe(async (cameras) => {
            console.log(`cameras is ${JSON.stringify(cameras)}`);

            const galleryImages: Array<{
              id: string;
              images: NgxGalleryImage[];
              title: string;
              latestTimestamp: string;
            }> = [];

            for (const camera of cameras) {
              const series = this.mapPredictionsToGraph(
                camera.Description,
                await this.cropService.getGrowthPredictions(
                  params["cropId"],
                  camera.Id
                )
              );

              growthChart.push(series[0]);
              growthChart.push(series[1]);

              this.cropService.getImages(camera.Id).subscribe((photos) => {
                const images = photos.map((photo) => {
                  if (photo.ThumbUrl) {
                    return {
                      small: photo.ThumbUrl,
                      medium: photo.Url,
                      big: photo.Url,
                    };
                  } else {
                    return {
                      small: photo.Url,
                      medium: photo.Url,
                      big: photo.Url,
                    };
                  }
                });

                if (photos.length > 0) {
                  galleryImages.push({
                    id: camera.Id,
                    images: images,
                    title: camera.Description,
                    latestTimestamp: moment
                      .unix(photos[0].UnixSeconds)
                      .format("dddd, MMMM Do, YYYY h:mm:ss A"),
                  });
                }
              });
            }

            this.galleryImages = galleryImages;

            if (growthChart.length > 0) {
              this.growthChart = growthChart;
            }
          });

        this.ticketsList = this.cropService.getticketsList(this.currentCrop);

        this.toolbar.changeHeader("Crop Details");
      });
    });

    this.galleryOptions = [
      {
        width: "800px",
        height: "600px",
        thumbnailsColumns: 5,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: "100%",
        height: "600px",
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false,
      },
    ];

    this.userService.self$.subscribe((self) => (this.self = self));
  }

  mapPredictionsToGraph(
    camera: string,
    results: firebase.firestore.QuerySnapshot<GrowthPrediction>
  ): Array<ChartSeries> {
    console.log(`series for camera is`);
    console.log(results.docs.map((x) => ({ id: x.id, data: x.data() })));

    // For each GrowthPrediction we return a name,value pair to plot.
    const current_series = results.docs.map((element) => {
      return {
        name: element.data().timestamp.toDate(),
        value: element.data().currentValue,
      };
    });

    const predicted_series = results.docs.map((element) => {
      return {
        name: element.data().timestamp.toDate(),
        value: element.data().predictedValue,
      };
    });

    return [
      {
        name: `Current Growth ${camera}`,
        series: current_series,
      },
      {
        name: `Predicted Growth ${camera}`,
        series: predicted_series,
      },
    ];
  }

  editDialog(
    tplTitle: TemplateRef<never>,
    tplContent: TemplateRef<never>,
    data: Ticket
  ): void {
    if (!data) {
      data = {} as Ticket;
      data.User = {} as User;
      data.Action = {} as Action;
    }
    const item = { ...data };
    item.Action.Comment = true;
    item.Action.CompleteTask = false;
    this.dialogFormGroup = this.formBuilder.group({
      titleCtrl: [item.Title, Validators.required],
      descriptionCtrl: [item.Description],
    });

    this.tplModal = this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: [
        {
          label: "Cancel",
          type: "danger",
          onClick: () => {
            this.tplModal.destroy();
          },
        },
        {
          label: "Save",
          type: "primary",
          disabled: () => this.dialogFormGroup.invalid,
          onClick: async () => {
            const form = this.dialogFormGroup.value;
            item.Title = form.titleCtrl;
            item.Description = form.descriptionCtrl;
            item.User = this.self;
            item.Action.Comment = true;
            item.Action.CompleteTask = false;
            item.CropId = this.currentCrop.Id;
            item.Timestamp = firebase.firestore.Timestamp.now();

            if (!item.Id) {
              this.cropService.addTicket(item);
            } else {
              this.cropService.editTicket(item);
            }
            this.displayResult(true, true);
            this.tplModal.destroy();
          },
        },
      ],
      nzMaskClosable: false,
      nzClosable: true,
    });
  }

  deleteDialog(item: Ticket): void {
    if (!item) {
      this.message.create("success", "Error delete item");
    } else {
      this.tplModal = this.modalService.create({
        nzTitle: "Are you sure delete this?",
        nzContent: `${item.Title}`,
        nzOkText: "Yes",
        nzOkType: "danger",
        nzOnOk: () => {
          this.cropService.deleteTicket(item.Id);
          this.displayResult(true, false);
        },
        nzCancelText: "No",
      });
    }
  }

  getAssignedUsers(task: Task): string {
    let usersString = "";
    let counter = 0;
    task.Users.forEach((user) => {
      if (user === this.self) {
        if (counter === 0) {
          usersString = usersString + `You ` + " ";
        } else {
          usersString = usersString + "-" + ` You `;
        }
      } else {
        if (counter === 0) {
          usersString = usersString + ` ${user.Name} ` + " ";
        } else {
          usersString = usersString + "-" + ` ${user.Name} `;
        }
      }
      counter = counter + 1;
    });
    return "( " + usersString + " )";
  }

  displayResult(response: boolean, isSave: boolean): void {
    if (response) {
      const msg = isSave
        ? "Item successfully saved"
        : "Item successfully deleted";
      this.message.create("success", msg);
    } else {
      const msg = isSave ? "Error saving item" : "Error deleting item";
      this.message.create("warning", msg);
    }
  }

  async onFileSelected(event: Event): Promise<void> {
    try {
      const target = event.target as HTMLInputElement;
      const files = target.files;
      const file = files[0];
      if (file) {
        const filePath = `crops/${this.currentCrop.Id}/${file.name}`;
        this.task = this.fireStorage.upload(filePath, file);
        (await this.task).ref.getDownloadURL().then(async (url) => {
          this.downloadableURL = url;
          const ticket = {
            Title: null,
            Description: null,
            Timestamp: firebase.firestore.Timestamp.now() as Timestamp,
            Task: null,
            User: this.self,
            Action: {
              Comment: false,
              CompleteTask: false,
              AssignedTask: false,
              Attached: true,
            } as Action,
            CropId: this.currentCrop.Id,
            Id: null,
            AttachmentUrl: url,
          } as Ticket;
          await this.cropService.addTicket(ticket);
        });
        this.message.success(`${file.name} file uploaded successfully`);
        console.log(this.downloadableURL);
      } else {
        this.message.error(`${file.name} file upload failed.`);
        alert("No images selected");
      }
    } catch (error) {
      console.log(error);
    }
  }
}
