import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Device } from "../../../../../models/device";
import { Attachment } from "src/app/models/attachment";
import { Sensor } from "../../../../../models/sensor";
import { ToolbarService } from "src/app/services/toolbar.service";
import { DashboardService } from "src/app/services/dashboard.service";
import { DeviceService } from "src/app/services/device.service";
import { ActivatedRoute } from "@angular/router";
import { AngularFirestoreCollection } from "@angular/fire/firestore";
import { Photo } from "../../../../../models/photo";
import { Camera } from "../../../../../models/camera";
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";

interface EnvironmentDashboard {
  Device: Device;
  EC: PlotlyTimeSeriesData;
  pH: PlotlyTimeSeriesData;
  Humid: PlotlyTimeSeriesData;
  AirTemp: PlotlyTimeSeriesData;
  WaterTemp: PlotlyTimeSeriesData;
  TDS: PlotlyTimeSeriesData;
  Light: PlotlyTimeSeriesData;
}

interface PlotlyLayout {
  xaxis?: {
    title: string;
    range?: Array<number | string>;
  };
  yaxis: { range?: number[]; title: string };
  title: { text: string; font: { size: number } };
  legend: { orientation: string };
  margin: { l: number; r: number; t: number; b: number };
}

interface PlotlyTimeSeriesData {
  Data: [
    {
      x: Date[];
      y: number[];
      type: string;
      name: string;
    }
  ];
  Layout: PlotlyLayout;
}

interface PlotlyStringSeriesData {
  Data: [
    {
      x: string[];
      y: number[];
      type: string;
      name?: string;
    }
  ];
  Layout: PlotlyLayout;
}

interface LatestData {
  EC: number;
  pH: number;
  light: number;
  humid: number;
  airTemp: number;
  waterTemp: number;
  Time: string;
}

@Component({
  selector: "app-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.scss"],
})
export class DetailsComponent implements OnInit {
  public loadGraph = true;
  public startDate: FormControl;
  public endDate: FormControl;
  public deviceList: Device[] = [];
  public imageList: Device[] = [];
  public autoDosingHistoryGraphData: PlotlyStringSeriesData;
  public deviceIdSelected: string;
  public imagingList: Attachment[] = [];
  public offset = moment().utcOffset();
  public sensors: Sensor[] = [];
  public envData: EnvironmentDashboard;
  public latestData: LatestData;
  public paramDeviceId: string;
  public title = "";
  public currentDeviceCameraId = "";
  public electricCunductivity: string;
  public phLevel: string;
  public airTemperature: string;
  public waterTemperature: string;
  public humidity: string;
  public photos: Observable<Photo[]>;
  public collection: AngularFirestoreCollection<Camera>;
  constructor(
    private toolbar: ToolbarService,
    private dashboardService: DashboardService,
    private deviceService: DeviceService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.envData = {} as EnvironmentDashboard;
    this.envData.Device = {} as Device;
    this.envData.EC = {} as PlotlyTimeSeriesData;
    this.envData.pH = {} as PlotlyTimeSeriesData;
    this.envData.Humid = {} as PlotlyTimeSeriesData;
    this.envData.AirTemp = {} as PlotlyTimeSeriesData;
    this.envData.WaterTemp = {} as PlotlyTimeSeriesData;
    this.envData.TDS = {} as PlotlyTimeSeriesData;
    this.envData.Light = {} as PlotlyTimeSeriesData;
    this.latestData = {} as LatestData;
    this.startDate = new FormControl(new Date());
    this.endDate = new FormControl(new Date());
    const today = moment();
    const twoWeeks = moment().subtract(14, "days");
    this.translate.stream("electricConductivity").subscribe((translation) => {
      this.electricCunductivity = translation;
    });
    this.translate.stream("phLevel").subscribe((translation) => {
      this.phLevel = translation;
    });
    this.translate.stream("airTemperature").subscribe((translation) => {
      this.airTemperature = translation;
    });
    this.translate.stream("waterTemperature").subscribe((translation) => {
      this.waterTemperature = translation;
    });
    this.translate.stream("humidity").subscribe((translation) => {
      this.humidity = translation;
    });
    this.activatedRoute.params.subscribe((params) => {
      this.paramDeviceId = params.deviceId;
      this.translate.stream("overal").subscribe((translation) => {
        this.title = translation;
      });

      this.startDate = new FormControl(twoWeeks.toDate());
      this.endDate = new FormControl(today.toDate());
      this.deviceService.get(params.deviceId).subscribe((data) => {
        const device = data.data();
        const start = moment(this.startDate.value).format("X");
        const end = moment(this.endDate.value).format("X");

        this.dashboardService
          .getTelemetry(device.DeviceId, start, end)
          .then((data) => {
            this.generateGraph(device, data);
          });
        this.dashboardService
          .getAutoDosingHistoryByRange(device.DeviceId, start, end)
          .then((data) => {
            console.log(data);

            function formatDate(d: Date) {
              const yyyy = d.getFullYear();
              const mm = String(d.getMonth() + 1).padStart(2, "0"); // month is zero-based
              const dd = String(d.getDate()).padStart(2, "0");

              return `${yyyy}-${mm}-${dd}`;
            }

            // Aggregate by date here (the key is yyyy-mm-dd).
            const aggregated = new Map<string, number>();

            // Initialise all days within our range to zero.
            for (let d = Number(start); d <= Number(end); d += 60 * 60 * 24) {
              aggregated.set(formatDate(new Date(d * 1000)), 0);
            }

            // Aggregate dosing events by day.
            for (const point of data) {
              const date = moment
                .utc(point.Time.value)
                .utcOffset(this.offset)
                .toDate();
              const day = formatDate(date);
              let bucket = aggregated.get(day) || 0;
              bucket += point.Dosage;
              aggregated.set(day, bucket);
            }

            const keys = Array.from<string>(aggregated.keys()).sort();
            this.autoDosingHistoryGraphData = {
              Data: [
                {
                  x: keys,
                  y: keys.map((x) => aggregated.get(x)),
                  type: "bar",
                },
              ],
              Layout: {
                ...this.generateLayout("Dosing History"),
                yaxis: {
                  title: "Nutrients dosed (ml)",
                },
                xaxis: {
                  title: "Date",
                  range: [keys[0], keys[keys.length - 1]],
                },
              },
            };
          });
      });
    });
    this.toolbar.changeHeader("Telemetry Details");
  }

  generateLayout(
    type: string
  ): {
    title: {
      text: string;
      font: {
        size: number;
      };
    };
    legend: {
      orientation: string;
    };
    margin: {
      l: number;
      r: number;
      t: number;
      b: number;
    };
  } {
    return {
      title: {
        text: `${type}`,
        font: { size: 14 },
      },
      legend: { orientation: "h" },
      margin: { l: 40, r: 40, t: 50, b: 40 },
    };
  }

  generateGraph(device: Device, data: Sensor[]): void {
    const xtime = data.map((x) =>
      moment.utc(x.Time.value).utcOffset(this.offset).toDate()
    );

    // Get the latest data.
    const last = data.slice(-1)[0];
    this.latestData = {
      EC: last.EC,
      pH: last.pH,
      light: last.LightIntensity,
      humid: last.Humidity,
      airTemp: last.Temperature,
      waterTemp: last.RTD,
      Time: moment
        .utc(last.Time.value)
        .utcOffset(this.offset)
        .format("DD MMM YYYY h:mm a"),
    };

    const yhumidity = {
      x: xtime,
      y: data.map((x) => x.Humidity),
      type: "scatter",
      name: "Humidity",
    };
    const ytemperature = {
      x: xtime,
      y: data.map((x) => x.Temperature),
      type: "scatter",
      name: "Ambient<br />Temperature",
    };
    const yec = {
      x: xtime,
      y: data.map((x) => x.EC),
      type: "scatter",
      name: "Electrical<br />Conductivity<br />",
    };
    const yph = {
      x: xtime,
      y: data.map((x) => x.pH),
      type: "scatter",
      name: "pH",
    };
    const yRTD = {
      x: xtime,
      y: data.map((x) => x.RTD),
      type: "scatter",
      name: "Soluble<br />Temperature",
    };

    this.envData.Device = device;
    this.envData.EC = {
      Data: [yec],
      Layout: {
        ...this.generateLayout(this.electricCunductivity),
        yaxis: {
          range: [0.0, 4.0],
          title: "Water conductivity (mS/cm)",
        },
      },
    };
    this.envData.pH = {
      Data: [yph],
      Layout: {
        ...this.generateLayout(this.phLevel),
        yaxis: {
          title: "pH level",
        },
      },
    };
    this.envData.Humid = {
      Data: [yhumidity],
      Layout: {
        ...this.generateLayout(this.humidity),
        yaxis: {
          title: "Humidity (%)",
        },
      },
    };
    this.envData.AirTemp = {
      Data: [ytemperature],
      Layout: {
        ...this.generateLayout(this.airTemperature),
        yaxis: {
          title: "Ambient air temperature (C)",
        },
      },
    };
    this.envData.WaterTemp = {
      Data: [yRTD],
      Layout: {
        ...this.generateLayout(this.waterTemperature),
        yaxis: {
          title: "Water temperature (C)",
        },
      },
    };
    this.loadGraph = false;
  }

  dateConverter(date: Date): string {
    const dateTime = moment
      .utc(date)
      .utcOffset(this.offset)
      .format("DD MMM YYYY h:mm");
    return dateTime;
  }
}
