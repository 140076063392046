<div fxLayout="row wrap" fxLayoutAlign="end stretch">
  <button
    nz-button
    *ngIf="self.Role.Admin || self.Role.Owner"
    nzType="primary"
    (click)="editDialog(tplTitle, tplContent, null, false)"
  >
    <i nz-icon nzType="plus" nzTheme="outline"></i>
    {{ "cameraSideMenu" | translate }}
  </button>
</div>
<br />

<nz-table nzSize="middle" [nzData]="camera$ | async" [nzLoading]="loadCard">
  <thead>
    <tr>
      <th>{{ "id" | translate }}</th>
      <th>{{ "description" | translate }}</th>
      <th>{{ "cropId" | translate }}</th>
      <th *ngIf="self.Role.Worker"></th>
      <th *ngIf="self.Role.Admin || self.Role.Owner">
        {{ "action" | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of camera$ | async">
      <td>{{ item.Id }}</td>
      <td>{{ item.Description }}</td>
      <td>{{ item.CropId }}</td>
      <td style="width: 90px">
        <button
          *ngIf="self.Role.Admin || self.Role.Owner"
          nz-button
          nzType="default"
          nzShape="circle"
          (click)="editDialog(tplTitle, tplContent, item, true)"
        >
          <i nz-icon nzType="edit"></i></button
        >&nbsp;
        <button
          *ngIf="self.Role.Admin || self.Role.Owner"
          nz-button
          nzType="danger"
          nzShape="circle"
          (click)="deleteDialog(item)"
        >
          <i nz-icon nzType="delete"></i>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>

<ng-template #tplTitle>
  <span>{{ "manageCamera" | translate }}</span>
</ng-template>

<ng-template #tplContent>
  <form nz-form [formGroup]="dialogFormGroup" class="dialog-form">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "description" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="Description is required"
      >
        <textarea
          nz-input
          formControlName="descriptionCtrl"
          placeholder="{{ 'deviceDescription' | translate }}"
          [nzAutosize]="{ minRows: 4 }"
        ></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "crop" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Crop is required">
        <nz-select
          nzPlaceHolder="{{ 'crop' | translate }}"
          formControlName="cropCtrl"
        >
          <nz-option nzLabel="None"></nz-option>
          <nz-option
            *ngFor="let crop of crops$ | async"
            [nzValue]="crop.Id"
            [nzLabel]="crop.Name"
          >
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>
