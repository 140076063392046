<div fxLayout="column" fxLayoutAlign="center center" class="container">
  <nz-card class="login-card">
    <div class="login-card">
      <div fxLayout="column" fxLayoutAlign="center center">
        <div id="login-div">
          <img class="login-icon bottom" src="./assets/secondary-logo.svg" />
        </div>
        <br />
        <div>
          <p style="text-align: center">
            Fill in your email that was used to login plantOS.
          </p>
          <form nz-form [formGroup]="formGroup" class="login-form">
            <nz-form-item>
              <nz-form-control>
                <nz-input-group [nzPrefix]="prefixUser">
                  <input
                    formControlName="emailCtrl"
                    nz-input
                    maxlength="255"
                    placeholder="Email"
                    readonly
                    autocomplete="off"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Device zone is required">
                <nz-input-group [nzPrefix]="prefixPassword">
                  <input
                    formControlName="passwordCtrl"
                    nz-input
                    required
                    type="password"
                    maxlength="255"
                    placeholder="Password"
                    autocomplete="off"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Device zone is required">
                <nz-input-group [nzPrefix]="prefixPassword">
                  <input
                    formControlName="cpasswordCtrl"
                    nz-input
                    required
                    type="password"
                    maxlength="255"
                    placeholder="Confirm Password"
                    autocomplete="off"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </form>
          <nz-tag
            [nzColor]="
              formGroup.controls['passwordCtrl'].hasError('required') ||
              formGroup.controls['passwordCtrl'].hasError('minlength')
                ? '#f50'
                : '#87d068'
            "
          >
            <i
              nz-icon
              nzType="{{
                formGroup.controls['passwordCtrl'].hasError('required') ||
                formGroup.controls['passwordCtrl'].hasError('minlength')
                  ? 'close-circle'
                  : 'check-circle'
              }}"
              nzTheme="outline"
            >
            </i>
            Must be at least 8 characters! </nz-tag
          ><br /><br />
          <nz-tag
            [nzColor]="
              formGroup.controls['passwordCtrl'].hasError('required') ||
              formGroup.controls['passwordCtrl'].hasError('hasNumber')
                ? '#f50'
                : '#87d068'
            "
          >
            <i
              nz-icon
              nzType="{{
                formGroup.controls['passwordCtrl'].hasError('required') ||
                formGroup.controls['passwordCtrl'].hasError('hasNumber')
                  ? 'close-circle'
                  : 'check-circle'
              }}"
              nzTheme="outline"
            >
            </i>
            Must contain at least 1 number! </nz-tag
          ><br /><br />
          <nz-tag
            [nzColor]="
              formGroup.controls['passwordCtrl'].hasError('required') ||
              formGroup.controls['passwordCtrl'].hasError('hasCapitalCase')
                ? '#f50'
                : '#87d068'
            "
          >
            <i
              nz-icon
              nzType="{{
                formGroup.controls['passwordCtrl'].hasError('required') ||
                formGroup.controls['passwordCtrl'].hasError('hasCapitalCase')
                  ? 'close-circle'
                  : 'check-circle'
              }}"
              nzTheme="outline"
            >
            </i>
            Must contain at least 1 in Capital Case! </nz-tag
          ><br /><br />
          <nz-tag
            [nzColor]="
              formGroup.controls['passwordCtrl'].hasError('required') ||
              formGroup.controls['passwordCtrl'].hasError('hasSmallCase')
                ? '#f50'
                : '#87d068'
            "
          >
            <i
              nz-icon
              nzType="{{
                formGroup.controls['passwordCtrl'].hasError('required') ||
                formGroup.controls['passwordCtrl'].hasError('hasSmallCase')
                  ? 'close-circle'
                  : 'check-circle'
              }}"
              nzTheme="outline"
            >
            </i>
            Must contain at least 1 Letter in Small Case! </nz-tag
          ><br /><br />
          <nz-tag
            [nzColor]="
              formGroup.controls['passwordCtrl'].hasError('required') ||
              formGroup.controls['passwordCtrl'].hasError(
                'hasSpecialCharacters'
              )
                ? '#f50'
                : '#87d068'
            "
          >
            <i
              nz-icon
              nzType="{{
                formGroup.controls['passwordCtrl'].hasError('required') ||
                formGroup.controls['passwordCtrl'].hasError(
                  'hasSpecialCharacters'
                )
                  ? 'close-circle'
                  : 'check-circle'
              }}"
              nzTheme="outline"
            >
            </i>
            Must contain at least 1 Special Character! </nz-tag
          ><br /><br />

          <ng-template #prefixUser><i nz-icon nzType="user"></i></ng-template>
          <ng-template #prefixPassword
            ><i nz-icon nzType="lock"></i
          ></ng-template>
          <div class="button-layout">
            <button
              nz-button
              nzType="primary"
              (click)="verify()"
              [disabled]="formGroup.invalid"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </nz-card>
</div>
