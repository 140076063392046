<div fxLayout="row wrap" fxLayoutAlign="end stretch">
  <button
    nz-button
    nzType="primary"
    (click)="editDialog(tplTitle, tplContent, null)"
  >
    <i nz-icon nzType="plus" nzTheme="outline"></i>
    {{ "deviceType" | translate }}
  </button>
</div>

<br />
<nz-table
  nzSize="middle"
  [nzData]="deviceType$ | async"
  [nzLoading]="!(deviceType$ | async)"
>
  <thead>
    <tr>
      <th>{{ "name" | translate }}</th>
      <th>{{ "action" | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of deviceType$ | async">
      <td>{{ item.Name }}</td>
      <td style="width: 90px">
        <button
          nz-button
          nzType="default"
          nzShape="circle"
          (click)="editDialog(tplTitle, tplContent, item)"
        >
          <i nz-icon nzType="edit"></i></button
        >&nbsp;
        <button
          nz-button
          nzType="danger"
          nzShape="circle"
          (click)="deleteDialog(item)"
        >
          <i nz-icon nzType="delete"></i>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>

<ng-template #tplTitle>
  <span>{{ "manageDeviceType" | translate }}</span>
</ng-template>
<ng-template #tplContent>
  <form nz-form [formGroup]="dialogFormGroup" class="dialog-form">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "name" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="Device type name is required"
      >
        <input
          type="text"
          nz-input
          formControlName="nameCtrl"
          placeholder="{{ 'name' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>
