<div fxLayout="row wrap" fxLayoutAlign="end stretch">
  <button
    nz-button
    nzType="primary"
    (click)="editDialog(tplTitle, tplContent, null)"
  >
    <i nz-icon nzType="plus" nzTheme="outline"></i> {{ "user" | translate }}
  </button>
</div>

<br />

<nz-table
  nzSize="middle"
  #companyUsers
  [nzData]="users$ | async"
  [nzLoading]="!(users$ | async)"
>
  <thead>
    <tr>
      <th>{{ "name" | translate }}</th>
      <th>{{ "email" | translate }}</th>
      <th>{{ "policy" | translate }}</th>
      <th>{{ "action" | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of companyUsers.data">
      <td>{{ item.Name }}</td>
      <td>{{ item.Email }}</td>
      <td>
        <span *ngIf="item.Role.Admin">{{ "admin" | translate }}</span>
        <span *ngIf="item.Role.Worker && !item.Role.Admin">{{
          "worker" | translate
        }}</span
        ><span *ngIf="item.Role.Owner && !item.Role.Admin">{{
          "owner" | translate
        }}</span>
      </td>
      <td style="width: 90px">
        <button
          nz-button
          nzType="default"
          nzShape="circle"
          (click)="editDialog(tplTitle, tplContent, item)"
        >
          <i nz-icon nzType="edit"></i></button
        >&nbsp;
        <button
          nz-button
          nzType="danger"
          nzShape="circle"
          (click)="deleteDialog(item)"
        >
          <i nz-icon nzType="delete"></i>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>

<ng-template #tplTitle>
  <span>{{ "manageUser" | translate }}</span>
</ng-template>

<ng-template #tplContent>
  <form nz-form [formGroup]="dialogFormGroup">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "email" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Email is required">
        <nz-input-group>
          <input
            type="text"
            nz-input
            formControlName="emailCtrl"
            placeholder="{{ 'email' | translate }}"
            autocomplete="off"
            maxlength="255"
            required
            email
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "name" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Name is required">
        <input
          type="text"
          nz-input
          formControlName="nameCtrl"
          placeholder="{{ 'name' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
    <!-- <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>Dashboard</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Dashboard Type is required">
        <nz-radio-group formControlName="dashboardCtrl" [nzButtonStyle]="'solid'">
          <label nz-radio-button nzValue="indoor">Indoor</label>
          <label nz-radio-button nzValue="outdoor">Outdoor</label>
          <label nz-radio-button nzValue="fertigation">Fertigation</label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item> -->
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "role" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Role is required">
        <nz-radio-group formControlName="roleCtrl" [nzButtonStyle]="'solid'">
          <label nz-radio-button nzValue="worker">{{
            "worker" | translate
          }}</label>
          <label nz-radio-button nzValue="owner">{{
            "owner" | translate
          }}</label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>
