export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAyyOHM-tC1wpFp0taT2RATmuNsA6iRNzE",
    authDomain: "plantos-development-225209.firebaseapp.com",
    databaseURL: "https://plantos-development-225209.firebaseio.com",
    projectId: "plantos-development-225209",
    storageBucket: "plantos-development-225209.appspot.com",
    messagingSenderId: "164720637351",
    appId: "1:164720637351:web:371d88a4e9fb1fb282b282",
  },
};
