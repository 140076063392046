import { Component, OnInit, TemplateRef } from "@angular/core";
import { NzModalRef, NzModalService, NzMessageService } from "ng-zorro-antd";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToolbarService } from "src/app/services/toolbar.service";
import { Observable } from "rxjs";
import {
  CropRevenue,
  Revenue,
  Expense,
} from "../../../../../models/profit-loss";
import { ProfitLossService } from "src/app/services/profit-loss.service";
import { Company } from "../../../../../models/company";
import {
  ONCE,
  WEEKLY,
  BI_WEEKLY,
  DAILY,
  MONTHLY,
  BI_MONTHLY,
} from "src/app/services/global-constant";
import moment from "moment";
import { recurrenceExpenseValidator } from "src/app/tools/task.validator";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-profit-loss",
  templateUrl: "./profit-loss.component.html",
  styleUrls: ["./profit-loss.component.scss"],
})
export class ProfitLossComponent implements OnInit {
  public tplModal: NzModalRef;
  public tplModalButtonLoading = false;

  public cropRevenueFormGroup: FormGroup;
  public revenueFormGroup: FormGroup;
  public expenseFormGroup: FormGroup;

  public cropRevenues$: Observable<CropRevenue[]>;
  public revenues$: Observable<Revenue[]>;
  public expenses$: Observable<Expense[]>;

  public months = [];
  public years: number[] = [];
  public yesTranslate: string;
  public noTranslate: string;
  public saveTranslate: string;
  public cancelTranslate: string;
  public areYouSureDeletionTranslate: string;
  public isVisibleTaskDate = false;
  public formGroup: FormGroup;

  constructor(
    private toolbar: ToolbarService,
    private modalService: NzModalService,
    private message: NzMessageService,
    private formBuilder: FormBuilder,
    private profitLossService: ProfitLossService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.stream("breakdownSideMenu").subscribe((translation) => {
      this.toolbar.changeHeader(translation);
    });

    this.translate.stream("yes").subscribe((translation) => {
      this.yesTranslate = translation;
    });

    this.translate.stream("no").subscribe((translation) => {
      this.noTranslate = translation;
    });

    this.translate.stream("cancel").subscribe((translation) => {
      this.cancelTranslate = translation;
    });

    this.translate.stream("save").subscribe((translation) => {
      this.saveTranslate = translation;
    });

    this.translate.stream("areYouSureForDelete").subscribe((translation) => {
      this.areYouSureDeletionTranslate = translation;
    });

    const today = new Date();
    for (let i = today.getFullYear(); i <= today.getFullYear() + 3; i++) {
      this.years.push(i);
    }

    this.months.push({ Name: "Jan", Value: 1 });
    this.months.push({ Name: "Feb", Value: 2 });
    this.months.push({ Name: "Mar", Value: 3 });
    this.months.push({ Name: "Apr", Value: 4 });
    this.months.push({ Name: "May", Value: 5 });
    this.months.push({ Name: "Jun", Value: 6 });
    this.months.push({ Name: "Jul", Value: 7 });
    this.months.push({ Name: "Aug", Value: 8 });
    this.months.push({ Name: "Sep", Value: 9 });
    this.months.push({ Name: "Oct", Value: 10 });
    this.months.push({ Name: "Nov", Value: 11 });
    this.months.push({ Name: "Dec", Value: 12 });

    const now = moment();
    this.formGroup = this.formBuilder.group({
      monthCtrl: [(now.month() + 1).toString()],
      yearCtrl: [now.year()],
    });

    this.search();
  }

  search(): void {
    const form = this.formGroup.value;
    const m = form.monthCtrl;
    const y = form.yearCtrl;

    this.cropRevenues$ = this.profitLossService.getCropRevenueListByMonthYear(
      m,
      y
    );
    this.revenues$ = this.profitLossService.getRevenueListByMonthYear(m, y);
    this.expenses$ = this.profitLossService.getExpenseListByMonthYear(m, y);
  }

  editCropRevenueDialog(
    tplTitle: TemplateRef<never>,
    tplContent: TemplateRef<never>,
    data: CropRevenue
  ): void {
    if (!data) {
      data = {} as CropRevenue;
      data.Company = {} as Company;
    }
    const item = { ...data };

    this.cropRevenueFormGroup = this.formBuilder.group({
      cropNameCtrl: [item.CropName, Validators.required],
      yieldCtrl: [item.Yield, Validators.required],
      priceCtrl: [item.Price, Validators.required],
      cycleCtrl: [item.CropCycle, Validators.required],
      outputCtrl: [item.Output, Validators.required],
      revenueCtrl: [item.CropRevenue, Validators.required],
      monthCtrl: [
        item.Month > 0 ? item.Month.toString() : "1",
        Validators.required,
      ],
      yearCtrl: [
        item.Year > 0 ? item.Year : new Date().getFullYear(),
        Validators.required,
      ],
    });

    const revenue$ = this.cropRevenueFormGroup.valueChanges.subscribe((x) => {
      if (x.yieldCtrl && x.priceCtrl) {
        this.cropRevenueFormGroup.patchValue(
          {
            revenueCtrl: x.yieldCtrl * x.priceCtrl,
          },
          { emitEvent: false }
        );
      }
    });

    this.tplModal = this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: [
        {
          label: this.cancelTranslate,
          type: "danger",
          onClick: () => {
            this.tplModal.destroy();
          },
        },
        {
          label: this.saveTranslate,
          type: "primary",
          disabled: () => this.cropRevenueFormGroup.invalid,
          onClick: async () => {
            revenue$.unsubscribe();

            const form = this.cropRevenueFormGroup.value;
            item.CropName = form.cropNameCtrl;
            item.Yield = Number(form.yieldCtrl);
            item.Price = Number(form.priceCtrl);
            item.CropCycle = Number(form.cycleCtrl);
            item.Output = Number(form.outputCtrl);
            item.CropRevenue = Number(form.revenueCtrl);
            item.Month = Number(form.monthCtrl);
            item.MonthName = this.months.find(
              (x) => x.Value === item.Month
            ).Name;
            item.Year = Number(form.yearCtrl);
            if (!item.Id) {
              this.profitLossService.addCropRevenue(item);
            } else {
              this.profitLossService.editCropRevenue(item);
            }

            this.displayResult(true, true);
            this.tplModal.destroy();
          },
        },
      ],
      nzMaskClosable: false,
      nzClosable: true,
    });
  }

  editRevenueDialog(
    tplTitle: TemplateRef<never>,
    tplContent: TemplateRef<never>,
    data: Revenue
  ): void {
    if (!data) {
      data = {} as Revenue;
      data.Company = {} as Company;
    }
    const item = { ...data };
    const itemDate = item.Date as any;

    this.revenueFormGroup = this.formBuilder.group({
      nameCtrl: [item.Name, Validators.required],
      amountCtrl: [item.Amount, Validators.required],
      dateCtrl: [
        itemDate ? new Date(itemDate.seconds * 1000) : "",
        Validators.required,
      ],
    });

    this.tplModal = this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: [
        {
          label: this.cancelTranslate,
          type: "danger",
          onClick: () => {
            this.tplModal.destroy();
          },
        },
        {
          label: this.saveTranslate,
          type: "primary",
          disabled: () => this.revenueFormGroup.invalid,
          onClick: async () => {
            const form = this.revenueFormGroup.value;
            item.Name = form.nameCtrl;
            item.Amount = form.amountCtrl;
            item.Date = form.dateCtrl;

            if (!item.Id) {
              this.profitLossService.addRevenue(item);
            } else {
              this.profitLossService.editRevenue(item);
            }

            this.displayResult(true, true);
            this.tplModal.destroy();
          },
        },
      ],
      nzMaskClosable: false,
      nzClosable: true,
    });
  }

  recurrenceOnChange(data: string): void {
    if (data === "once") {
      this.isVisibleTaskDate = true;
    } else {
      this.isVisibleTaskDate = false;
    }
  }

  setValidators(): void {
    const startDateCtrl = this.expenseFormGroup.get("startDateCtrl");
    const endDateCtrl = this.expenseFormGroup.get("endDateCtrl");
    const dateCtrl = this.expenseFormGroup.get("dateCtrl");

    this.expenseFormGroup
      .get("recurrenceCtrl")
      .valueChanges.subscribe((response) => {
        if (response === "once") {
          dateCtrl.setValidators([Validators.required]);
          startDateCtrl.setValidators(null);
          endDateCtrl.setValidators(null);
        } else {
          dateCtrl.setValidators(null);
          startDateCtrl.setValidators([Validators.required]);
          endDateCtrl.setValidators([Validators.required]);
        }

        dateCtrl.updateValueAndValidity();
        startDateCtrl.updateValueAndValidity();
        endDateCtrl.updateValueAndValidity();
      });
  }

  onClickSave(item: Expense): void {
    this.setValidators();

    const form = this.expenseFormGroup.value;

    item.Name = form.nameCtrl;
    item.Amount = form.amountCtrl;
    item.Recurrence = form.recurrenceCtrl;
    item.StartDate = form.startDateCtrl;
    item.EndDate = form.endDateCtrl;
    item.Date = form.dateCtrl;

    if (!item.Id) {
      // ADD
      if (form.recurrenceCtrl === ONCE) {
        item.StartDate = null;
        item.EndDate = null;
        item.Date = form.dateCtrl;

        this.profitLossService.addExpense(item);
      } else if (form.recurrenceCtrl === DAILY) {
        const endDate = moment(item.EndDate);
        const startDate = moment(item.StartDate);

        const days = endDate.diff(startDate, "days");

        const expenses = [] as Array<Expense>;

        for (let i = 0; i < days; i++) {
          const expenseObj = { ...item };
          expenseObj.Date = moment(expenseObj.StartDate)
            .add(i, "days")
            .toDate();
          expenses.push(expenseObj);
        }

        this.profitLossService.addExpenseList(expenses);
      } else if (form.recurrenceCtrl === WEEKLY) {
        const endDate = moment(item.EndDate);
        const startDate = moment(item.StartDate);

        const week = endDate.diff(startDate, "week");

        const expenses = [] as Array<Expense>;

        for (let i = 0; i < week; i++) {
          const expenseObj = { ...item };
          expenseObj.Date = moment(expenseObj.StartDate)
            .add(i, "weeks")
            .toDate();
          expenses.push(expenseObj);
        }

        this.profitLossService.addExpenseList(expenses);
      } else if (form.recurrenceCtrl === BI_WEEKLY) {
        const endDate = moment(item.EndDate);
        const startDate = moment(item.StartDate);
        const week = endDate.diff(startDate, "week");
        const biweek = Math.floor(week / 2);

        const expenses = [] as Array<Expense>;

        for (let i = 0; i < biweek; i++) {
          const expenseObj = { ...item };
          expenseObj.Date = moment(expenseObj.StartDate)
            .add(i * 2, "weeks")
            .toDate();
          expenses.push(expenseObj);
        }

        this.profitLossService.addExpenseList(expenses);
      } else if (form.recurrenceCtrl === MONTHLY) {
        const endDate = moment(item.EndDate);
        const startDate = moment(item.StartDate);

        const months = endDate.diff(startDate, "months");

        const expenses = [] as Array<Expense>;

        for (let i = 0; i < months; i++) {
          const expenseObj = { ...item };
          expenseObj.Date = moment(expenseObj.StartDate)
            .add(i, "months")
            .toDate();
          expenses.push(expenseObj);
        }

        this.profitLossService.addExpenseList(expenses);
      } else if (form.recurrenceCtrl === BI_MONTHLY) {
        const endDate = moment(item.EndDate);
        const startDate = moment(item.StartDate);

        const months = endDate.diff(startDate, "months");
        const bimonth = Math.floor(months / 2);

        const expenses = [] as Array<Expense>;

        for (let i = 0; i < bimonth; i++) {
          const expenseObj = { ...item };
          expenseObj.Date = moment(expenseObj.StartDate)
            .add(i, "months")
            .toDate();
          expenses.push(expenseObj);
        }

        this.profitLossService.addExpenseList(expenses);
      }
    } else {
      // UPDATE
      this.profitLossService.editExpense(item);
    }

    this.tplModal.destroy();
  }

  editExpenseDialog(
    tplTitle: TemplateRef<never>,
    tplContent: TemplateRef<never>,
    data: Expense,
    isEdit: boolean
  ): void {
    if (!data) {
      data = {} as Expense;
      data.Company = {} as Company;
    }
    const item = { ...data };
    const itemDate = item.Date as any;
    const itemStartDate = item.StartDate as any;
    const itemEndDate = item.EndDate as any;

    this.expenseFormGroup = this.formBuilder.group(
      {
        nameCtrl: [item.Name, Validators.required],
        amountCtrl: [item.Amount, Validators.required],
        dateCtrl: [
          itemDate ? new Date(itemDate.seconds * 1000) : "",
          Validators.required,
        ],
        recurrenceCtrl: [
          item.Recurrence ? item.Recurrence : ONCE,
          Validators.required,
        ],
        startDateCtrl: [
          itemStartDate ? new Date(itemStartDate.seconds * 1000) : "",
        ],
        endDateCtrl: [itemEndDate ? new Date(itemEndDate.seconds * 1000) : ""],
      },
      {
        validator: recurrenceExpenseValidator,
      }
    );

    this.tplModal = this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: [
        {
          label: this.cancelTranslate,
          type: "danger",
          onClick: () => {
            this.tplModal.destroy();
          },
        },
        {
          label: this.saveTranslate,
          type: "primary",
          disabled: () => this.expenseFormGroup.invalid,
          onClick: () => {
            this.onClickSave(item);
          },
        },
      ],
      nzMaskClosable: false,
      nzClosable: true,
    });
  }

  deleteCropRevenueDialog(item: CropRevenue): void {
    if (!item) {
      this.message.create("success", "Error delete item");
    } else {
      this.tplModal = this.modalService.create({
        nzTitle: this.areYouSureDeletionTranslate,
        nzContent: `${item.CropName} with revenue of ${item.CropRevenue}`,
        nzOkText: this.yesTranslate,
        nzOkType: "danger",
        nzOnOk: () => {
          this.profitLossService.deleteCropRevenue(item.Id);
          this.displayResult(true, false);
        },
        nzCancelText: this.noTranslate,
      });
    }
  }

  deleteRevenueDialog(item: Revenue): void {
    if (!item) {
      this.message.create("success", "Error delete item");
    } else {
      const itemDate = item.Date as any;
      const date = moment(new Date(itemDate.seconds * 1000));
      this.tplModal = this.modalService.create({
        nzTitle: this.areYouSureDeletionTranslate,
        nzContent: `"${item.Name}" with revenue of ${
          item.Amount
        } on ${date.format("MMM D, YYYY")}`,
        nzOkText: this.yesTranslate,
        nzOkType: "danger",
        nzOnOk: () => {
          this.profitLossService.deleteRevenue(item.Id);
          this.displayResult(true, false);
        },
        nzCancelText: this.noTranslate,
      });
    }
  }

  deleteExpenseDialog(item: Expense): void {
    if (!item) {
      this.message.create("success", "Error delete item");
    } else {
      const itemDate = item.Date as any;
      const date = moment(new Date(itemDate.seconds * 1000));
      this.tplModal = this.modalService.create({
        nzTitle: this.areYouSureDeletionTranslate,
        nzContent: `"${item.Name}" with expense of ${
          item.Amount
        } on ${date.format("MMM D, YYYY")}`,
        nzOkText: this.yesTranslate,
      });
    }
  }

  displayResult(response: boolean, isSave: boolean): void {
    if (response) {
      const msg = isSave
        ? "Item successfully saved"
        : "Item successfully deleted";
      this.message.create("success", msg);
    } else {
      const msg = isSave ? "Error saving item" : "Error deleting item";
      this.message.create("warning", msg);
    }
  }
}
