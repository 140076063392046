import { Component, OnInit, TemplateRef } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { User, Role } from "../../../../../models/user";
import { Company } from "../../../../../models/company";
import { NzModalRef, NzModalService, NzMessageService } from "ng-zorro-antd";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToolbarService } from "src/app/services/toolbar.service";
import { CompanyService } from "src/app/services/company.service";
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit {
  public tplModal: NzModalRef;
  public dialogFormGroup: FormGroup;
  public tplModalButtonLoading = false;
  public company$: Observable<Company[]>;
  public users$: Observable<User[]>;
  public self: User;
  public yesTranslate: string;
  public cancelTranslate: string;
  public saveTranslate: string;
  public noTranslate: string;
  public areYouSureDeletionTranslate: string;

  constructor(
    private toolbarService: ToolbarService,
    private companyService: CompanyService,
    private userService: UserService,
    private modalService: NzModalService,
    private message: NzMessageService,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.stream("manageUser").subscribe((translation) => {
      this.toolbarService.changeHeader(translation);
    });
    this.translate.stream("cancel").subscribe((translation) => {
      this.cancelTranslate = translation;
    });
    this.translate.stream("save").subscribe((translation) => {
      this.saveTranslate = translation;
    });
    this.translate.stream("yes").subscribe((translation) => {
      this.yesTranslate = translation;
    });
    this.translate.stream("no").subscribe((translation) => {
      this.noTranslate = translation;
    });
    this.translate.stream("areYouSureForDelete").subscribe((translation) => {
      this.areYouSureDeletionTranslate = translation;
    });
    this.company$ = this.companyService.getList();
    this.users$ = this.userService.getList();
    this.userService.self$.subscribe((self) => (this.self = self));
  }

  editDialog(
    tplTitle: TemplateRef<never>,
    tplContent: TemplateRef<never>,
    data: User
  ): void {
    if (!data) {
      data = {} as User;
      data.Company = {} as Company;
      data.Role = {} as Role;
      data.Dashboard = { Indoor: false, Outdoor: false, Fertigation: false };
      data.Role.Worker = true;
      data.Role.Owner = false;
      data.Role.Admin = false;
    }
    const item = { ...data };

    let assignedRole: string;
    if (item.Role.Worker) {
      assignedRole = "worker";
    } else if (item.Role.Owner) {
      assignedRole = "owner";
    } else if (item.Role.Admin) {
      assignedRole = "admin";
    }

    let dashboard: string;
    if (item.Dashboard.Indoor) {
      dashboard = "indoor";
    } else if (item.Dashboard.Outdoor) {
      dashboard = "outdoor";
    } else if (item.Dashboard.Fertigation) {
      dashboard = "fertigation";
    }

    this.dialogFormGroup = this.formBuilder.group({
      companyCtrl: [item.Company.Id, Validators.required],
      emailCtrl: [item.Email, [Validators.required, Validators.email]],
      nameCtrl: [item.Name, Validators.required],
      roleCtrl: [assignedRole, Validators.required],
      dashboardCtrl: [dashboard, Validators.required],
    });

    this.tplModal = this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: [
        {
          label: this.cancelTranslate,
          type: "danger",
          onClick: () => {
            this.tplModal.destroy();
          },
        },
        {
          label: this.saveTranslate,
          type: "primary",
          disabled: () => this.dialogFormGroup.invalid,
          onClick: () => {
            const form = this.dialogFormGroup.value;
            item.Company.Id = form.companyCtrl;
            item.Email = form.emailCtrl;
            item.Name = form.nameCtrl;
            item.Dashboard.Indoor = form.dashboardCtrl === "indoor";
            item.Dashboard.Outdoor = form.dashboardCtrl === "outdoor";
            item.Dashboard.Fertigation = form.dashboardCtrl === "fertigation";
            item.Role.Worker = form.roleCtrl === "worker";
            item.Role.Admin = form.roleCtrl === "admin";
            item.Role.Owner = form.roleCtrl === "owner";

            if (!item.Id) {
              this.userService
                .add(item)
                .then(() => {
                  this.displayResult(true, true);
                  this.tplModal.destroy();
                })
                .catch((err) => {
                  console.log(err);
                  this.message.create("error", err.message);
                });
            } else {
              this.userService
                .edit(item)
                .then(() => {
                  this.displayResult(true, true);
                  this.tplModal.destroy();
                })
                .catch(() => {
                  this.displayResult(false, true);
                });
            }
          },
        },
      ],
      nzMaskClosable: false,
      nzClosable: true,
    });
  }

  deleteDialog(item: User): void {
    if (!item) {
      this.message.create("success", "Error delete item");
    } else {
      this.tplModal = this.modalService.create({
        nzTitle: this.areYouSureDeletionTranslate,
        nzContent: item.Name,
        nzOkText: this.yesTranslate,
        nzOkType: "danger",
        nzOnOk: () => {
          this.userService.delete(item.Id, item.Email);
          this.displayResult(true, false);
        },
        nzCancelText: this.noTranslate,
      });
    }
  }

  displayResult(response: boolean, isSave: boolean): void {
    if (response) {
      const msg = isSave
        ? "Item successfully saved"
        : "Item successfully deleted";
      this.message.create("success", msg);
    } else {
      const msg = isSave ? "Error saving item" : "Error deleting item";
      this.message.create("warning", msg);
    }
  }
}
