import { ErrorHandler, isDevMode, Injectable } from "@angular/core";

import StackdriverErrorReporter from "stackdriver-errors-js";

export const errorHandler = new StackdriverErrorReporter();
errorHandler.start({
  key: "AIzaSyAyyOHM-tC1wpFp0taT2RATmuNsA6iRNzE",
  projectId: "plantos-development-225209",
  service: "web",
});

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: Error): void {
    console.error(error);
    if (!isDevMode()) {
      errorHandler.report(error);
    }
  }
}
