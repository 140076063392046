import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthenticateService } from "../services/authenticate.service";
import { ActivatedRoute, Router } from "@angular/router";
import { trigger, transition, style, animate } from "@angular/animations";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [style({ opacity: 1 }), animate("1s ease-in-out")]),
      transition(":leave", [style({ opacity: 0 }), animate("1s ease-in-out")]),
    ]),
  ],
})
export class LoginComponent implements OnInit {
  public returnUrl: string;
  public favicon = "";
  public enter = false;
  public loginFormGroup: FormGroup;
  public isCaptchaed = false;

  public notificationText = "";

  constructor(
    private authenticateService: AuthenticateService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || "/";

    this.loginFormGroup = this.formBuilder.group({
      emailCtrl: ["", [Validators.required, Validators.email]],
      passwordCtrl: ["", Validators.required],
    });
  }

  login(email: string, password: string): void {
    this.notificationText = "Logging in...";
    this.authenticateService
      .login(email, password)
      .then(() => {
        this.navigateToDashboard();
      })
      .catch((err) => {
        this.notificationText = `${err.message}`;
      });
  }

  navigateToDashboard(): void {
    this.returnUrl = "/p/task";
    this.router.navigate([this.returnUrl]);
  }
}
