import { Component, OnInit, TemplateRef } from "@angular/core";
import { NzModalRef, NzModalService, NzMessageService } from "ng-zorro-antd";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { Camera } from "../../../../../models/camera";
import { User } from "../../../../../models/user";
import { Crop } from "../../../../../models/crop";
import { ToolbarService } from "src/app/services/toolbar.service";
import { UserService } from "src/app/services/user.service";
import { CropService } from "src/app/services/crop.service";
import { CameraService } from "src/app/services/camera.service";
import { Company } from "../../../../../models/company";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-camera",
  templateUrl: "./camera.component.html",
  styleUrls: ["./camera.component.scss"],
})
export class CameraComponent implements OnInit {
  public tplModal: NzModalRef;
  public dialogFormGroup: FormGroup;
  public tplModalButtonLoading = false;
  public camera$: Observable<Camera[]>;
  public self: User;
  public crops$: Observable<Crop[]>;
  public activeCamera: Camera;
  public isEditing = false;
  public loadCard = true;
  public cancelTranslate: string;
  public saveTranslate: string;
  public yesTranslate: string;
  public noTranslate: string;
  public areYouSureDeletionTranslate: string;

  constructor(
    private toolbar: ToolbarService,
    private cameraService: CameraService,
    private modalService: NzModalService,
    private userService: UserService,
    private message: NzMessageService,
    private formBuilder: FormBuilder,
    public cropService: CropService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.stream("cameraManagement").subscribe((translation) => {
      this.toolbar.changeHeader(translation);
    });
    this.translate.stream("cancel").subscribe((translation) => {
      this.cancelTranslate = translation;
    });
    this.translate.stream("save").subscribe((translation) => {
      this.saveTranslate = translation;
    });
    this.translate.stream("yes").subscribe((translation) => {
      this.yesTranslate = translation;
    });
    this.translate.stream("no").subscribe((translation) => {
      this.noTranslate = translation;
    });
    this.translate.stream("areYouSureForDelete").subscribe((translation) => {
      this.areYouSureDeletionTranslate = translation;
    });
    this.camera$ = this.cameraService.getList();
    if (!this.camera$) {
      this.loadCard = true;
    } else {
      this.loadCard = false;
    }
    this.crops$ = this.cropService.getList();
    this.self = this.userService.self;
  }

  editDialog(
    tplTitle: TemplateRef<Record<string, never>>,
    tplContent: TemplateRef<never>,
    data: Camera,
    isEditing: boolean
  ): void {
    if (!data) {
      data = {} as Camera;
      data.Photos = [];
      data.Company = {} as Company;
    }
    const item = { ...data };
    this.activeCamera = item;
    this.dialogFormGroup = this.formBuilder.group({
      descriptionCtrl: [item.Description, Validators.required],
      cropCtrl: [item.CropId, Validators.required],
    });
    this.isEditing = isEditing;
    this.tplModal = this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: [
        {
          label: this.cancelTranslate,
          type: "danger",
          onClick: () => {
            this.tplModal.destroy();
            this.isEditing = false;
          },
        },
        {
          label: this.saveTranslate,
          type: "primary",
          disabled: () => this.dialogFormGroup.invalid,
          onClick: async () => {
            const form = this.dialogFormGroup.value;
            item.Description = form.descriptionCtrl;
            item.CropId = form.cropCtrl;

            if (!item.Id) {
              this.cameraService.add(item);
            } else {
              this.cameraService.edit(item);
            }
            this.displayResult(true, true);
            this.tplModal.destroy();
            this.isEditing = false;
          },
        },
      ],
      nzMaskClosable: false,
      nzClosable: true,
    });
  }

  deleteDialog(item: Camera): void {
    if (!item) {
      this.message.create("success", "Error delete item");
    } else {
      this.tplModal = this.modalService.create({
        nzTitle: this.areYouSureDeletionTranslate,
        nzContent: `${item.Description}`,
        nzOkText: this.yesTranslate,
        nzOkType: "danger",
        nzOnOk: () => {
          this.cameraService.delete(item.Id);
          this.displayResult(true, false);
        },
        nzCancelText: this.noTranslate,
      });
    }
  }

  displayResult(response: boolean, isSave: boolean): void {
    if (response) {
      const msg = isSave
        ? "Item successfully saved"
        : "Item successfully deleted";
      this.message.create("success", msg);
    } else {
      const msg = isSave ? "Error saving item" : "Error deleting item";
      this.message.create("warning", msg);
    }
  }
}
