import { Component, OnInit, TemplateRef } from "@angular/core";
import { DeviceType } from "../../../../../models/device-type";
import { ToolbarService } from "src/app/services/toolbar.service";
import { DeviceTypeService } from "src/app/services/devicetype.service";
import { NzModalService, NzMessageService, NzModalRef } from "ng-zorro-antd";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-device-type",
  templateUrl: "./device-type.component.html",
  styleUrls: ["./device-type.component.scss"],
})
export class DeviceTypeComponent implements OnInit {
  public tplModal: NzModalRef;
  public dialogFormGroup: FormGroup;
  public tplModalButtonLoading = false;
  public cancelTranslate: string;
  public yesTranslate: string;
  public noTranslate: string;
  public saveTranslate: string;
  public areYouSureDeletionTranslate: string;
  public deviceType$: Observable<DeviceType[]>;

  constructor(
    private toolbar: ToolbarService,
    private deviceTypeService: DeviceTypeService,
    private modalService: NzModalService,
    private message: NzMessageService,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.stream("manageDeviceType").subscribe((translation) => {
      this.toolbar.changeHeader(translation);
    });
    this.translate.stream("cancel").subscribe((translation) => {
      this.cancelTranslate = translation;
    });
    this.translate.stream("save").subscribe((translation) => {
      this.saveTranslate = translation;
    });
    this.translate.stream("yes").subscribe((translation) => {
      this.yesTranslate = translation;
    });
    this.translate.stream("no").subscribe((translation) => {
      this.noTranslate = translation;
    });
    this.translate.stream("areYouSureForDelete").subscribe((translation) => {
      this.areYouSureDeletionTranslate = translation;
    });
    this.deviceType$ = this.deviceTypeService.getList();
  }

  editDialog(
    tplTitle: TemplateRef<never>,
    tplContent: TemplateRef<never>,
    data: DeviceType
  ): void {
    if (!data) {
      data = {} as DeviceType;
    }
    const item = { ...data };

    this.dialogFormGroup = this.formBuilder.group({
      nameCtrl: [item.Name, Validators.required],
    });

    this.tplModal = this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: [
        {
          label: this.cancelTranslate,
          type: "danger",
          onClick: () => {
            this.tplModal.destroy();
          },
        },
        {
          label: this.saveTranslate,
          type: "primary",
          disabled: () => this.dialogFormGroup.invalid,
          onClick: () => {
            const form = this.dialogFormGroup.value;
            item.Name = form.nameCtrl;

            if (!item.Id) {
              this.deviceTypeService.add(item);
            } else {
              this.deviceTypeService.edit(item);
            }
            this.tplModal.destroy();
          },
        },
      ],
      nzMaskClosable: false,
      nzClosable: true,
    });
  }

  deleteDialog(item: DeviceType): void {
    if (!item) {
      this.message.create("success", "Error delete item");
    } else {
      this.tplModal = this.modalService.create({
        nzTitle: this.areYouSureDeletionTranslate,
        nzContent: item.Name,
        nzOkText: this.yesTranslate,
        nzOkType: "danger",
        nzOnOk: () => {
          this.deviceTypeService.delete(item.Id);
          this.displayResult(true, false);
        },
        nzCancelText: this.noTranslate,
      });
    }
  }

  displayResult(response: boolean, isSave: boolean): void {
    if (response) {
      const msg = isSave
        ? "Item successfully saved"
        : "Item successfully deleted";
      this.message.create("success", msg);
    } else {
      const msg = isSave ? "Error saving item" : "Error deleting item";
      this.message.create("warning", msg);
    }
  }
}
