import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Crop } from "../../../../models/crop";
import { GrowthPrediction } from "../../../../models/growth_prediction";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { UserService } from "./user.service";
import { Ticket } from "../../../../models/ticket";
import { Photo } from "../../../../models/photo";
import { Camera } from "../../../../models/camera";

import firebase from "firebase/app";
import { DeviceService } from "./device.service";
import { FruitDetection } from "../../../../models/fruit_detection";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CropService {
  public collection: AngularFirestoreCollection<Crop>;
  public ticketsCollection: AngularFirestoreCollection<Ticket>;

  constructor(
    private db: AngularFirestore,
    private userService: UserService,
    private deviceService: DeviceService,
    private http: HttpClient
  ) {
    this.collection = this.db.collection<Crop>("crops", (ref) => {
      const c1 = ref.where(
        "Company.Id",
        "==",
        this.userService.self.Company.Id
      );
      return c1;
    });
    this.ticketsCollection = this.db.collection<Ticket>("tickets", (ref) => {
      const c1 = ref.where(
        "User.Company.Id",
        "==",
        this.userService.self.Company.Id
      );
      return c1;
    });
  }

  async list(company: string): Promise<firebase.firestore.QuerySnapshot<Crop>> {
    return (await firebase
      .firestore()
      .collection("crops")
      .where("Company.Id", "==", company)
      .get()) as firebase.firestore.QuerySnapshot<Crop>;
  }

  getList(): Observable<Array<Crop>> {
    const list$ = this.db
      .collection<Crop>("crops", (ref) => {
        const c1 = ref.where(
          "Company.Id",
          "==",
          this.userService.self.Company.Id
        );
        return c1;
      })
      .valueChanges({ idField: "Id" });
    return list$.pipe(
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  async getGrowthPredictions(
    cropId: string,
    cameraId: string
  ): Promise<firebase.firestore.QuerySnapshot<GrowthPrediction>> {
    return (await firebase
      .firestore()
      .collection("growthPrediction")
      .where("cropId", "==", cropId)
      .where("camera", "==", cameraId)
      .orderBy("timestamp", "desc")
      .get()) as firebase.firestore.QuerySnapshot<GrowthPrediction>;
  }

  getticketsList(crop: Crop): Observable<Array<Ticket>> {
    const list$ = this.db
      .collection<Ticket>("tickets", (ref) => {
        const c1 = ref
          .where("CropId", "==", crop.Id)
          .orderBy("Timestamp", "desc");
        return c1;
      })
      .valueChanges({ idField: "Id" });
    return list$.pipe(
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  async addTicket(data: Ticket): Promise<void> {
    const docRef = await this.ticketsCollection.add(data);
    data.Id = docRef.id;
    this.editTicket(data);
  }

  editTicket(data: Ticket): void {
    this.ticketsCollection.doc(data.Id).update(data);
  }

  getImages(cameraId: string): Observable<Photo[]> {
    const list$ = this.db
      .collection<Camera>("cameras")
      .doc(cameraId)
      .collection<Photo>("photos", (ref) => {
        return ref.orderBy("UnixSeconds", "desc").limit(100);
      })
      .valueChanges({ idField: "Id" });
    return list$.pipe(
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  listCameras(cropId: string): Observable<Camera[]> {
    const list$ = this.db
      .collection<Camera>("cameras", (ref) => {
        return ref
          .where("Company.Id", "==", this.userService.self.Company.Id)
          .where("CropId", "==", cropId);
      })
      .valueChanges({ idField: "Id" });
    return list$.pipe(
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  deleteTicket(id: string): void {
    this.ticketsCollection.doc(id).delete();
  }

  getTicket(
    Id: string
  ): Observable<firebase.firestore.DocumentSnapshot<Ticket>> {
    return this.db.collection<Ticket>("tickets").doc(Id).get();
  }

  async add(data: Crop): Promise<void> {
    data.Company = this.userService.self.Company;
    const docRef = await this.collection.add(data);
    data.Id = docRef.id;
    await this.edit(data);
  }

  async edit(data: Crop): Promise<void> {
    data.Company = this.userService.self.Company;
    // FIXME(simon): Do not rely on data.Id here.
    console.log(`edit crop ${data.Id} => ${JSON.stringify(data)}`);

    await this.collection.doc(data.Id).update(data);

    if (data.Recipes.length == 0) {
      console.log(`not updating devices, no recipes assigned.`);
      return;
    }

    // If we update the recipe for a Crop we need to then go and check which
    // Devices are using this Crop and update them accordingly.
    //
    // The relation is:
    // - Device has a Crop (via Device.CropId)
    // - Crop has a Recipe (via Crop.Recipes[0])

    const devices = await this.deviceService.list(
      this.userService.self.Company.Id
    );

    for (const doc of devices.docs) {
      const device = doc.data();
      if (device.CropId == data.Id) {
        console.log(
          `device ${doc.id} ${device.DeviceId} is using this crop.. updating it's config.`
        );
        await this.deviceService.updateDeviceConfig(
          device.RegistryId,
          device.DeviceId,
          device.SumpTankCapacityLitres,
          data.Recipes[0].EC.Min,
          data.Recipes[0].EC.Optimal,
          data.Recipes[0].pH.Max,
          device.MaxFrequency
        );
      }
    }
  }

  delete(id: string): void {
    this.collection.doc(id).delete();
  }

  get(Id: string): Observable<firebase.firestore.DocumentSnapshot<Crop>> {
    return this.db.collection<Crop>("crops").doc(Id).get();
  }

  async getFruitDetectionResults(cropId: string): Promise<FruitDetection[]> {
    const cameras = await firebase
      .firestore()
      .collection("cameras")
      .where("Company.Id", "==", this.userService.self.Company.Id)
      .where("CropId", "==", cropId)
      .get();
    if (cameras.empty) {
      throw `No cameras found`;
    }

    const r: FruitDetection[] = [];
    for (const camera of cameras.docs) {
      const results = await firebase
        .firestore()
        .collection("fruitDetection")
        .where("camera", "==", camera.id)
        .orderBy("timestamp", "desc")
        .limit(1)
        .get();
      for (const result of results.docs) {
        // console.log(`latest inference is ${JSON.stringify(result.data())}`);

        r.push(result.data() as FruitDetection);
      }
    }
    return r;
  }

  getCurrentUserToken(): Promise<string> {
    return firebase.auth().currentUser.getIdToken(true);
  }
}
