import { Component, OnInit, TemplateRef } from "@angular/core";
import { CropService } from "src/app/services/crop.service";
import { Crop } from "../../../../../models/crop";
import { ToolbarService } from "src/app/services/toolbar.service";
import { AttachmentService } from "src/app/services/attachment.service";
import { NzModalService, NzMessageService, NzModalRef } from "ng-zorro-antd";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { UserService } from "src/app/services/user.service";
import { Company } from "../../../../../models/company";
import { Observable } from "rxjs";
import { Ticket } from "../../../../../models/ticket";
import { Camera } from "../../../../../models/camera";
import { CameraService } from "src/app/services/camera.service";
import { Recipe } from "../../../../../models/recipe";
import { RecipeService } from "src/app/services/recipe.service";
import { TranslateService } from "@ngx-translate/core";

import firebase from "firebase/app";

@Component({
  selector: "app-crop",
  templateUrl: "./crop.component.html",
  styleUrls: ["./crop.component.scss"],
})
export class CropComponent implements OnInit {
  public cropList: Observable<Crop[]>;
  public ticketList: Observable<Ticket[]>;
  public recipesList: Recipe[];
  public progress: { [key: string]: number } = {};
  public tplModal: NzModalRef;
  public dialogFormGroup: FormGroup;
  public tplModalButtonLoading = false;
  public files: Set<File> = new Set();
  public cameras: Camera[];

  // FIXME: What's going on here?
  public crop: Crop = {
    Id: "",
    Name: "",
    Company: {
      Id: "",
      RegistryId: "",
      Name: "",
      Address1: "",
      Address2: "",
      State: "",
      City: "",
      Postcode: "",
      Country: "",
    },
    Selected: false,
    Schedules: [],
    Cameras: [],
    Recipes: [],
  } as Crop;
  public translations: { save?: string; cancel?: string } = {};
  public yesTranslate: string;
  public noTranslate: string;
  public areYouSureDeletionTranslate: string;

  constructor(
    private toolbar: ToolbarService,
    private cropService: CropService,
    public attachmentService: AttachmentService,
    private modalService: NzModalService,
    public userService: UserService,
    private message: NzMessageService,
    private formBuilder: FormBuilder,
    private cameraService: CameraService,
    private recipeService: RecipeService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.stream("cropManager").subscribe((translation) => {
      this.toolbar.changeHeader(translation);
    });
    this.translate.stream("save").subscribe((translation) => {
      this.translations.save = translation;
    });
    this.translate.stream("cancel").subscribe((translation) => {
      this.translations.cancel = translation;
    });
    this.translate.stream("yes").subscribe((translation) => {
      this.yesTranslate = translation;
    });
    this.translate.stream("no").subscribe((translation) => {
      this.noTranslate = translation;
    });
    this.translate.stream("areYouSureForDelete").subscribe((translation) => {
      this.areYouSureDeletionTranslate = translation;
    });

    this.cropList = this.cropService.getList();
    this.cameraService
      .getList()
      .subscribe((list) => (this.cameras = [...list]));
    this.recipeService
      .getList()
      .subscribe((list) => (this.recipesList = [...list]));
  }

  editDialog(
    tplTitle: TemplateRef<never>,
    tplContent: TemplateRef<never>,
    data: Crop
  ): void {
    if (!data) {
      data = {} as Crop;
      data.Company = {} as Company;

      // Default the date to today.
      data.StartDate = firebase.firestore.Timestamp.now();
    }
    const item = { ...data };

    console.log(`editing crop ${data.Id} => ${JSON.stringify(data)}`);

    this.dialogFormGroup = this.formBuilder.group({
      nameCtrl: [item.Name, Validators.required],
      cropStagesFormArray: new FormArray([]),
      camerasCtrl: [item.Cameras ? item.Cameras.map((x) => x.Id) : []],
      recipesCtrl: [
        item.Recipes && item.Recipes.length > 0 ? item.Recipes[0].Id : [],
      ],
      startDateCtrl: [item.StartDate ? item.StartDate.toDate() : null],
    });

    this.tplModal = this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: [
        {
          label: this.translations.cancel,
          type: "danger",
          onClick: () => {
            this.tplModal.destroy();
          },
        },
        {
          label: this.translations.save,
          type: "primary",
          disabled: () => this.dialogFormGroup.invalid,
          onClick: () => {
            const cdata = this.dialogFormGroup.value;
            item.Name = cdata.nameCtrl;
            if (cdata.camerasCtrl) {
              item.Cameras = [];
              cdata.camerasCtrl.forEach((cameraId) => {
                const u = this.cameras.find((x) => x.Id === cameraId);
                item.Cameras.push(u);
              });
            }
            if (cdata.recipesCtrl) {
              const recipeId = cdata.recipesCtrl;
              item.Recipes = this.recipesList.filter((x) => x.Id === recipeId);
            }

            item.StartDate = firebase.firestore.Timestamp.fromDate(
              cdata.startDateCtrl
            );

            // FIXME(simon): Add error handling here.
            if (!item.Id) {
              this.cropService.add(item);
            } else {
              this.cropService.edit(item);
            }

            this.displayResult(true, true);
            this.tplModal.destroy();
          },
        },
      ],
      nzMaskClosable: false,
      nzClosable: true,
    });
  }

  deleteDialog(item: Crop): void {
    if (!item) {
      this.message.create("success", "Error delete item");
    } else {
      this.tplModal = this.modalService.create({
        nzTitle: this.areYouSureDeletionTranslate,
        nzContent: item.Name,
        nzOkText: this.yesTranslate,
        nzOkType: "danger",
        nzOnOk: () => {
          this.cropService.delete(item.Id);
          this.displayResult(true, false);
        },
        nzCancelText: this.noTranslate,
      });
    }
  }

  displayResult(response: boolean, isSave: boolean): void {
    if (response) {
      this.cropList = this.cropService.getList();
      const msg = isSave
        ? "Item successfully saved"
        : "Item successfully deleted";
      this.message.create("success", msg);
    } else {
      const msg = isSave ? "Error saving item" : "Error deleting item";
      this.message.create("warning", msg);
    }
  }

  view(item: Crop): void {
    if (item) {
      this.crop = item;
    }
  }
}
