<div *ngIf="fruitDetectionResults">
  <h2>Fruit detection results</h2>

  <div style="display: flex; flex-direction: row; flex-wrap: wrap; gap: 12px">
    <div
      *ngFor="let image of fruitDetectionResults"
      style="position: relative"
      [ngStyle]="{
        'background-size': 'cover',
        'background-image': 'url(' + image.photoUri + ')'
      }"
      [style.width.px]="2592 / 8"
      [style.height.px]="1944 / 8"
    >
      <div
        *ngFor="let box of image.actualBoxes"
        style="
          position: absolute;
          border-color: red;
          border-style: solid;
          border-width: 2px;
          font-size: xx-small;
        "
        [style.opacity.%]="box.score * box.score * box.score * 100"
        [style.left.%]="100 * box.left"
        [style.top.%]="100 * box.top"
        [style.width.%]="100 * (box.right - box.left)"
        [style.height.%]="100 * (box.bottom - box.top)"
      >
        <span style="display: none">{{ box.score }}</span>
      </div>
    </div>
  </div>
</div>
<br />

<!-- TODO: upgrade @swimlane/ngx-charts
<div class="table-div">
  <div *ngIf="growthChart">
    <div class="chart">
      <h1>{{ "rateOfGrowth" | translate }}</h1>
      <ngx-charts-line-chart
        [view]="view"
        [scheme]="growthColorScheme"
        [results]="growthChart"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="growthXAxisLabel"
        [yAxisLabel]="growthYAxisLabel"
        [timeline]="timeline"
      >
      </ngx-charts-line-chart>
    </div>
  </div>
</div>
<br />
-->

<h2>Photos</h2>
<div class="gallery" *ngFor="let galleryImage of galleryImages">
  <div class="gallery-images">
    <div
      id="{{ galleryImage.id }}"
      style="padding-top: 25px; padding-bottom: 10px"
    >
      {{ galleryImage.title }} (last updated:
      {{ galleryImage.latestTimestamp }})
    </div>
    <ngx-gallery
      [options]="galleryOptions"
      [images]="galleryImage.images"
    ></ngx-gallery>
  </div>
</div>
<br />

<div fxLayout="row wrap" fxLayoutAlign="start stretch">
  <button
    [ngStyle]="{
      margin: '5px',
      color: 'white',
      display: 'inline-block',
      background: '#17b484',
      border: 'none',
      padding: '7px 15px',
      fontweight: '700',
      borderRadius: '5px',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      fontSize: '10pt'
    }"
    (click)="editDialog(tplTitle, tplContent, null)"
  >
    {{ "addAComment" | translate }}
  </button>
  <span class="attach-image-button">
    <label for="upload-photo">{{ "attachAnImage" | translate }}</label>
    <input
      type="file"
      name="file"
      (input)="onFileSelected($event)"
      accept=".jpeg,.jpg,.png,.svg"
      id="upload-photo"
    />
  </span>
</div>
<div class="timeline">
  <nz-timeline>
    <div *ngFor="let ticket of ticketsList | async">
      <div *ngIf="ticket.Action.Comment">
        <nz-timeline-item [nzDot]="editTemplate" nzColor="blue">
          <h4>
            {{
              ticket.User.Name === self.Name
                ? ("you" | translate)
                : ticket.User.Name
            }}
            {{ "commentedOn" | translate }} ({{
              ticket.Timestamp.seconds * 1000 | date: "medium"
            }})
          </h4>
          <h4>{{ ticket.Title }}</h4>
          <p>{{ ticket.Description }}</p>
        </nz-timeline-item>
      </div>
      <div *ngIf="ticket.Action.CompleteTask">
        <nz-timeline-item [nzDot]="doneTemplate" nzColor="blue">
          <h4>
            {{
              ticket.User.Name === self.Name
                ? ("you" | translate)
                : ticket.User.Name
            }}
            {{ "completedTheTask" | translate }} {{ ticket.Task.Name }} on ({{
              ticket.Timestamp.seconds * 1000 | date: "medium"
            }})
          </h4>
          <h4>{{ ticket.Title }}</h4>
          <p>{{ ticket.Description }}</p>
        </nz-timeline-item>
      </div>
      <div *ngIf="ticket.Action.AssignedTask">
        <nz-timeline-item [nzDot]="assignTaskTemplate" nzColor="blue">
          <h4>
            {{
              ticket.User.Name === self.Name
                ? ("you" | translate)
                : ticket.User.Name
            }}
            {{ "assignedTheTask" | translate }} ( {{ ticket.Task.Name }} )
            {{ "to" | translate }} {{ getAssignedUsers(ticket.Task) }}
            {{ "on" | translate }} ({{
              ticket.Timestamp.seconds * 1000 | date: "medium"
            }})
          </h4>
          <h4>{{ ticket.Title }}</h4>
          <p>{{ ticket.Description }}</p>
        </nz-timeline-item>
      </div>
      <div *ngIf="ticket.Action.Attached">
        <nz-timeline-item [nzDot]="attachedPhotoTemplate" nzColor="blue">
          <h4>
            {{
              ticket.User.Name === self.Name
                ? ("you" | translate)
                : ticket.User.Name
            }}
            {{ "attachedAPhotoOn" | translate }} ({{
              ticket.Timestamp.seconds * 1000 | date: "medium"
            }})
          </h4>
          <label for="image-click" [ngStyle]="{ backgroundColor: 'white' }">
            <img src="{{ ticket.AttachmentUrl }}" width="350" height="290" />
          </label>
          <button
            id="image-click"
            onclick="myFunction()"
            class="visuallyhidden"
          ></button>
        </nz-timeline-item>
      </div>
      <div *ngIf="ticket.Action.AutoDose">
        <nz-timeline-item [nzDot]="autodoseTemplate" nzColor="blue">
          <h4>
            {{ ticket.Description }} ml of {{ ticket.Title }}
            {{ "wasDosedOn" | translate }} ({{
              ticket.Timestamp.seconds * 1000 | date: "medium"
            }})
          </h4>
          <h4>{{ ticket.Title }}</h4>
          <p>{{ ticket.Description }} {{ "ml" | translate }}</p>
        </nz-timeline-item>
      </div>
    </div>
    <ng-template #dotTemplate>
      <i nz-icon nzType="clock-circle-o" style="font-size: 21px"></i>
    </ng-template>
    <ng-template #deleteTemplate>
      <i nz-icon nzType="delete" nzTheme="outline"></i>
    </ng-template>
    <ng-template #doneTemplate>
      <i
        nz-icon
        nzType="check-circle"
        nzTheme="outline"
        style="font-size: 21px"
      ></i>
    </ng-template>
    <ng-template #assignTaskTemplate>
      <i
        nz-icon
        nzType="schedule"
        nzTheme="outline"
        style="font-size: 21px"
      ></i>
    </ng-template>
    <ng-template #attachedPhotoTemplate>
      <i
        nz-icon
        nzType="file-image"
        nzTheme="outline"
        style="font-size: 21px"
      ></i>
    </ng-template>
    <ng-template #autodoseTemplate>
      <i
        nz-icon
        nzType="bg-colors"
        nzTheme="outline"
        style="font-size: 21px"
      ></i>
    </ng-template>
  </nz-timeline>
</div>
<ng-template #editTemplate>
  <i nz-icon nzType="edit" nzTheme="outline" style="font-size: 21px"></i>
</ng-template>
<ng-template #tplTitle>
  <span>{{ "comment" | translate }}</span>
</ng-template>
<ng-template #tplContent>
  <form nz-form [formGroup]="dialogFormGroup" class="dialog-form">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "title" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Title is required">
        <input
          type="text"
          nz-input
          formControlName="titleCtrl"
          placeholder="Title"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24">{{
        "description" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="Description is required"
      >
        <textarea
          nz-input
          formControlName="descriptionCtrl"
          placeholder="Description"
          [nzAutosize]="{ minRows: 4 }"
        ></textarea>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>
