<div fxLayout="row wrap" fxLayoutAlign="end stretch">
  <button
    *ngIf="self.Role.Admin || self.Role.Owner"
    nz-button
    nzType="primary"
    (click)="editDialog(tplTitle, tplContent, null)"
  >
    <i nz-icon nzType="plus" nzTheme="outline"></i>
    {{ "recipeSideMenu" | translate }}
  </button>
</div>

<br />
<nz-table
  nzSize="middle"
  [nzData]="recipe$ | async"
  [nzLoading]="!(recipe$ | async)"
>
  <thead>
    <tr>
      <th>{{ "name" | translate }}</th>
      <th>{{ "phRange" | translate }}</th>
      <th>{{ "ecRange" | translate }}</th>
      <th *ngIf="self.Role.Worker"></th>
      <th *ngIf="self.Role.Admin || self.Role.Owner">
        {{ "action" | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of recipe$ | async">
      <td>{{ item.Name }}</td>
      <td>
        <nz-tag [nzColor]="'#f50'"
          >{{ "min" | translate }}: {{ item.pH.Min }}</nz-tag
        >
        <nz-tag [nzColor]="'#2db7f5'"
          >{{ "max" | translate }}: {{ item.pH.Max }}</nz-tag
        >
        <nz-tag [nzColor]="'#87d068'"
          >{{ "optimal" | translate }}: {{ item.pH.Optimal }}</nz-tag
        >
      </td>
      <td>
        <nz-tag [nzColor]="'#f50'"
          >{{ "min" | translate }}: {{ item.EC.Min }}</nz-tag
        >
        <nz-tag [nzColor]="'#2db7f5'"
          >{{ "max" | translate }}: {{ item.EC.Max }}</nz-tag
        >
        <nz-tag [nzColor]="'#87d068'"
          >{{ "optimal" | translate }}: {{ item.EC.Optimal }}</nz-tag
        >
      </td>
      <td style="width: 90px">
        <button
          *ngIf="self.Role.Admin || self.Role.Owner"
          nz-button
          nzType="default"
          nzShape="circle"
          (click)="editDialog(tplTitle, tplContent, item)"
        >
          <i nz-icon nzType="edit"></i></button
        >&nbsp;
        <button
          *ngIf="self.Role.Admin || self.Role.Owner"
          nz-button
          nzType="danger"
          nzShape="circle"
          (click)="deleteDialog(item)"
        >
          <i nz-icon nzType="delete"></i>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>

<ng-template #tplTitle>
  <span>{{ "manageRecipe" | translate }}</span>
</ng-template>
<ng-template #tplContent>
  <form nz-form [formGroup]="dialogFormGroup" class="dialog-form">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "name" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Name is required">
        <input
          type="text"
          nz-input
          autocomplete="off"
          required
          formControlName="nameCtrl"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "phMin" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="pH min is required">
        <input
          type="number"
          nz-input
          autocomplete="off"
          required
          formControlName="pHMinCtrl"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "phMax" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="pH max is required and must be more than 0"
      >
        <input
          type="number"
          nz-input
          autocomplete="off"
          required
          formControlName="pHMaxCtrl"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "phOptimal" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="pH optimal is required"
      >
        <input
          type="number"
          nz-input
          autocomplete="off"
          required
          formControlName="pHOptimalCtrl"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "ecMin" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="EC min is required">
        <input
          type="number"
          nz-input
          autocomplete="off"
          required
          formControlName="ECMinCtrl"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "ecMax" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="EC max is required and must be more than 0"
      >
        <input
          type="number"
          nz-input
          autocomplete="off"
          required
          formControlName="ECMaxCtrl"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "ecOptimal" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="EC optimal is required"
      >
        <input
          type="number"
          nz-input
          autocomplete="off"
          required
          formControlName="ECOptimalCtrl"
        />
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>
