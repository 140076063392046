import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { UserService } from "./user.service";
import { Camera } from "../../../../models/camera";
import { Observable } from "rxjs";
import { CropService } from "./crop.service";
import firebase from "firebase/app";
import { Crop } from "../../../../models/crop";

@Injectable({
  providedIn: "root",
})
export class CameraService {
  public collection: AngularFirestoreCollection<Camera>;

  constructor(
    private db: AngularFirestore,
    private userService: UserService,
    private cropService: CropService
  ) {
    this.collection = this.db.collection<Camera>("cameras", (ref) => {
      const c1 = ref.where(
        "Company.Id",
        "==",
        this.userService.self.Company.Id
      );
      return c1;
    });
  }

  getList(): Observable<Array<Camera>> {
    const list$ = this.db
      .collection<Camera>("cameras", (ref) => {
        const c1 = ref.where(
          "Company.Id",
          "==",
          this.userService.self.Company.Id
        );
        return c1;
      })
      .valueChanges({ idField: "Id" });
    return list$;
  }

  get(Id: string): Observable<firebase.firestore.DocumentSnapshot<Camera>> {
    return this.db.collection<Camera>("cameras").doc(Id).get();
  }

  getCrop(
    cropId: string
  ): Observable<firebase.firestore.DocumentSnapshot<Crop>> {
    const crop = this.cropService.get(cropId);
    return crop;
  }

  async add(data: Camera): Promise<void> {
    data.Company = this.userService.self.Company;
    const docRef = await this.collection.add(data);
    data.Id = docRef.id;
    this.edit(data);
  }

  edit(data: Camera): void {
    data.Company = this.userService.self.Company;
    this.collection.doc(data.Id).update(data);
  }

  delete(id: string): void {
    this.collection.doc(id).delete();
  }
}
