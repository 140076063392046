<div *ngIf="self && self.Role.Admin">
  <div fxLayout="row wrap" fxLayoutAlign="end stretch">
    <button
      nz-button
      nzType="primary"
      (click)="editDialog(tplTitle, tplContent, null)"
    >
      <i nz-icon nzType="plus" nzTheme="outline"></i> {{ "user" | translate }}
    </button>
  </div>

  <br />
  <nz-table
    nzSize="middle"
    #allUsers
    [nzData]="users$ | async"
    [nzLoading]="!(users$ | async)"
  >
    <thead>
      <tr>
        <th>{{ "name" | translate }}</th>
        <th>{{ "email" | translate }}</th>
        <th>{{ "company" | translate }}</th>
        <th>{{ "dashboardType" | translate }}</th>
        <th>{{ "policy" | translate }}</th>
        <th>{{ "action" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of allUsers.data">
        <td>{{ item.Name }}</td>
        <td>{{ item.Email }}</td>
        <td>{{ item.Company.Name }}</td>
        <td>
          <span *ngIf="item.Dashboard.Indoor">{{ "indoor" | translate }} </span>
          <span *ngIf="item.Dashboard.Outdoor"
            >{{ "outdoor" | translate }} </span
          ><span *ngIf="item.Dashboard.Fertigation"
            >{{ "fertigation" | translate }}
          </span>
        </td>
        <td>
          <span *ngIf="item.Role.Admin">{{ "admin" | translate }}</span>
          <span *ngIf="item.Role.Worker && !item.Role.Admin">{{
            "worker" | translate
          }}</span
          ><span *ngIf="item.Role.Owner && !item.Role.Admin">{{
            "owner" | translate
          }}</span>
        </td>
        <td style="width: 90px">
          <button
            nz-button
            nzType="default"
            nzShape="circle"
            (click)="editDialog(tplTitle, tplContent, item)"
          >
            <i nz-icon nzType="edit"></i></button
          >&nbsp;
          <button
            nz-button
            nzType="danger"
            nzShape="circle"
            (click)="deleteDialog(item)"
          >
            <i nz-icon nzType="delete"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>

  <ng-template #tplTitle>
    <span>{{ "manageUser" | translate }}</span>
  </ng-template>

  <ng-template #tplContent>
    <form nz-form [formGroup]="dialogFormGroup">
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
          "email" | translate
        }}</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Email is required">
          <nz-input-group>
            <input
              type="text"
              nz-input
              formControlName="emailCtrl"
              placeholder="{{ 'email' | translate }}"
              autocomplete="off"
              maxlength="255"
              required
              email
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
          "name" | translate
        }}</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Name is required">
          <input
            type="text"
            nz-input
            formControlName="nameCtrl"
            placeholder="{{ 'name' | translate }}"
            autocomplete="off"
            maxlength="255"
            required
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
          "company" | translate
        }}</nz-form-label>
        <nz-form-control
          [nzSm]="14"
          [nzXs]="24"
          nzErrorTip="Company is required"
        >
          <nz-select
            nzPlaceHolder="{{ 'company' | translate }}"
            formControlName="companyCtrl"
          >
            <nz-option nzLabel="None"></nz-option>
            <nz-option
              *ngFor="let company of company$ | async"
              [nzValue]="company.Id"
              [nzLabel]="company.Name"
            >
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
          "dashboardSideMenu" | translate
        }}</nz-form-label>
        <nz-form-control
          [nzSm]="14"
          [nzXs]="24"
          nzErrorTip="Dashboard Type is required"
        >
          <nz-radio-group
            formControlName="dashboardCtrl"
            [nzButtonStyle]="'solid'"
          >
            <label nz-radio-button nzValue="indoor">{{
              "indoor" | translate
            }}</label>
            <label nz-radio-button nzValue="outdoor">{{
              "outdoor" | translate
            }}</label>
            <label nz-radio-button nzValue="fertigation">{{
              "fertigation" | translate
            }}</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
          "role" | translate
        }}</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Role is required">
          <nz-radio-group formControlName="roleCtrl" [nzButtonStyle]="'solid'">
            <label nz-radio-button nzValue="admin">{{
              "admin" | translate
            }}</label>
            <label nz-radio-button nzValue="owner">{{
              "owner" | translate
            }}</label>
            <label nz-radio-button nzValue="worker">{{
              "worker" | translate
            }}</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-template>
</div>
<div *ngIf="!(self && self.Role.Admin)">
  <h2>You are not authorized!</h2>
</div>
