import { Injectable } from "@angular/core";
import { User } from "../../../../models/user";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { catchError, map, switchMap } from "rxjs/operators";
import { Observable, of, throwError } from "rxjs";
import { leftJoin } from "../models/docJoin";
import { AuthenticateService } from "./authenticate.service";
import { HttpHeaders, HttpClient, HttpResponse } from "@angular/common/http";

import firebase from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public self$: Observable<User>;
  public self: User;
  public collection: AngularFirestoreCollection<User>;

  constructor(
    private db: AngularFirestore,
    private authService: AuthenticateService,
    private http: HttpClient,
    private afAuth: AngularFireAuth
  ) {
    this.collection = this.db.collection<User>("users", (ref) => {
      const c1 = ref.orderBy("Company.Id");
      const c2 = c1.orderBy("Name");
      return c2;
    });

    this.self$ = afAuth.user.pipe(
      switchMap((user) => {
        // console.log(`user is now: ${JSON.stringify(user)}`);
        if (user !== null) {
          // console.log(user);
          return this.getCurrentUser(user.email);
        } else {
          console.log(`user is null..`);
          return of(null);
        }
      })
    );
    this.self$.subscribe((self) => (this.self = self));
  }

  getCurrentUser(email: string): Observable<User> {
    return this.db
      .collection<User>("users", (ref) => ref.where("Email", "==", email))
      .valueChanges({ idField: "Id" })
      .pipe(map((x) => x[0]));
  }

  getList(): Observable<Array<User>> {
    const list$ = this.collection.valueChanges({ idField: "Id" });
    const j1$ = list$.pipe(leftJoin(this.db, "Company", "Id", "companies"));
    return j1$ as Observable<Array<User>>;
  }

  listOwnCompanyUsers(): Observable<User[]> {
    return this.db
      .collection<User>("users", (ref) => {
        return ref
          .where("Company.Id", "==", this.self.Company.Id)
          .orderBy("Name");
      })
      .valueChanges({ idField: "Id" })
      .pipe(
        catchError((err) => {
          console.error(err);
          return throwError(err);
        })
      );
  }

  async add(data: User): Promise<void> {
    try {
      await this.cloudFunctionCreateUser(data);
      console.log("created user");
    } catch (ex) {
      console.log(ex);
      const errorJson = JSON.parse(ex.error);
      // Format of ex.error is
      // {"code":"auth/email-already-exists","message":"The email address is already in use by another account."}.
      throw errorJson;
    }

    return await this.authService.reset(data.Email);
  }

  edit(data: User): Promise<void> {
    console.log("Updating " + JSON.stringify(data));
    return this.collection.doc(data.Id).update(data);
  }

  async delete(id: string, email: string): Promise<boolean> {
    this.collection.doc(id).delete();
    return await this.cloudFunctionRemoveUser(email);
  }

  getCurrentUserToken(): Promise<string> {
    return firebase.auth().currentUser.getIdToken(true);
  }

  async cloudFunctionCreateUser(user: User): Promise<HttpResponse<string>> {
    const data = { user: user, uid: firebase.auth().currentUser.uid };
    const authToken = await this.getCurrentUserToken();
    const headers = new HttpHeaders({ Authorization: "Bearer " + authToken });

    console.log("Creating user " + JSON.stringify(data));

    return this.http
      .post(
        "https://us-central1-plantos-development-225209.cloudfunctions.net/createUser",
        { data: data },
        { headers: headers, observe: "response", responseType: "text" }
      )
      .toPromise();
  }

  async cloudFunctionRemoveUser(email: string): Promise<boolean> {
    const data = { email: email, uid: firebase.auth().currentUser.uid };
    const authToken = await this.getCurrentUserToken();
    const headers = new HttpHeaders({ Authorization: "Bearer " + authToken });
    return this.http
      .post(
        "https://us-central1-plantos-development-225209.cloudfunctions.net/removeUser",
        { data: data },
        { headers: headers, observe: "response" }
      )
      .pipe(
        map((response) => {
          return response.status === 200;
        })
      )
      .toPromise();
  }
}
