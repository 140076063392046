import { Component, OnInit } from "@angular/core";
import { AuthenticateService } from "../services/authenticate.service";
import { UserService } from "../services/user.service";
import { User } from "../../../../models/user";
import { ToolbarService } from "../services/toolbar.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit {
  public mobileQuery: MediaQueryList;
  public HeaderText: string;
  public self: User;
  public contributor = false;
  public companyUser: User;
  public isCollapsed = false;
  public isReverseArrow = false;
  public width = 200;
  public headerText = "";
  public hexText = "";
  public hexBackground = "";
  public hexStyle;

  constructor(
    private authenticateService: AuthenticateService,
    private userService: UserService,
    private toolbar: ToolbarService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userService.self$.subscribe((self) => (this.self = self));
    this.toolbar.header.subscribe((message) => (this.headerText = message));
  }

  signOut(): void {
    this.authenticateService.logout().then(() => this.navigateToLogin());
  }

  navigateToLogin(): void {
    this.router.navigate(["/"]);
  }
}
