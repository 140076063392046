<div fxLayout="column" fxLayoutAlign="center center" class="container">
  <nz-card class="login-card">
    <div class="login-card">
      <div fxLayout="column" fxLayoutAlign="center center">
        <div id="login-div">
          <img class="login-icon bottom" src="./assets/secondary-logo.svg" />
        </div>
        <br />
        <div>
          <p style="text-align: center">
            Fill in your email that was used to login plantOS.
          </p>
          <form nz-form [formGroup]="formGroup" class="login-form">
            <nz-form-item>
              <nz-form-control>
                <nz-input-group [nzPrefix]="prefixUser">
                  <input
                    formControlName="emailCtrl"
                    nz-input
                    maxlength="255"
                    placeholder="Email"
                    autocomplete="off"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </form>
          <ng-template #prefixUser><i nz-icon nzType="user"></i></ng-template>
          <div class="button-layout">
            <button
              nz-button
              nzType="primary"
              (click)="resetPassword()"
              [disabled]="formGroup.invalid"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </nz-card>
</div>
