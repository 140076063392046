import { AngularFirestore } from "@angular/fire/firestore";
import { switchMap, map } from "rxjs/operators";
import { combineLatest, defer, of } from "rxjs";

export const leftJoin = (
  afs: AngularFirestore,
  field: string,
  fieldRef: string,
  collection: string,
  limit = 100
) => {
  return (source) =>
    defer(() => {
      // Operator state
      let collectionData;

      return source.pipe(
        switchMap((data) => {
          // Clear mapping on each emitted val ;
          // Save the parent data state
          collectionData = data as any[];

          const reads$ = [];
          for (const doc of collectionData) {
            // Push doc read to Array
            if (doc[field].Id) {
              // Perform query on join key, with optional limit
              const q = (ref) =>
                ref.where(fieldRef, "==", doc[field].Id).limit(limit);
              reads$.push(afs.collection(collection, q).valueChanges());
            } else {
              reads$.push(of([]));
            }
          }

          return combineLatest(reads$);
        }),
        map((joins) => {
          return collectionData.map((v, i) => {
            return { ...v, [field]: joins[i][0] || null };
          });
        })
      );
    });
};
