<div fxLayout="row wrap" fxLayoutAlign="end stretch">
  <button
    nz-button
    nzType="primary"
    (click)="editDialog(tplTitle, tplContent, null)"
  >
    <i nz-icon nzType="plus" nzTheme="outline"></i> {{ "company" | translate }}
  </button>
</div>

<br />
<nz-table
  nzSize="middle"
  [nzData]="company$ | async"
  [nzLoading]="!(company$ | async)"
>
  <thead>
    <tr>
      <th>{{ "registryId" | translate }}</th>
      <th>{{ "company" | translate }}</th>
      <th>{{ "country" | translate }}</th>
      <th>{{ "action" | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of company$ | async">
      <td>{{ item.RegistryId }}</td>
      <td>{{ item.Name }}</td>
      <td>{{ item.Country }}</td>
      <td style="width: 90px">
        <button
          nz-button
          nzType="default"
          nzShape="circle"
          (click)="editDialog(tplTitle, tplContent, item)"
        >
          <i nz-icon nzType="edit"></i></button
        >&nbsp;
        <button
          nz-button
          nzType="danger"
          nzShape="circle"
          (click)="deleteDialog(item)"
        >
          <i nz-icon nzType="delete"></i>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>

<ng-template #tplTitle>
  <span>{{ "manageCompany" | translate }}</span>
</ng-template>

<ng-template #tplContent>
  <form nz-form [formGroup]="dialogFormGroup">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "registryId" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="Registry ID is required"
      >
        <input
          type="text"
          nz-input
          formControlName="registryIdCtrl"
          placeholder="{{ 'registryId' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "name" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="Company name is required"
      >
        <input
          type="text"
          nz-input
          formControlName="companyNameCtrl"
          placeholder="{{ 'name' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "address1" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="Address 1 is required"
      >
        <input
          type="text"
          nz-input
          formControlName="address1Ctrl"
          placeholder="{{ 'address1' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24">{{
        "address2" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <input
          type="text"
          nz-input
          formControlName="address2Ctrl"
          placeholder="{{ 'address2' | translate }}"
          autocomplete="off"
          maxlength="255"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "city" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="City is required">
        <input
          type="text"
          nz-input
          formControlName="cityCtrl"
          placeholder="{{ 'city' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "state" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="State is required">
        <input
          type="text"
          nz-input
          formControlName="stateCtrl"
          placeholder="{{ 'state' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "postcode" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="Postcode is required"
      >
        <input
          type="text"
          nz-input
          formControlName="postcodeCtrl"
          placeholder="{{ 'postcode' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>{{
        "country" | translate
      }}</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Country is required">
        <input
          type="text"
          nz-input
          formControlName="countryCtrl"
          placeholder="{{ 'country' | translate }}"
          autocomplete="off"
          maxlength="255"
          required
        />
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>
