import { Component, OnInit } from "@angular/core";
import { ToolbarService } from "src/app/services/toolbar.service";
import { trigger, transition, style, animate } from "@angular/animations";
import { FormControl } from "@angular/forms";
import { DeviceService } from "src/app/services/device.service";
import { Device } from "../../../../../models/device";
import { Attachment } from "src/app/models/attachment";
import { User as DashboardUser } from "../../../../../models/user";
import { UserService } from "src/app/services/user.service";
import { ActivatedRoute } from "@angular/router";
import moment from "moment";

@Component({
  selector: "app-dashboard",
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({ transform: "translateY(100%)", opacity: 0 }),
        animate("500ms", style({ transform: "translateY(0)", opacity: 1 })),
      ]),
      transition(":leave", [
        style({ transform: "translateY(0)", opacity: 1 }),
        animate("500ms", style({ transform: "translateY(100%)", opacity: 0 })),
      ]),
    ]),
  ],
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  public startDate: FormControl;
  public endDate: FormControl;
  public deviceList: Device[] = [];
  public imageList: Device[] = [];
  public deviceIdSelected: string;
  public imagingList: Attachment[] = [];
  public offset = moment().utcOffset();
  public loadCard = true;
  public self: DashboardUser;

  constructor(
    private toolbar: ToolbarService,
    private userService: UserService,
    private deviceService: DeviceService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.deviceService.getActiveDevices(params.cropId).subscribe((list) => {
        const today = moment();
        const sevenDays = moment().subtract(14, "days");
        this.self = this.userService.self;
        this.deviceList = list;

        // Mark the devices as loaded - if empty we'll show the empty state message.
        this.loadCard = false;

        list.forEach(() => {
          this.startDate = new FormControl(sevenDays.toDate());
          this.endDate = new FormControl(today.toDate());
        });
      });
    });
    this.startDate = new FormControl(new Date());
    this.endDate = new FormControl(new Date());
    this.toolbar.changeHeader("Dashboard: IoT Monitor");
  }

  isDeviceDead(device: Device): boolean {
    return !device.Connected;
  }
}
