<form nz-form [formGroup]="formGroup" nzLayout="inline">
  <nz-form-item>
    <nz-form-label>{{ "period" | translate }}</nz-form-label>
    <nz-form-control>
      <nz-input-group nzCompact>
        <nz-select
          formControlName="monthCtrl"
          nzPlaceHolder="{{ 'month' | translate }}"
          nzAllowClear
        >
          <nz-option nzLabel="Jan" nzValue="1"></nz-option>
          <nz-option nzLabel="Feb" nzValue="2"></nz-option>
          <nz-option nzLabel="Mar" nzValue="3"></nz-option>
          <nz-option nzLabel="Apr" nzValue="4"></nz-option>
          <nz-option nzLabel="May" nzValue="5"></nz-option>
          <nz-option nzLabel="Jun" nzValue="6"></nz-option>
          <nz-option nzLabel="Jul" nzValue="7"></nz-option>
          <nz-option nzLabel="Aug" nzValue="8"></nz-option>
          <nz-option nzLabel="Sep" nzValue="9"></nz-option>
          <nz-option nzLabel="Oct" nzValue="10"></nz-option>
          <nz-option nzLabel="Nov" nzValue="11"></nz-option>
          <nz-option nzLabel="Dec" nzValue="12"></nz-option>
        </nz-select>
        <nz-select
          formControlName="yearCtrl"
          nzPlaceHolder="{{ 'year' | translate }}"
          nzAllowClear
        >
          <nz-option
            *ngFor="let i of years"
            [nzLabel]="i"
            [nzValue]="i"
          ></nz-option>
        </nz-select>
        <button nz-button nzType="primary" nzSearch (click)="search()">
          <i nz-icon nzType="search"></i>
        </button>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
</form>
<br />
<div fxLayout="row wrap" fxLayoutAlign="start stretch">
  <div fxFlex="30" fxFlex.lt-md="100" class="summaryCard">
    <ul nz-list nzBordered nzSize="large" [nzHeader]="summaryHeader">
      <li
        nz-list-item
        [nzActions]="[summaryRevenue]"
        nzContent="{{ 'revenue' | translate }}"
        nzNoFlex
      ></li>
      <ng-template #summaryRevenue>{{
        revenueAmount$ | async | number: "1.2-2"
      }}</ng-template>
      <li
        nz-list-item
        [nzActions]="[summaryExpense]"
        nzContent="{{ 'expenses' | translate }}"
        nzNoFlex
      ></li>
      <ng-template #summaryExpense>{{
        expenseAmount$ | async | number: "1.2-2"
      }}</ng-template>
      <li
        nz-list-item
        [nzActions]="[summaryProfitLoss]"
        nzContent="{{ 'totalProfitLoss' | translate }}"
        nzNoFlex
      ></li>
      <ng-template #summaryProfitLoss>
        <b>{{ profitloss$ | async | number: "1.2-2" }}</b>
      </ng-template>
    </ul>
    <ng-template #summaryHeader>
      <b>{{ "summarySideMenu" | translate }}</b>
    </ng-template>
  </div>

  <div fxFlex="30" fxFlex.lt-md="100" class="summaryCard">
    <ul nz-list nzBordered nzSize="large" [nzHeader]="revenueHeader">
      <li
        nz-list-item
        [nzActions]="[yield]"
        nzContent="{{ 'yield' | translate }}"
        nzNoFlex
      ></li>
      <ng-template #yield>{{ yield$ | async | number: "1.2-2" }}</ng-template>
      <li
        nz-list-item
        [nzActions]="[averagePrice]"
        nzContent="{{ 'averagePrice' | translate }}"
        nzNoFlex
      ></li>
      <ng-template #averagePrice>{{
        averagePrice$ | async | number: "1.2-2"
      }}</ng-template>
      <li
        nz-list-item
        [nzActions]="[cropRevenue]"
        nzContent="{{ 'cropRevenue' | translate }}"
        nzNoFlex
      ></li>
      <ng-template #cropRevenue>{{
        cropRevenue$ | async | number: "1.2-2"
      }}</ng-template>
      <li
        nz-list-item
        [nzActions]="[otherRevenue]"
        nzContent="{{ 'otherRevenue' | translate }}"
        nzNoFlex
      ></li>
      <ng-template #otherRevenue>{{
        otherRevenue$ | async | number: "1.2-2"
      }}</ng-template>
      <li
        nz-list-item
        [nzActions]="[revenueAmount]"
        nzContent="{{ 'total' | translate }}"
        nzNoFlex
      ></li>
      <ng-template #revenueAmount>
        <b>{{ revenueAmount$ | async | number: "1.2-2" }}</b>
      </ng-template>
    </ul>
    <ng-template #revenueHeader>
      <b>{{ "revenue" | translate }}</b>
    </ng-template>
  </div>

  <div fxFlex="30" fxFlex.lt-md="100" class="summaryCard">
    <ul
      nz-list
      [nzDataSource]="expenseList$ | async"
      nzBordered
      nzSize="large"
      [nzHeader]="expensesHeader"
      [nzRenderItem]="largeItem"
    >
      <ng-template #largeItem let-item>
        <li
          nz-list-item
          [nzActions]="[opAction]"
          nzContent="{{ item.Name }} @ {{
            item.Date.toDate() | date: 'mediumDate'
          }}"
          nzNoFlex
        ></li>
        <ng-template #opAction>{{ item.Amount | number: "1.2-2" }}</ng-template>
      </ng-template>
      <li
        style="border-top: 1px solid #e8e8e8"
        nz-list-item
        [nzActions]="[expenseAmount]"
        nzContent="{{ 'total' | translate }}"
        nzNoFlex
      ></li>
    </ul>
    <ng-template #expenseAmount>
      <b>{{ expenseAmount$ | async | number: "1.2-2" }}</b>
    </ng-template>
    <ng-template #expensesHeader>
      <b>{{ "expenses" | translate }}</b>
    </ng-template>
  </div>
</div>
