import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { Task } from "../../../../models/task";
import {
  AngularFirestoreCollection,
  AngularFirestore,
} from "@angular/fire/firestore";
import { UserService } from "./user.service";
import { User } from "../../../../models/user";
import { Ticket, Action } from "../../../../models/ticket";

import moment from "moment";

import firebase from "firebase/app";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TaskService {
  public collection: AngularFirestoreCollection<Task>;
  public ticketCollection: AngularFirestoreCollection<Ticket>;
  constructor(private db: AngularFirestore, private userService: UserService) {
    this.collection = this.db.collection<Task>("tasks", (ref) => {
      const c1 = ref.where(
        "Company.Id",
        "==",
        this.userService.self.Company.Id
      );
      const c2 = c1.orderBy("TaskDate", "asc");
      return c2;
    });

    this.ticketCollection = this.db.collection<Ticket>("tickets", (ref) => {
      const c1 = ref.where(
        "Company.Id",
        "==",
        this.userService.self.Company.Id
      );
      const c2 = c1.orderBy("Timestamp", "desc");
      return c2;
    });
  }

  public getToday(): Observable<Array<Task>> {
    const d1 = moment().set({ hour: 0, minute: 0, second: 0 });
    const d2 = moment().set({ hour: 23, minute: 59, second: 59 });

    const list$ = this.db
      .collection<Task>("tasks", (ref) => {
        const c1 = ref.where(
          "Company.Id",
          "==",
          this.userService.self.Company.Id
        );
        const c2 = c1.where("TaskDate", ">=", d1.toDate());
        const c3 = c2
          .where("TaskDate", "<=", d2.toDate())
          .orderBy("TaskDate", "asc");
        return c3;
      })
      .valueChanges({ idField: "Id" });

    return list$.pipe(
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  public getPastIncomplete(): Observable<Array<Task>> {
    const d1 = moment().set({ hour: 0, minute: 0, second: 0 });

    const list$ = this.db
      .collection<Task>("tasks", (ref) => {
        const c1 = ref.where(
          "Company.Id",
          "==",
          this.userService.self.Company.Id
        );
        const c2 = c1.where("TaskDate", "<", d1.toDate());
        const c3 = c2
          .where("isCompleted", "==", false)
          .orderBy("TaskDate", "asc");
        return c3;
      })
      .valueChanges({ idField: "Id" });
    return list$.pipe(
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  public getList(): Observable<Array<Task>> {
    return this.collection.valueChanges({ idField: "Id" }).pipe(
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  public getIncomplete(): Observable<Array<Task>> {
    return this.db
      .collection<Task>("tasks", (ref) => {
        return ref
          .where("Company.Id", "==", this.userService.self.Company.Id)
          .where("isCompleted", "==", false)
          .orderBy("TaskDate", "asc");
      })
      .valueChanges({ idField: "Id" })
      .pipe(
        catchError((err) => {
          console.error(err);
          return throwError(err);
        })
      );
  }

  public getCompleted(): Observable<Array<Task>> {
    return this.db
      .collection<Task>("tasks", (ref) => {
        return ref
          .where("Company.Id", "==", this.userService.self.Company.Id)
          .where("isCompleted", "==", true)
          .orderBy("TaskDate", "asc");
      })
      .valueChanges({ idField: "Id" })
      .pipe(
        catchError((err) => {
          console.error(err);
          return throwError(err);
        })
      );
  }

  async add(data: Task): Promise<void> {
    const ticket = {
      Title: data.Name,
      Description: data.Notes,
      Timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
      Task: data,
      User: this.userService.self,
      Action: {
        Comment: false,
        CompleteTask: data.isCompleted,
        AssignedTask: !data.isCompleted,
      } as Action,
      CropId: data.CropId,
      Id: null,
    } as Ticket;
    data.Company = this.userService.self.Company;
    const savedTicket = await this.ticketCollection.add(ticket);
    await this.ticketCollection
      .doc(savedTicket.id)
      .update({ Id: savedTicket.id });
    await this.collection.add(data);
  }

  addList(data: Array<Task>): void {
    // Store company information first.
    data.forEach((itm) => {
      itm.Company = this.userService.self.Company;
    });

    // Parent Record
    const parent = data[0];

    // Child Record
    data.shift();
    const child = [...data];

    // Add Parent & Child Record
    this.collection.add(parent).then((x) => {
      child.forEach(async (element) => {
        element.ParentId = x.id;
        this.collection.add(element);
        const ticket = {
          Id: null,
          Title: element.Name,
          Description: element.Notes,
          Task: element,
          Timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
          User: this.userService.self,
          Action: {
            Comment: false,
            CompleteTask: element.isCompleted,
            AssignedTask: !element.isCompleted,
          } as Action,
          CropId: element.CropId,
        } as Ticket;
        const savedTicket = await this.ticketCollection.add(ticket);
        this.ticketCollection
          .doc(savedTicket.id)
          .update({ Id: savedTicket.id });
      });
    });
  }

  async updateCompleted(data: Task): Promise<void> {
    data.Company = this.userService.self.Company;
    const ticket = {
      Id: null,
      Title: data.Name,
      Description: data.Notes,
      Timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
      Task: data,
      User: this.userService.self,
      Action: {
        Comment: false,
        CompleteTask: data.isCompleted,
        AssignedTask: !data.isCompleted,
      } as Action,
      CropId: data.CropId,
    } as Ticket;
    const savedTicket = await this.ticketCollection.add(ticket);
    this.ticketCollection.doc(savedTicket.id).update({ Id: savedTicket.id });
    this.collection.doc(data.Id).update(data);
  }

  updateAllCompleted(data: Task[], value: boolean, completedBy: User): void {
    data.forEach(async (x) => {
      if (value === true) {
        x.Company = this.userService.self.Company;
        x.isCompleted = value;
        x.CompletedDate = new Date();
        x.CompletedBy = completedBy.Id;
        const ticket = {
          Id: null,
          Title: x.Name,
          Description: x.Notes,
          Task: x,
          Timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
          User: this.userService.self,
          Action: {
            Comment: false,
            CompleteTask: x.isCompleted,
            AssignedTask: !x.isCompleted,
          } as Action,
          CropId: x.CropId,
        } as Ticket;
        const savedTicket = await this.ticketCollection.add(ticket);
        this.ticketCollection
          .doc(savedTicket.id)
          .update({ Id: savedTicket.id });
      } else {
        x.Company = this.userService.self.Company;
        x.isCompleted = value;
        x.CompletedDate = null;
        x.CompletedBy = null;
        const ticket = {
          Id: null,
          Title: x.Name,
          Description: x.Notes,
          Task: x,
          Timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
          User: this.userService.self,
          Action: {
            Comment: false,
            CompleteTask: x.isCompleted,
            AssignedTask: !x.isCompleted,
          } as Action,
          CropId: x.CropId,
        } as Ticket;
        const savedTicket = await this.ticketCollection.add(ticket);
        this.ticketCollection
          .doc(savedTicket.id)
          .update({ Id: savedTicket.id });
      }
      this.collection.doc(x.Id).update(x);
    });
  }

  async edit(data: Task): Promise<void> {
    data.Company = this.userService.self.Company;
    data.CompletedBy = this.userService.self.Id;
    const ticket = {
      Id: data.Id,
      Title: data.Name,
      Description: data.Notes,
      Task: data,
      Timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
      User: this.userService.self,
      Action: {
        Comment: false,
        CompleteTask: data.isCompleted,
        AssignedTask: !data.isCompleted,
      } as Action,
      CropId: data.CropId,
    } as Ticket;
    this.ticketCollection.add(ticket);
    const savedTicket = await this.ticketCollection.add(ticket);
    this.ticketCollection.doc(savedTicket.id).update({ Id: savedTicket.id });
    this.collection.doc(data.Id).update(data);
  }

  delete(id: string): void {
    this.collection.doc(id).delete();
  }
}
