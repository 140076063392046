import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Attachment } from "../models/attachment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AttachmentService {
  public data: Attachment = new Attachment();
  constructor(private http: HttpClient) {}

  public upload(file: File): Observable<Attachment> {
    // create a new multipart-form for every file
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);

    return this.http
      .post("/api/attachments/", formData, {
        observe: "response",
        reportProgress: true,
      })
      .pipe(
        map((response) => {
          if (response.status === 200) {
            const t = response.body as any;
            return t.data as Attachment;
          }
          return null;
        })
      );
  }

  public getImaging(deviceId: string): Observable<Attachment[]> {
    return this.http
      .get("/api/attachments/imaging/normal/" + deviceId + "/0/3", {
        observe: "response",
        reportProgress: true,
      })
      .pipe(
        map((response) => {
          if (response.status === 200) {
            const t = response.body as any;
            return t.data as Attachment[];
          }
          return null;
        })
      );
  }

  public getDemoImaging(deviceId: string): Observable<Attachment[]> {
    return this.http
      .get("/api/attachments/demo/imaging/normal/" + deviceId + "/0/1", {
        observe: "response",
        reportProgress: true,
      })
      .pipe(
        map((response) => {
          if (response.status === 200) {
            const t = response.body as any;
            return t.data as Attachment[];
          }
          return null;
        })
      );
  }
}
