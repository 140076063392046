import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { AuthenticateService } from "src/app/services/authenticate.service";
import { NzMessageService } from "ng-zorro-antd";
import { takeUntil } from "rxjs/operators";
import { PasswordValidator } from "src/app/tools/password.validator";

@Component({
  selector: "app-verification",
  templateUrl: "./verification.component.html",
  styleUrls: ["./verification.component.scss"],
})
export class VerificationComponent implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  public ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticateService,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group(
      {
        emailCtrl: ["", Validators.required],
        passwordCtrl: ["", Validators.compose([Validators.required])],
        cpasswordCtrl: ["", Validators.compose([Validators.required])],
        actionCodeCtrl: [""],
      },
      {
        validator: PasswordValidator.passwordMatchValidator,
      }
    );
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        if (!params) {
          this.router.navigate(["/login"]);
        }

        const actionCode = params.oobCode;
        this.authenticationService
          .verifyPasswordResetCode(actionCode)
          .then((email) => {
            this.formGroup = this.formBuilder.group({
              emailCtrl: [email, Validators.required],
              passwordCtrl: [
                "",
                Validators.compose([
                  Validators.required,
                  Validators.minLength(8),
                  PasswordValidator.patternValidator(/\d/, {
                    hasNumber: true,
                  }),
                  // PasswordValidator.patternValidator(/[A-Z]/, {
                  //   hasCapitalCase: true
                  // }),
                  PasswordValidator.patternValidator(/[a-z]/, {
                    hasSmallCase: true,
                  }),
                  // PasswordValidator.patternValidator(
                  //   /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
                  //   hasSpecialCharacters: true
                  // }),
                ]),
              ],
              cpasswordCtrl: [
                "",
                Validators.compose([
                  Validators.required,
                  PasswordValidator.patternValidator(/\d/, {
                    hasNumber: true,
                  }),
                  // PasswordValidator.patternValidator(/[A-Z]/, {
                  //   hasCapitalCase: true
                  // }),
                  PasswordValidator.patternValidator(/[a-z]/, {
                    hasSmallCase: true,
                  }),
                  // PasswordValidator.patternValidator(
                  //   /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
                  //   hasSpecialCharacters: true
                  // }),
                ]),
              ],
              actionCodeCtrl: [actionCode],
            });
          })
          .catch((e) => {
            console.log(e);
            // TODO: Show error message to the user.
            // For example:
            // e = { code: "auth/invalid-action-code"
            //       message: "The action code is invalid. This can happen if the code is malformed, expired, or has already been used." }
            this.message.create("error", e.message);

            //this.router.navigate(["/login"]);
          });
      });
  }

  ngOnDestroy(): void {
    // End all subscriptions listening to ngUnsubscribe
    // to avoid memory leaks.
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async verify(): Promise<void> {
    const form = this.formGroup.value;
    if (form.passwordCtrl === form.cpasswordCtrl) {
      await this.authenticationService.confirmPasswordReset(
        form.actionCodeCtrl,
        form.passwordCtrl
      );
      const msg =
        "Password has been reset and now redirecting you to login page.";
      this.message.create("success", msg);
      this.router.navigate(["/login"]);
    }
  }
}
