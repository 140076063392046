import { Injectable, NgZone } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { UserService } from "./user.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class AuthenticateGuardService implements CanActivate {
  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router,
    private ngZone: NgZone
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.userService.self$.pipe(
      map((x) => {
        if (!x) {
          console.log("Not logged in, redirecting to login page.");
          this.ngZone.run(() => this.router.navigate(["/login"]));
          return false;
        }
        if (state.url.indexOf("/company") > -1 && !x.Role.Admin) {
          return false;
        }
        if (state.url.indexOf("/user") > -1 && !x.Role.Admin && !x.Role.Owner) {
          return false;
        }
        if (state.url.indexOf("/devicetype") > -1 && !x.Role.Admin) {
          return false;
        }
        if (
          state.url.indexOf("/user-company") > -1 &&
          !x.Role.Admin &&
          !x.Role.Owner
        ) {
          return false;
        }
        if (
          state.url.indexOf("/dashboard") > -1 &&
          !x.Role.Admin &&
          !x.Dashboard.Indoor
        ) {
          return false;
        }
        if (
          state.url.indexOf("/outdoor-dashboard") > -1 &&
          !x.Role.Admin &&
          !x.Dashboard.Outdoor
        ) {
          return false;
        }
        if (
          state.url.indexOf("/fertigation-dashboard") > -1 &&
          !x.Role.Admin &&
          !x.Dashboard.Fertigation
        ) {
          return false;
        }
        return true;
      })
    );
  }
}
