import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthenticateService } from "src/app/services/authenticate.service";
import { NzMessageService } from "ng-zorro-antd";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  public formGroup: FormGroup;
  public email: string;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticateService,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      emailCtrl: [this.email, Validators.required],
    });
  }

  resetPassword(): void {
    this.authenticationService.reset(this.formGroup.controls.emailCtrl.value);
    const msg =
      "Email has been sent to your email address for reset password procedure.";
    this.message.create("success", msg);
    this.router.navigate(["/login"]);
  }
}
