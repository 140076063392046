import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ToolbarService {
  private headerSource = new BehaviorSubject<string>("");
  public header = this.headerSource.asObservable();

  changeHeader(message: string): void {
    this.headerSource.next(message);
  }
}
