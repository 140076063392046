import { Component, OnInit, TemplateRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Recipe, Parameter } from "../../../../../models/recipe";
import { Company } from "../../../../../models/company";
import { NzModalService, NzMessageService, NzModalRef } from "ng-zorro-antd";
import { RecipeService } from "src/app/services/recipe.service";
import { Observable } from "rxjs";
import { ToolbarService } from "src/app/services/toolbar.service";
import { User } from "../../../../../models/user";
import { UserService } from "src/app/services/user.service";
import { Crop } from "../../../../../models/crop";
import { CropService } from "src/app/services/crop.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-recipe",
  templateUrl: "./recipe.component.html",
  styleUrls: ["./recipe.component.scss"],
})
export class RecipeComponent implements OnInit {
  public dialogFormGroup: FormGroup;
  public tplModal: NzModalRef;
  public recipe$: Observable<Recipe[]>;
  public self: User;
  public cancelTranslate: string;
  public saveTranslate: string;
  public yesTranslate: string;
  public noTranslate: string;
  public areYouSureDeletionTranslate: string;

  constructor(
    private toolbar: ToolbarService,
    private modalService: NzModalService,
    private message: NzMessageService,
    private recipeService: RecipeService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private cropService: CropService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.stream("recipeManagement").subscribe((translation) => {
      this.toolbar.changeHeader(translation);
    });
    this.translate.stream("cancel").subscribe((translation) => {
      this.cancelTranslate = translation;
    });
    this.translate.stream("save").subscribe((translation) => {
      this.saveTranslate = translation;
    });
    this.translate.stream("yes").subscribe((translation) => {
      this.yesTranslate = translation;
    });
    this.translate.stream("no").subscribe((translation) => {
      this.noTranslate = translation;
    });
    this.translate.stream("areYouSureForDelete").subscribe((translation) => {
      this.areYouSureDeletionTranslate = translation;
    });
    this.self = this.userService.self;
    this.recipe$ = this.recipeService.getList();
  }

  getCropName(cropId: string): void {
    console.log(cropId);
    this.cropService.get(cropId).subscribe((crop) => {
      return crop.data() as Crop;
    });
  }

  editDialog(
    tplTitle: TemplateRef<never>,
    tplContent: TemplateRef<never>,
    data: Recipe
  ): void {
    if (!data) {
      data = {} as Recipe;
      data.Company = {} as Company;
      data.pH = {} as Parameter;
      data.pH.Min = 5;
      data.pH.Max = 7;
      data.pH.Optimal = 6;
      data.EC = {} as Parameter;
      data.EC.Min = 0.8;
      data.EC.Max = 1.5;
      data.EC.Optimal = 1.1;
    }
    const item = { ...data };

    this.dialogFormGroup = this.formBuilder.group({
      pHMinCtrl: [item.pH.Min, Validators.required],
      pHMaxCtrl: [item.pH.Max, [Validators.required, Validators.min(1)]],
      pHOptimalCtrl: [
        item.pH.Optimal,
        [Validators.required, Validators.min(1)],
      ],
      ECMinCtrl: [item.EC.Min, Validators.required],
      ECMaxCtrl: [item.EC.Max, [Validators.required, Validators.min(0.4)]],
      ECOptimalCtrl: [
        item.EC.Optimal,
        [Validators.required, Validators.min(0.4)],
      ],
      nameCtrl: [item.Name, Validators.required],
    });

    this.tplModal = this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: [
        {
          label: this.cancelTranslate,
          type: "danger",
          onClick: () => {
            this.tplModal.destroy();
          },
        },
        {
          label: this.saveTranslate,
          type: "primary",
          disabled: () => this.dialogFormGroup.invalid,
          onClick: () => {
            const form = this.dialogFormGroup.value;
            item.pH.Min = form.pHMinCtrl;
            item.pH.Max = form.pHMaxCtrl;
            item.pH.Optimal = form.pHOptimalCtrl;
            item.EC.Min = form.ECMinCtrl;
            item.EC.Max = form.ECMaxCtrl;
            item.EC.Optimal = form.ECOptimalCtrl;
            item.Name = form.nameCtrl;

            if (!item.Id) {
              this.recipeService.add(item);
            } else {
              this.recipeService.edit(item);
            }
            this.displayResult(true, true);
            this.tplModal.destroy();
          },
        },
      ],
      nzMaskClosable: false,
      nzClosable: true,
    });
  }

  deleteDialog(item: Recipe): void {
    if (!item) {
      this.message.create("success", "Error delete item");
    } else {
      this.tplModal = this.modalService.create({
        nzTitle: this.areYouSureDeletionTranslate,
        nzContent: `Recipe ${item.Name}`,
        nzOkText: this.yesTranslate,
        nzOkType: "danger",
        nzOnOk: () => {
          this.recipeService.delete(item.Id);
          this.displayResult(true, false);
        },
        nzCancelText: this.noTranslate,
      });
    }
  }

  displayResult(response: boolean, isSave: boolean): void {
    if (response) {
      const msg = isSave
        ? "Item successfully saved"
        : "Item successfully deleted";
      this.message.create("success", msg);
    } else {
      const msg = isSave ? "Error saving item" : "Error deleting item";
      this.message.create("warning", msg);
    }
  }
}
