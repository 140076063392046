import { Component, OnInit, TemplateRef } from "@angular/core";
import { User } from "../../../../../models/user";
import { Task } from "../../../../../models/task";
import { ToolbarService } from "src/app/services/toolbar.service";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { NzModalService, NzMessageService, NzModalRef } from "ng-zorro-antd";
import { TaskService } from "src/app/services/task.service";
import { Crop } from "../../../../../models/crop";
import { Company } from "../../../../../models/company";
import { Observable, combineLatest } from "rxjs";
import {
  ONCE,
  DAILY,
  WEEKLY,
  BI_WEEKLY,
  MONTHLY,
  BI_MONTHLY,
} from "src/app/services/global-constant";
import moment from "moment";
import { UserService } from "src/app/services/user.service";
import { recurrenceTaskValidator } from "src/app/tools/task.validator";
import { map } from "rxjs/operators";
import { CropService } from "src/app/services/crop.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-task",
  templateUrl: "./task.component.html",
  styleUrls: ["./task.component.scss"],
})
export class TaskComponent implements OnInit {
  public userList: User[] = [];
  public taskList: Task[] = [];
  public cropList: Crop[] = [];
  public task: Task;
  public tplModal: NzModalRef;
  public dialogFormGroup: FormGroup;
  public crops$: Observable<Crop[]>;
  public users: User[];
  public today$: Observable<Task[]>;
  public todayTask$: Observable<Task[]>;
  public incomplete: Observable<Task[]>;

  public task$: Observable<Task[]>;
  public taskOngoing$: Observable<Task[]>;
  public taskCompleted$: Observable<Task[]>;
  public paginationIndex = 0;
  public self: User;
  public cancelTranslate: string;
  public saveTranslate: string;
  public yesTranslate: string;
  public noTranslate: string;
  public areYouSureDeletionTranslate: string;

  constructor(
    private toolbar: ToolbarService,
    private taskService: TaskService,
    private userService: UserService,
    private modalService: NzModalService,
    private message: NzMessageService,
    private formBuilder: FormBuilder,
    public cropService: CropService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.stream("taskManager").subscribe((translation) => {
      this.toolbar.changeHeader(translation);
    });
    this.translate.stream("cancel").subscribe((translation) => {
      this.cancelTranslate = translation;
    });
    this.translate.stream("save").subscribe((translation) => {
      this.saveTranslate = translation;
    });
    this.translate.stream("yes").subscribe((translation) => {
      this.yesTranslate = translation;
    });
    this.translate.stream("no").subscribe((translation) => {
      this.noTranslate = translation;
    });
    this.translate.stream("areYouSureForDelete").subscribe((translation) => {
      this.areYouSureDeletionTranslate = translation;
    });
    this.crops$ = this.cropService.getList();
    this.self = this.userService.self;
    this.today$ = combineLatest(
      this.taskService.getToday(),
      this.taskService.getPastIncomplete()
    ).pipe(
      map(([first, second]) => {
        return [...first, ...second];
      })
    );

    this.task$ = this.taskService.getList();
    this.taskOngoing$ = this.taskService.getIncomplete();
    this.taskCompleted$ = this.taskService.getCompleted();

    this.userService
      .listOwnCompanyUsers()
      .subscribe((list) => (this.users = [...list]));
  }

  refreshStatus(item: Task): void {
    if (item.isCompleted) {
      item.CompletedDate = new Date();
    } else {
      item.CompletedDate = null;
    }
    this.taskService.updateCompleted(item);
  }

  doesItContain(users: User[], user: User): boolean {
    if (users !== undefined) {
      for (const u of users) {
        if (u.Id === user.Id) {
          return true;
        }
      }
      return false;
    }
  }

  editDialog(
    tplTitle: TemplateRef<never>,
    tplContent: TemplateRef<never>,
    data: Task,
    isEdit: boolean
  ): void {
    if (!data) {
      data = {} as Task;
      data.Company = {} as Company;
    }
    const item = { ...data };
    const itemStartDate = item.StartDate as any;
    const itemEndDate = item.EndDate as any;
    const itemTaskDate = item.TaskDate as any;

    this.dialogFormGroup = this.formBuilder.group(
      {
        nameCtrl: [item.Name, Validators.required],
        zoneCtrl: [item.Zone],
        recurrenceCtrl: [
          item.Recurrence ? item.Recurrence : ONCE,
          Validators.required,
        ],
        startDateCtrl: [
          itemStartDate ? new Date(itemStartDate.seconds * 1000) : null,
        ],
        endDateCtrl: [
          itemEndDate ? new Date(itemEndDate.seconds * 1000) : null,
        ],
        taskDateCtrl: [
          itemTaskDate ? new Date(itemTaskDate.seconds * 1000) : null,
        ],
        notesCtrl: [item.Notes],
        usersCtrl: [
          item.Users ? item.Users.map((x) => x.Id) : [],
          [Validators.required, Validators.min(1)],
        ],
        basedOnCropCtrl: [item.CropId ? "no" : "yes", Validators.required],
        cropCtrl: [item.CropId],
      },
      {
        validator: recurrenceTaskValidator,
      }
    );

    this.tplModal = this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: [
        {
          label: this.cancelTranslate,
          type: "danger",
          onClick: () => {
            this.tplModal.destroy();
          },
        },
        {
          label: this.saveTranslate,
          type: "primary",
          disabled: () => this.dialogFormGroup.invalid,
          onClick: () => {
            this.onClickSave(item);
          },
        },
      ],
      nzMaskClosable: false,
      nzClosable: true,
    });
  }

  checkDate(passedDate: Date): boolean {
    // Get Range in Days
    const xx = new Date();
    const pDate = moment(passedDate);
    const today = moment(xx);
    const days = today.diff(pDate, "days");

    if (days < 0) {
      // overdue & present
      return true;
    } else {
      // future
      return false;
    }
  }

  checkDateAll(xxy: Date, item: Task): boolean {
    // Check Completed
    if (item.isCompleted === true) {
      // Is complete straight away display
      return false;
    } else {
      // Is not complete check condition date
      // Get Range in Days
      const xx = new Date();
      const pDate = moment(xxy);
      const today = moment(xx);
      const days = today.diff(pDate, "days");

      if (days < 0) {
        // overdue & present
        return false;
      } else {
        // future
        return true;
      }
    }
  }

  onClickSave(item: Task): void {
    const form = this.dialogFormGroup.value;

    item.Name = form.nameCtrl;
    item.StartDate = form.startDateCtrl;
    item.EndDate = form.endDateCtrl;
    item.TaskDate = form.taskDateCtrl;
    item.Recurrence = form.recurrenceCtrl;
    item.Zone = form.zoneCtrl;
    item.Notes = form.notesCtrl;
    item.isCompleted = false;
    item.CropId = form.cropCtrl;
    // User area
    if (form.usersCtrl) {
      item.Users = [];
      form.usersCtrl.forEach((userId) => {
        const u = this.users.find((x) => x.Id === userId);
        item.Users.push(u);
      });
    }

    if (!item.Id) {
      if (form.recurrenceCtrl === ONCE) {
        item.StartDate = null;
        item.EndDate = null;
        item.TaskDate = form.taskDateCtrl;
        this.taskService.add(item);
      } else if (form.recurrenceCtrl === DAILY) {
        // Get Range in Dayss
        const endDate = moment(item.EndDate);
        const startDate = moment(item.StartDate);
        const days = endDate.diff(startDate, "days");

        // Declare Var
        const taskList = [] as Array<Task>;

        // Loop into this to create multi-record that link to parent id
        for (let i = 0; i <= days; i++) {
          const obj = { ...item };
          obj.Name += " " + (i + 1).toString();
          obj.TaskDate = moment(obj.StartDate).add(i, "days").toDate();
          taskList.push(obj);
        }
        this.taskService.addList(taskList);
      } else if (form.recurrenceCtrl === WEEKLY) {
        // Get Range in Dayss
        const startDate = moment(item.StartDate);
        const endDate = moment(item.EndDate);
        const week = endDate.diff(startDate, "week");

        // Declare Var
        const taskList = [] as Array<Task>;

        // Loop into this to create multi-record that link to parent id
        for (let i = 0; i <= week; i++) {
          const obj = { ...item };
          obj.Name += " " + (i + 1).toString();
          obj.TaskDate = moment(obj.StartDate).add(i, "weeks").toDate();
          taskList.push(obj);
        }
        this.taskService.addList(taskList);
      } else if (form.recurrenceCtrl === BI_WEEKLY) {
        // Get Range in Dayss
        const startDate = moment(item.StartDate);
        const endDate = moment(item.EndDate);
        const week = endDate.diff(startDate, "week");
        const biWeek = Math.floor(week / 2);
        // Declare Var
        const taskList = [] as Array<Task>;

        // Loop into this to create multi-record that link to parent id
        for (let i = 0; i <= biWeek; i++) {
          const obj = { ...item };
          obj.Name += " " + (i + 1).toString();
          obj.TaskDate = moment(obj.StartDate)
            .add(i * 2, "weeks")
            .toDate();
          taskList.push(obj);
        }
        this.taskService.addList(taskList);
      } else if (form.recurrenceCtrl === MONTHLY) {
        // Get Range in Dayss
        const startDate = moment(item.StartDate);
        const endDate = moment(item.EndDate);
        const months = endDate.diff(startDate, "months");

        // Declare Var
        const taskList = [] as Array<Task>;

        // Loop into this to create multi-record that link to parent id
        for (let i = 0; i < months; i++) {
          const obj = { ...item };
          obj.Name += " " + (i + 1).toString();
          obj.TaskDate = moment(obj.StartDate).add(i, "months").toDate();
          taskList.push(obj);
        }
        this.taskService.addList(taskList);
      } else if (form.recurrenceCtrl === BI_MONTHLY) {
        // Get Range in Dayss
        const startDate = moment(item.StartDate);
        const endDate = moment(item.EndDate);
        const months = endDate.diff(startDate, "months");
        const biMonth = Math.floor(months / 2);
        // Declare Var
        const taskList = [] as Array<Task>;

        // Loop into this to create multi-record that link to parent id
        for (let i = 0; i <= biMonth; i++) {
          const obj = { ...item };
          obj.Name += " " + (i + 1).toString();
          obj.TaskDate = moment(obj.StartDate)
            .add(i * 2, "months")
            .toDate();
          taskList.push(obj);
        }
        this.taskService.addList(taskList);
      }
    } else {
      this.taskService.edit(item);
    }
    this.tplModal.destroy();
  }

  deleteDialog(item: Task): void {
    if (!item) {
      this.message.create("success", "Error delete item");
    } else {
      const itemDate = item.TaskDate as any; // FIXME(simon): Is this a Timestamp?
      const date = moment(new Date(itemDate.seconds * 1000));

      this.tplModal = this.modalService.create({
        nzTitle: this.areYouSureDeletionTranslate,
        nzContent: `"${item.Name}" with task date on ${date.format(
          "MMM D, YYYY"
        )}`,
        nzOkText: this.yesTranslate,
        nzOkType: "danger",
        nzOnOk: () => {
          this.taskService.delete(item.Id);
        },
        nzCancelText: this.noTranslate,
      });
    }
  }

  displayResult(response: boolean, isSave: boolean): void {
    if (response) {
      const msg = isSave
        ? "Item successfully saved"
        : "Item successfully deleted";
      this.message.create("success", msg);
    } else {
      const msg = isSave ? "Error saving item" : "Error deleting item";
      this.message.create("warning", msg);
    }
  }
}
