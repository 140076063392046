import { Component, OnInit, TemplateRef } from "@angular/core";
import { Company } from "../../../../../models/company";
import { NzModalRef, NzModalService, NzMessageService } from "ng-zorro-antd";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToolbarService } from "src/app/services/toolbar.service";
import { Observable } from "rxjs";
import { UserService } from "src/app/services/user.service";
import { User, Role, Dashboard } from "../../../../../models/user";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-user-company",
  templateUrl: "./user-company.component.html",
  styleUrls: ["./user-company.component.scss"],
})
export class UserCompanyComponent implements OnInit {
  public tplModal: NzModalRef;
  public dialogFormGroup: FormGroup;
  public tplModalButtonLoading = false;
  public self: User;
  public users$: Observable<User[]>;
  public cancelTranslate: string;
  public saveTranslate: string;
  public yesTranslate: string;
  public noTranslate: string;
  public areYouSureDeletionTranslate: string;

  constructor(
    private toolbarService: ToolbarService,
    private userService: UserService,
    private modalService: NzModalService,
    private message: NzMessageService,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.stream("cancel").subscribe((translation) => {
      this.cancelTranslate = translation;
    });
    this.translate.stream("save").subscribe((translation) => {
      this.saveTranslate = translation;
    });
    this.translate.stream("yes").subscribe((translation) => {
      this.yesTranslate = translation;
    });
    this.translate.stream("no").subscribe((translation) => {
      this.noTranslate = translation;
    });
    this.translate.stream("areYouSureForDelete").subscribe((translation) => {
      this.areYouSureDeletionTranslate = translation;
    });
    this.translate.stream("manageUser").subscribe((translation) => {
      this.toolbarService.changeHeader(translation);
    });

    this.users$ = this.userService.listOwnCompanyUsers();
    this.self = this.userService.self;
  }

  editDialog(
    tplTitle: TemplateRef<never>,
    tplContent: TemplateRef<never>,
    data: User
  ): void {
    if (!data) {
      data = {} as User;
      data.Company = {} as Company;
      data.Role = {} as Role;
      data.Dashboard = {} as Dashboard;
      data.Role.Worker = true;
      data.Role.Owner = false;
    }
    const item = { ...data };
    // if (item.Dashboard.Indoor) {
    //   var dashboard = "indoor"
    // } else if (item.Dashboard.Outdoor){
    //   var dashboard = "outdoor"
    // } else if (item.Dashboard.Fertigation){
    //   var dashboard = "fertigation"
    // }
    const assignedRole = item.Role.Worker ? "worker" : "owner";
    this.dialogFormGroup = this.formBuilder.group({
      emailCtrl: [item.Email, [Validators.required, Validators.email]],
      companyCtrl: [this.userService.self.Company.Id],
      nameCtrl: [item.Name, Validators.required],
      roleCtrl: [assignedRole, Validators.required],
      // dashboardCtrl: [dashboard, Validators.required],
    });

    this.tplModal = this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: [
        {
          label: this.cancelTranslate,
          type: "danger",
          onClick: () => {
            this.tplModal.destroy();
          },
        },
        {
          label: this.saveTranslate,
          type: "primary",
          disabled: () => this.dialogFormGroup.invalid,
          onClick: () => {
            const form = this.dialogFormGroup.value;
            item.Company.Id = form.companyCtrl;
            item.Email = form.emailCtrl;
            item.Name = form.nameCtrl;
            item.Dashboard.Indoor = this.self.Dashboard.Indoor;
            item.Dashboard.Outdoor = this.self.Dashboard.Outdoor;
            item.Dashboard.Fertigation = this.self.Dashboard.Fertigation;
            item.Role.Worker = form.roleCtrl === "worker";
            item.Role.Owner = form.roleCtrl === "owner";

            if (!item.Id) {
              this.userService
                .add(item)
                .then(() => {
                  this.displayResult(true, true);
                  this.tplModal.destroy();
                })
                .catch((err) => {
                  console.log(err);
                  this.message.create("error", err.message);
                });
            } else {
              this.userService
                .edit(item)
                .then(() => {
                  this.displayResult(true, true);
                  this.tplModal.destroy();
                })
                .catch(() => {
                  this.displayResult(false, true);
                });
            }
          },
        },
      ],
      nzMaskClosable: false,
      nzClosable: true,
    });
  }

  deleteDialog(item: User): void {
    if (!item) {
      this.message.create("success", "Error delete item");
    } else {
      this.tplModal = this.modalService.create({
        nzTitle: this.areYouSureDeletionTranslate,
        nzContent: item.Name,
        nzOkText: this.yesTranslate,
        nzOkType: "danger",
        nzOnOk: () => {
          this.userService.delete(item.Id, item.Email);
          this.displayResult(true, false);
        },
        nzCancelText: this.noTranslate,
      });
    }
  }

  displayResult(response: boolean, isSave: boolean): void {
    if (response) {
      const msg = isSave
        ? "Item successfully saved"
        : "Item successfully deleted";
      this.message.create("success", msg);
    } else {
      const msg = isSave ? "Error saving item" : "Error deleting item";
      this.message.create("warning", msg);
    }
  }
}
