<nz-skeleton
  [nzActive]="true"
  [nzLoading]="loadGraph"
  [nzParagraph]="{ rows: 14 }"
>
  <nz-page-header nzBackIcon [nzGhost]="false">
    <nz-page-header-title>{{ title }}</nz-page-header-title>
    <nz-page-header-content>
      <nz-descriptions nzBordered nzSize="small">
        <nz-descriptions-item nzTitle="{{ 'zone' | translate }}">{{
          envData.Device.DeviceZone
        }}</nz-descriptions-item>
        <nz-descriptions-item
          nzTitle="{{ 'lastUpdatedAt' | translate }}"
          nzSpan="2"
          >{{ latestData.Time }}</nz-descriptions-item
        >
        <nz-descriptions-item
          title="{{ latestData.EC }} mS/cm"
          nzTitle="{{ 'electricConductivity' | translate }}"
          >{{ latestData.EC | number: "1.1-1" }} mS/cm</nz-descriptions-item
        >
        <nz-descriptions-item nzTitle="{{ 'phLevel' | translate }}">{{
          latestData.pH | number: "1.1-1"
        }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="{{ 'airTemperature' | translate }}"
          >{{ latestData.airTemp | number: "1.1-1" }} °C</nz-descriptions-item
        >
        <nz-descriptions-item nzTitle="{{ 'waterTemperature' | translate }}"
          >{{ latestData.waterTemp | number: "1.1-1" }} °C</nz-descriptions-item
        >
        <nz-descriptions-item nzTitle="{{ 'humidity' | translate }}"
          >{{ latestData.humid | number: "1.1-1" }} %</nz-descriptions-item
        >
      </nz-descriptions>
    </nz-page-header-content>
  </nz-page-header>
  <div class="wrap">
    <div class="content">
      <div fxLayout="row wrap" fxLayoutAlign="space-between stretch">
        <div
          [fxFlex]="'auto'"
          fxFlex.lt-lg="100"
          class="dash-card"
          *ngIf="autoDosingHistoryGraphData"
        >
          <plotly-plot
            [data]="autoDosingHistoryGraphData.Data"
            [layout]="autoDosingHistoryGraphData.Layout"
            [config]="{ displayModeBar: false }"
            [useResizeHandler]="true"
            [style]="{ position: 'relative', width: '100%', height: '100%' }"
          ></plotly-plot>
        </div>
        <div [fxFlex]="'auto'" fxFlex.lt-lg="100" class="dash-card">
          <plotly-plot
            [data]="envData.EC.Data"
            [layout]="envData.EC.Layout"
            [config]="{ displayModeBar: false }"
            [useResizeHandler]="true"
            [style]="{ position: 'relative', width: '100%', height: '100%' }"
          ></plotly-plot>
        </div>
        <div [fxFlex]="'auto'" fxFlex.lt-lg="100" class="dash-card">
          <plotly-plot
            [data]="envData.pH.Data"
            [layout]="envData.pH.Layout"
            [config]="{ displayModeBar: false }"
            [useResizeHandler]="true"
          ></plotly-plot>
        </div>
        <div [fxFlex]="'auto'" fxFlex.lt-lg="100" class="dash-card">
          <plotly-plot
            [data]="envData.AirTemp.Data"
            [layout]="envData.AirTemp.Layout"
            [config]="{ displayModeBar: false }"
            [useResizeHandler]="true"
          ></plotly-plot>
        </div>
        <div [fxFlex]="'auto'" fxFlex.lt-lg="100" class="dash-card">
          <plotly-plot
            [data]="envData.WaterTemp.Data"
            [layout]="envData.WaterTemp.Layout"
            [config]="{ displayModeBar: false }"
            [useResizeHandler]="true"
          ></plotly-plot>
        </div>
        <div [fxFlex]="'auto'" fxFlex.lt-lg="100" class="dash-card">
          <plotly-plot
            [data]="envData.Humid.Data"
            [layout]="envData.Humid.Layout"
            [config]="{ displayModeBar: false }"
            [useResizeHandler]="true"
          ></plotly-plot>
        </div>
      </div>
    </div>
  </div>

  <!-- <br /> -->
  <!-- <nz-divider nzText="{{ 'autoDosingHistory' | translate }}"></nz-divider> -->
  <!-- <nz-table #basicTable [nzData]="autoDosingHistories">
  <thead>
    <tr>
      <th>{{ "substance" | translate }}</th>
      <th>{{ "dosageMl" | translate }}</th>
      <th>{{ "time" | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of basicTable.data">
      <td>{{ item.Substance }}</td>
      <td>{{ item.Dosage }}</td>
      <td>{{ dateConverter(item.Time) }}</td>
    </tr>
  </tbody>
</nz-table> -->
</nz-skeleton>
