import { Component, OnInit } from "@angular/core";
import { Observable, combineLatest } from "rxjs";
import {
  CropRevenue,
  Revenue,
  Expense,
} from "../../../../../models/profit-loss";
import { ToolbarService } from "src/app/services/toolbar.service";
import { ProfitLossService } from "src/app/services/profit-loss.service";
import { map } from "rxjs/operators";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";

@Component({
  selector: "app-profit-loss-summary",
  templateUrl: "./profit-loss-summary.component.html",
  styleUrls: ["./profit-loss-summary.component.scss"],
})
export class ProfitLossSummaryComponent implements OnInit {
  public cropRevenueList$: Observable<CropRevenue[]>;
  public revenueList$: Observable<Revenue[]>;
  public expenseList$: Observable<Expense[]>;

  public revenueAmount$: Observable<number>;
  public expenseAmount$: Observable<number>;
  public profitloss$: Observable<number>;
  public breakevenYield: number;

  public yield$: Observable<number>;
  public averagePrice$: Observable<number>;
  public cropRevenue$: Observable<number>;
  public otherRevenue$: Observable<number>;

  public formGroup: FormGroup;
  public years: number[] = [];

  constructor(
    private toolbar: ToolbarService,
    private formBuilder: FormBuilder,
    private profitLossService: ProfitLossService,
    private translate: TranslateService
  ) {
    const today = new Date();
    for (let i = today.getFullYear() - 3; i <= today.getFullYear() + 3; i++) {
      this.years.push(i);
    }
  }

  ngOnInit(): void {
    this.translate.stream("summarySideMenu").subscribe((translation) => {
      this.toolbar.changeHeader(translation);
    });
    const now = moment();
    this.formGroup = this.formBuilder.group({
      monthCtrl: [(now.month() + 1).toString()],
      yearCtrl: [now.year()],
    });

    this.search();
  }

  search(): void {
    const form = this.formGroup.value;
    const m = form.monthCtrl;
    const y = form.yearCtrl;

    this.cropRevenueList$ = this.profitLossService.getCropRevenueListByMonthYear(
      m,
      y
    );
    this.revenueList$ = this.profitLossService.getRevenueListByMonthYear(m, y);
    this.expenseList$ = this.profitLossService.getExpenseListByMonthYear(m, y);

    this.revenueAmount$ = combineLatest(
      this.cropRevenueList$,
      this.revenueList$
    ).pipe(
      map(([first, second]) => {
        const item1 = first.reduce(
          (sum, current) => sum + current.CropRevenue,
          0
        );
        const item2 = second.reduce((sum, current) => sum + current.Amount, 0);
        return item1 + item2;
      })
    );

    this.expenseAmount$ = this.expenseList$.pipe(
      map((x) => x.reduce((sum, current) => sum + current.Amount, 0))
    );
    this.profitloss$ = combineLatest(
      this.revenueAmount$,
      this.expenseAmount$
    ).pipe(map(([first, second]) => first - second));

    this.yield$ = this.cropRevenueList$.pipe(
      map((x) => x.reduce((sum, current) => sum + current.Yield, 0))
    );

    const total$ = this.cropRevenueList$.pipe(
      map((c) => c.reduce((state, current) => state + 1, 0))
    );
    this.averagePrice$ = combineLatest(this.cropRevenueList$, total$).pipe(
      map(([first, second]) => {
        const item1 = first.reduce((sum, current) => sum + current.Price, 0);
        const result = item1 / second;
        if (result) {
          return result;
        }
        return 0;
      })
    );

    this.cropRevenue$ = this.cropRevenueList$.pipe(
      map((x) => x.reduce((sum, current) => sum + current.CropRevenue, 0))
    );
    this.otherRevenue$ = this.revenueList$.pipe(
      map((x) => x.reduce((sum, current) => sum + current.Amount, 0))
    );
  }
}
